import { handleSnackbar } from "actions/layout/layout";
import { useAppDispatch } from "hooks/hooks";
import { useAppSelector } from "hooks/hooks";
import styles from "./SnackBar.module.scss";

const Snackbar = () => {
    const {
        layoutReducer: { snackbar }
    } = useAppSelector((state) => state);
    const dispatch = useAppDispatch();

    const snackBarColors = {
        red: "rgba(223, 9, 29, 0.8)",
        green: "#26EA8F",
        blue: "rgba(23, 133, 166, 0.65)"
    };

    return (
        <div
            className={` ${styles.snackbar} ${snackbar.show ? styles.show : styles.hide}`}
            style={{ backgroundColor: snackBarColors[snackbar.color] }}>
            <p>{snackbar.message}</p>
            <i
                className={styles.closeIcon}
                onClick={() => {
                    dispatch(handleSnackbar(false));
                }}
            />
        </div>
    );
};

export default Snackbar;
