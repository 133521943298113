import { useNavigate } from "react-router-dom";
import Button from "components/Button/Button";
import styles from "./PageHeader.module.scss";
import useCheckMobileScreen from "hooks/UseCheckMobileScreen/UseCheckMobileScreen";
import { useTranslations } from "hooks/UseTranslations";

interface Props {
    pageTitle?: String;
    pageSubTitle?: string;
    hasBackButton?: boolean;
    handleBack?: () => void;
    category?: String;
    icon?: string;
}

const PageHeader: React.FC<Props> = (props) => {
    const navigate = useNavigate();
    const { translations } = useTranslations();
    const { pageTitle, pageSubTitle, category, hasBackButton, handleBack, icon } = props;
    let isMobile = useCheckMobileScreen();

    return (
        <>
            {isMobile ? (
                icon ? (
                    <div className={styles.mobileWrap}>
                        <i className={`${icon} mr-1`}></i>
                        <p className={styles.mobileTitle} style={{ padding: "0px", margin: "0px" }}>
                            {pageTitle}
                        </p>
                    </div>
                ) : (
                    <div
                        className={
                            hasBackButton ? styles.pageHeaderWrapper : `${styles.pageHeaderWrapper} ${styles.noBackBtn}`
                        }>
                        <div className="w-100">
                            <div className={styles.mobileTitles}>
                                <h1>{category ? category : pageTitle}</h1>
                                {hasBackButton ? (
                                    <Button
                                        style={{ width: 110 }}
                                        label={translations("button.back")}
                                        class="btn btn-transparent"
                                        iconClass="left-back-arrow "
                                        handleClick={() => (handleBack ? handleBack() : navigate(-1))}
                                    />
                                ) : // <button onClick={() => (handleBack ? handleBack() : navigate(-1))}>
                                //     <i className="xIcon"></i>
                                // </button>
                                null}
                            </div>
                            <h1>{category ? pageTitle : ""}</h1>
                            {pageSubTitle ? (
                                <div className={styles.mobileSubtitles}>
                                    <p style={{ whiteSpace: isMobile ? "pre-line" : "normal" }}>{pageSubTitle}</p>
                                </div>
                            ) : null}
                        </div>
                    </div>
                )
            ) : (
                <div
                    className={
                        hasBackButton ? styles.pageHeaderWrapper : `${styles.pageHeaderWrapper} ${styles.noBackBtn}`
                    }>
                    <div className={styles.webTitles}>
                        <div>
                            <h1 className="d-flex">
                                {category ? category + " /" : ""} {pageTitle}
                            </h1>

                            <p style={{ whiteSpace: isMobile ? "pre-line" : "normal" }}>{pageSubTitle}</p>
                        </div>
                        {hasBackButton ? (
                            <div>
                                <Button
                                    label={translations("button.back")}
                                    class="btn btn-transparent"
                                    iconClass="left-back-arrow "
                                    handleClick={() => (handleBack ? handleBack() : navigate(-1))}
                                />
                            </div>
                        ) : null}
                    </div>
                </div>
            )}
        </>
    );
};
export default PageHeader;
