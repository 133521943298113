import api from "services/api";

export const SET_CAPTURE_SCREEN = "SET_CAPTURE_SCREEN";
export const SET_CAPTURE_TYPE = "SET_CAPTURE_TYPE";
export const GET_UPLOAD_DOCUMENTS_BEGIN = "GET_UPLOAD_DOCUMENTS_BEGIN";
export const GET_UPLOAD_DOCUMENTS_SUCCESS = "GET_UPLOAD_DOCUMENTS_SUCCESS";
export const GET_UPLOAD_DOCUMENTS_FAILED = "GET_UPLOAD_DOCUMENTS_FAILED";
export const SET_CURRENT_MODAL = "SET_CURRENT_MODAL";
export const SET_UPLOAD_DOCUMENTS_LOCALLY = "SET_UPLOAD_DOCUMENTS_LOCALLY";
export const DELETE_UPLOAD_DOCUMENT_LOCALLY = "DELETE_UPLOAD_DOCUMENT_LOCALLY";
export const POST_UPLOAD_DOCUMENT_BEGIN = "POST_UPLOAD_DOCUMENT_BEGIN";
export const POST_UPLOAD_DOCUMENT_SUCCESS = "POST_UPLOAD_DOCUMENT_SUCCESS";
export const POST_UPLOAD_DOCUMENT_FAILED = "POST_UPLOAD_DOCUMENT_FAILED";
export const SET_UPLOADED_DOCUMENTS_MESSAGE = "SET_UPLOADED_DOCUMENTS_MESSAGE";
export const CLEAR_UPLOAD_DOCS_ACTION = "CLEAR_UPLOAD_DOCS_ACTION";

export const setCaptureScreen = (data) => ({
    type: SET_CAPTURE_SCREEN,
    payload: data
});

export const setCaptureType = (type) => ({
    type: SET_CAPTURE_TYPE,
    payload: type
});
export const setUploadedDocumentsMessage = (message) => ({
    type: SET_UPLOADED_DOCUMENTS_MESSAGE,
    payload: message
});
export const setCurrentModal = (modal) => ({
    type: SET_CURRENT_MODAL,
    payload: modal
});
export const uploadDocumentsLocally = (data) => ({
    type: SET_UPLOAD_DOCUMENTS_LOCALLY,
    payload: data
});
export const deleteUploadDocumentLocally = (indexToDelete: number) => ({
    type: DELETE_UPLOAD_DOCUMENT_LOCALLY,
    payload: indexToDelete
});
export const getUploadedDocuments = (ta_id: number, product_order_id: number, product_order_item_id: number) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: GET_UPLOAD_DOCUMENTS_BEGIN
            });
            const response = await api.get(`/core/account/product_order/attachments`, {
                params: {
                    ta_id: ta_id,
                    product_order_id: product_order_id,
                    product_order_item_id: product_order_item_id
                }
            });
            dispatch({
                type: GET_UPLOAD_DOCUMENTS_SUCCESS,
                payload: response.data[0]
            });
        } catch (error) {
            dispatch({
                type: GET_UPLOAD_DOCUMENTS_FAILED,
                error: error
            });
        }
    };
};
const postUploadDocumentBegin = () => ({
    type: POST_UPLOAD_DOCUMENT_BEGIN
});
const postUploadDocumentSuccess = (data) => ({
    type: POST_UPLOAD_DOCUMENT_SUCCESS,
    payload: data
});
const postUploadDocumentFailed = (error) => ({
    type: POST_UPLOAD_DOCUMENT_FAILED,
    error: error
});
export const postUploadDocument = (data: any, file: any) => {
    return async (dispatch: any) => {
        try {
            const formData = new FormData();
            formData.append("consultation_request", JSON.stringify(data));
            formData.append("file", file);
            dispatch(postUploadDocumentBegin());
            const response = await api.post(`/core/account/consultation/upload/document`, formData, {
                headers: { "Content-Type": "multipart/form-data" }
            });
            if (response.status === 200) {
                dispatch(getUploadedDocuments(data.ta_id, data.product_order_id, data.product_order_item_id));
            }
            await dispatch(postUploadDocumentSuccess(response.data));
            return response;
        } catch (error) {
            dispatch(postUploadDocumentFailed(error.response));
        }
    };
};
export const setGivenConsent = (
    ta_id: number,
    product_order_id: number,
    product_order_item_id: number,
    given_consent: boolean
) => {
    return async () => {
        try {
            const response = api.put(
                `/core/product_order/user_consent?ta_id=${ta_id}&product_order_id=${product_order_id}&product_order_item_id=${product_order_item_id}&given_consent=${given_consent}`
            );
        } catch (error) {
            console.log("error", error);
        }
    };
};

export const clearUploadDocumentsAction = () => ({
    type: CLEAR_UPLOAD_DOCS_ACTION
});
