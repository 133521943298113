import React from "react";
import { useTranslations } from "hooks/UseTranslations";
import styles from "./NotFound.module.scss";

const NotFound = React.memo(({ notFound, children }: { notFound: boolean; children?: any }) => {
    const { translations } = useTranslations();
    if (notFound)
        return (
            <div className={styles.container}>
                <h1>{translations("error.oops!")}</h1>
                <h1>{translations("error.404_not_found")}</h1>
                <p>{translations("error.sorry_an_error_has_occured")}</p>
            </div>
        );

    if (!children) return null;

    return typeof children === "function" ? children() : children;
});

export default NotFound;
