import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "hooks/hooks";
import { deleteShoppingCart, handleQuantity, setBookAppointmentProduct } from "actions/layout/layout";
import { weekDays } from "utils/utils";
import { imageUrl } from "helpers";
import { useTranslations } from "hooks/UseTranslations";
import GTMEventsHandler from "utils/GTMEventsHandler";
import StorageService from "services/storage.service";
import styles from "./CartItem.module.scss";
import useCheckMobileScreen from "hooks/UseCheckMobileScreen/UseCheckMobileScreen";
import useSnackbar from "hooks/UseSnackBar/useSnackBar";

interface Props {
    product: any;
    checkout: boolean;
}
export default function CartItem({ product, checkout }: Props) {
    const {
        officialName,
        images,
        product_id,
        price,
        _quantity,
        _bookAppointment,
        product_calendar,
        product_group,
        _uid,
        discount_price
    } = product;

    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const {
        homePageReducer: { language },
        layoutReducer: { showDiscount }
    } = useAppSelector((state) => state);
    const { translations } = useTranslations();
    const img = imageUrl(images, language);
    const { showSnackBar } = useSnackbar();
    const homeProduct = product_group === "H";
    let isMobile = useCheckMobileScreen();

    const quantityHandler = (operation: string) => {
        dispatch(handleQuantity(operation, _uid));
    };

    const handleEditBookAppointment = () => {
        const merchantNumber = StorageService.getDataByKey("merchantData")?.merchantNumber;
        if (merchantNumber) {
            StorageService.updateProductCalendarItems(product_calendar[0].product_calendar_items?.length > 0);
        }
        dispatch(setBookAppointmentProduct(product));
        merchantNumber
            ? navigate(`/web/book-appointment?account_number=${merchantNumber}&action=edit`)
            : navigate(`/web/book-appointment?action=edit`);
    };

    const handleRemoveCart = () => {
        dispatch(deleteShoppingCart(_uid));
        GTMEventsHandler("remove_from_cart", {
            items: [
                {
                    item_name: officialName,
                    item_id: product_id,
                    price: price,
                    item_brand: "",
                    item_variant: "",
                    quantity: 1
                }
            ]
        });

        showSnackBar(translations("cart.deleted_product"), "green");
    };
    const qunatityCounter = () => {
        return (
            <div className={`${styles.quantityWidget}`}>
                <button onClick={() => quantityHandler("subtract")} className={`${styles.widget}`}>
                    -
                </button>
                <div className={`${styles.widget}`}>{_quantity}</div>
                <button onClick={() => quantityHandler("increment")} className={`${styles.widget}`}>
                    +
                </button>
            </div>
        );
    };
    return (
        <>
            <div className={`${styles.cartItemWeb}`}>
                <div className={styles.wrapper}>
                    <div className={styles.content}>
                        <img alt={`${officialName}-img`} src={img} className={styles.mr2} />
                        <div>
                            <p className={styles.title}>{officialName}</p>
                            <div className="d-flex">
                                <p
                                    className={
                                        showDiscount && discount_price
                                            ? `${styles.price} ${styles.redAlert}`
                                            : `${styles.price}`
                                    }>
                                    {price === 0 ? `${translations("label.free")}` : `€ ${(price / 100).toFixed(2)}`}
                                </p>
                                {showDiscount && discount_price && (
                                    <p className={styles.price}>
                                        {product?.discount_price === 0
                                            ? `${translations("label.free")}`
                                            : `€ ${(product?.discount_price / 100).toFixed(2)}`}
                                    </p>
                                )}
                            </div>
                            {isMobile && homeProduct && qunatityCounter()}
                        </div>
                    </div>
                    {!checkout && (
                        <div className={styles.actions}>
                            {!isMobile && homeProduct && qunatityCounter()}
                            <button className={`${styles.btnDelete} ${styles.mr2}`} onClick={handleRemoveCart}>
                                <i className="deleteItemIcon" style={{ marginRight: "11px" }}></i>
                            </button>
                        </div>
                    )}
                </div>
                <InfoWrapper
                    handleEdit={handleEditBookAppointment}
                    homeProduct={homeProduct}
                    bookAppointment={_bookAppointment}
                    id={product_id}
                />
            </div>
        </>
    );
}
const InfoWrapper = ({ handleEdit, homeProduct, bookAppointment, id }) => {
    const { translations } = useTranslations();
    let date = new Date(bookAppointment?.start);
    let weekDay = weekDays.filter((item) => item.value === date.getDay());
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();

    return (
        <div className="d-flex">
            {!homeProduct &&
                (bookAppointment ? (
                    <div className={styles.infoWrapper} style={{ width: "100%" }}>
                        <div className={`${styles.wrap}`}>
                            <div className="mr-1">
                                <p className={styles.title}>{translations(weekDay[0]?.label)}</p>
                                <p
                                    className={
                                        styles.subTitle
                                    }>{`${day}/${month}/${year} - ${bookAppointment.title}`}</p>
                            </div>
                        </div>
                        <button onClick={handleEdit}>
                            <i className="editItemIcon"></i>
                        </button>
                    </div>
                ) : (
                    <div className={styles.infoWrapper}>
                        <div className={`${styles.wrap}`}>
                            <div className="mr-1">
                                <p className={styles.title}>{translations("label.without_appointment")}</p>
                            </div>
                        </div>
                        <button onClick={handleEdit}>
                            <i className="editItemIcon"></i>
                        </button>
                    </div>
                ))}
        </div>
    );
};
