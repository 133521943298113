import api from "services/api";
import properties from "properties";

/***************
 * ACTION TYPES
 ***************/
export const UPDATE_CHAT_CONVERSATION = "UPDATE_CHAT_CONVERSATION";
export const UPDATE_MESSAGE_STATUS = "UPDATE_MESSAGE_STATUS";
export const SET_CONVERSATION_ID = "SET_CONVERSATION_ID";
export const GET_CONVERSATION_HISTORY_BEGIN = "GET_CONVERSATION_HISTORY_BEGIN";
export const GET_CONVERSATION_HISTORY_SUCCESS = "GET_CONVERSATION_HISTORY_SUCCESS";
export const GET_CONVERSATION_HISTORY_FAILED = "GET_CONVERSATION_HISTORY_FAILED";
export const GET_ALL_CONVERSATIONS_BEGIN = "GET_ALL_CONVERSATIONS_BEGIN";
export const GET_ALL_CONVERSATIONS_SUCCESS = "GET_ALL_CONVERSATIONS_SUCCESS";
export const GET_ALL_CONVERSATIONS_FAILED = "GET_ALL_CONVERSATIONS_FAILED";
export const GET_PARTICIPANTS_BY_CONVERSATION_BEGIN = "GET_PARTICIPANTS_BY_CONVERSATION_BEGIN";
export const GET_PARTICIPANTS_BY_CONVERSATION_SUCCESS = "GET_PARTICIPANTS_BY_CONVERSATION_SUCCESS";
export const GET_PARTICIPANTS_BY_CONVERSATION_FAILED = "GET_PARTICIPANTS_BY_CONVERSATION_FAILED";
export const CLEAR_CHAT = "CLEAR_CHAT";
export const SET_CONVERSATION_PARTICIPANTS = "SET_CONVERSATION_PARTICIPANTS";
export const UPDATE_INBOX = "UPDATE_INBOX";
export const UPDATE_CONVERSATION_STATUS = "UPDATE_CONVERSATION_STATUS";
export const SET_CANDIDATE_OFFER_OBJECT = "SET_CANDIDATE_OBJECT";
export const FETCH_ICE_SERVER_BEGIN = "FETCH_ICE_SERVER_BEGIN";
export const FETCH_ICE_SERVER_SUCCESS = "FETCH_ICE_SERVER_SUCCESS";
export const FETCH_ICE_SERVER_FAILED = "FETCH_ICE_SERVER_FAILED";
export const UPDATE_MESSAGE_PAYLOAD = "UPDATE_MESSAGE_PAYLOAD";
export const CREATE_CUSTOMER_BEGIN = "CREATE_CUSTOMER_BEGIN";
export const CREATE_CUSTOMER_SUCCESS = "CREATE_CUSTOMER_SUCCESS";
export const CREATE_CUSTOMER_FAILED = "CREATE_CUSTOMER_FAILED";
export const FILTER_CONVERSATIONS_BY_TYPE = "FILTER_CONVERSATIONS_BY_TYPE";
export const SET_INBOX_CHANNEL = "SET_INBOX_CHANNEL";
export const SET_CHAT_ACTIVE_TAB = "SET_CHAT_ACTIVE_TAB";

// export const SET_SDP_OFFER_OBJECT = "SET_SDP_OBJECT";

// export const setSDPOffer = (sdpObj) => ({
//     type: SET_SDP_OFFER_OBJECT,
//     payload: sdpObj
// });

export const updateChat = (chat) => ({
    type: UPDATE_CHAT_CONVERSATION,
    payload: chat
});

export const setInboxChannel = (channel) => ({
    type: SET_INBOX_CHANNEL,
    payload: channel
});

export const setChatActiveTab = (activeTab) => ({
    type: SET_CHAT_ACTIVE_TAB,
    payload: activeTab
});

export const setCandidateOffer = (candidateObj) => ({
    type: SET_CANDIDATE_OFFER_OBJECT,
    payload: candidateObj
});

export const updateInbox = (chat) => ({
    type: UPDATE_INBOX,
    payload: chat
});

export const updateMessageStatus = (chat) => ({
    type: UPDATE_MESSAGE_STATUS,
    payload: chat
});

export const updateMessagePayload = (data) => ({
    type: UPDATE_MESSAGE_PAYLOAD,
    payload: data
});

export const updateConversationStatus = () => ({
    type: UPDATE_CONVERSATION_STATUS
});

export const setCurrentConversation = (product) => ({
    type: SET_CONVERSATION_ID,
    payload: product
});

export const setConversationParticipants = (data) => ({
    type: SET_CONVERSATION_PARTICIPANTS,
    payload: data
});

export const clearChat = () => ({
    type: CLEAR_CHAT
});

const getParticipatsByConversationBegin = () => ({
    type: GET_PARTICIPANTS_BY_CONVERSATION_BEGIN
});

const getParticipatsByConversationSuccess = (data) => ({
    type: GET_PARTICIPANTS_BY_CONVERSATION_SUCCESS,
    payload: data
});

const getParticipatsByConversationFailed = (error) => ({
    type: GET_PARTICIPANTS_BY_CONVERSATION_FAILED,
    payload: error
});

export const filterConversationsByType = (type: string) => ({
    type: FILTER_CONVERSATIONS_BY_TYPE,
    payload: type
});

export const getParticipantsByConversation = (conversation_id: string) => {
    return async (dispatch: any) => {
        try {
            dispatch(getParticipatsByConversationBegin());
            const response = await api.get(`/chat/participant/conversation/${conversation_id}`, {
                headers: { "Cache-Control": "no-cache" }
            });
            dispatch(getParticipatsByConversationSuccess(response.data));
            return response.data;
        } catch (error) {
            return dispatch(getParticipatsByConversationFailed(error));
        }
    };
};

const getConversationHistoryBegin = () => ({
    type: GET_CONVERSATION_HISTORY_BEGIN
});

const getConversationHistorySuccess = (data) => ({
    type: GET_CONVERSATION_HISTORY_SUCCESS,
    payload: data
});

const getConversationHistoryFailed = (error) => ({
    type: GET_CONVERSATION_HISTORY_FAILED,
    payload: error
});

export const getCoversationHistory = (conversation_id: string) => {
    return async (dispatch: any) => {
        try {
            dispatch(getConversationHistoryBegin());
            const response = await api.get(`/chat/conversation/${conversation_id}/history/v2`, {
                headers: { "Cache-Control": "no-cache" }
            });
            dispatch(getConversationHistorySuccess(response));
            return response.data;
        } catch (error) {
            return dispatch(getConversationHistoryFailed(error));
        }
    };
};

const getAllConversationsBegin = () => ({
    type: GET_ALL_CONVERSATIONS_BEGIN
});

const getAllConversationsSuccess = (data) => ({
    type: GET_ALL_CONVERSATIONS_SUCCESS,
    payload: data
});

const getAllConversationsFailed = (error) => ({
    type: GET_ALL_CONVERSATIONS_FAILED,
    payload: error
});

export const getAllConversations = (getFirstConversation?: boolean, type: string = "") => {
    return async (dispatch: any) => {
        try {
            dispatch(getAllConversationsBegin());
            const route = type === "" ? `/chat/conversation` : `/chat/conversation/${type}`;
            const response = await api.get(route, {
                headers: { "Cache-Control": "no-cache" }
            });
            dispatch(getAllConversationsSuccess({ ...response, getFirstConversation: getFirstConversation }));
            return response;
        } catch (error) {
            return dispatch(getAllConversationsFailed(error));
        }
    };
};

export const deleteSdp = (data) => {
    return async (dispatch: any) => {
        try {
            const { conversationId, message_external_identifier } = data;
            const response = await api.delete(
                `/chat/conversation/${conversationId}/${message_external_identifier}/delete-sdp`,
                {
                    headers: { "Cache-Control": "no-cache" }
                }
            );
            return response;
        } catch (error) {
            console.log("error", error);
        }
    };
};

export const fetchIceServer = () => {
    return async (dispatch: any) => {
        try {
            dispatch({ type: FETCH_ICE_SERVER_BEGIN });
            // const res = await fetch(`${properties.ICE_SERVER_HOST}credentials?apiKey=${properties.ICE_SERVER_KEY}`);
            const res = await fetch(
                "https://p2p_video.metered.live/api/v1/turn/credentials?apiKey=0b905d84bb32658af5c1f6a23a8278d4293c",
                {
                    headers: { "Cache-Control": "no-cache" }
                }
            );
            const data = await res.json();
            dispatch({
                type: FETCH_ICE_SERVER_SUCCESS,
                payload: data
            });
            return data;
        } catch (error) {
            dispatch({
                type: FETCH_ICE_SERVER_FAILED,
                error: error
            });
        }
    };
};

export const createCustomerChat = (data: any) => {
    return async (dispatch) => {
        try {
            dispatch({ type: CREATE_CUSTOMER_BEGIN });
            const response = await api.post(`/core/chat/room`, data);
            dispatch({ type: CREATE_CUSTOMER_SUCCESS });
            setTimeout(()=> dispatch(getAllConversations(true)), 1000)
            return response;
        } catch (error) {
            dispatch({ type: CREATE_CUSTOMER_FAILED });
        }
    };
};
