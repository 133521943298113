import { useEffect, useState } from "react";
import PreScannedKit from "./PreScannedKit/PreScannedKit";
import PageHeader from "components/PageHeader/PageHeader";
import useCheckMobileScreen from "hooks/UseCheckMobileScreen/UseCheckMobileScreen";
import { useTranslations } from "hooks/UseTranslations";
import { useNavigate } from "react-router-dom";
import HorizontalStepper from "components/HorizontalStepper/HorizontalStepper";
import { Button } from "components";
import { useAppDispatch, useAppSelector } from "hooks/hooks";
import { resetAction } from "actions/dashboard/dashboard";
import styles from "./HomeTesting.module.scss";
import BarcodeReader from "./KitActivation/BarcodeScanner";
import useSnackbar from "hooks/UseSnackBar/useSnackBar";

const HomeTestingManually = () => {
    const dispatch = useAppDispatch();
    const { action } = useAppSelector((state) => state.dashboardReducer);
    const { translations } = useTranslations();
    const [barcode, setBarcode] = useState("");
    const [step, setStep] = useState<string>("1");
    const navigate = useNavigate();
    const backToLanding = () => {
        navigate(-1);
    };

    let isMobile = useCheckMobileScreen();
    const steps = [`${translations("label.kit_activation")}`, `${translations("home_testing.instruction")}`];
    const { showSnackBar } = useSnackbar();

    useEffect(() => {
        if (action.actionName === "POST_CONFIRM_HOME_TEST_SUCCESS") {
            navigate("/web/home-testing/instructions");
            showSnackBar(translations("error.the_barcode_is_successfully"), "green");
        }
        if (action.actionName === "POST_CONFIRM_HOME_TEST_FAILED") {
            showSnackBar(translations(`app.service.error.${action.error}`), "red");
        }
        if (barcode) setStep("2");
        return () => {
            dispatch(resetAction());
        };
    }, [barcode, action]);

    const ScanBarcodeClick = () => {
        setStep("1");
        setBarcode("");
    };

    return (
        <div>
            <HorizontalStepper steps={steps} activeStep={0} completed={[0]} />
            <div style={{ marginTop: isMobile ? 0 : -60 }}>
                <PageHeader
                    pageTitle={isMobile && translations("label.kit_activation")}
                    hasBackButton
                    handleBack={backToLanding}
                />
            </div>
            {step === "1" ? (
                <div className={styles.barcodeContainer}>
                    <div>{translations("label.place_your_barcode_in_front")}</div>
                    <div>{translations("label.your_test_kit_contains")}</div>
                    <BarcodeReader data={barcode} setData={setBarcode} />
                    <Button
                        class={styles.activateKitButton}
                        label={translations("action.enter_barcode_manuelly")}
                        handleClick={() => setStep("2")}
                    />
                </div>
            ) : (
                <>
                    <PreScannedKit manually setBarcode={setBarcode} barcode={barcode} />
                    <div onClick={ScanBarcodeClick} className={styles.barcodeButton}>
                        {translations("action.scan_barcode")}
                    </div>
                </>
            )}
        </div>
    );
};
export default HomeTestingManually;
