import axios from "axios";
// import { store } from "reducers/store";
// import { logoutSuccess } from "actions/authentication/authentication";

const instance = axios.create({
    withCredentials: false,
    baseURL: ""
    // headers: {
    //   Accept: "application/json",
    //   "Content-Type": "application/json",
    // },
});

instance.interceptors.request.use(
    (config) => config,
    (error) => Promise.reject(error)
);

instance.interceptors.response.use(
    (res) => {
        return res;
    },
    (err) => {
        let message = "Something went wrong!";
        try {
            // No Logged In user
            // if (err.response.status === 401) {
            //   message = "Your session has expire, please login again.";
            //   store.dispatch(logoutSuccess());
            // }
            message = err;
            throw message;
        } catch (err) {
            throw message;
        }
    }
);

export default instance;
