import React, { useEffect, useState } from "react";
import { EcocareLogoWhite } from "utils/constants/images";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useTranslations } from "hooks/UseTranslations";
import { useAppDispatch, useAppSelector } from "hooks/hooks";
import { getFooterLinks } from "actions/layout/layout";
import { useLocation } from "react-router-dom";
import { newsletterSubscribe } from "actions/authentication/authentication";
import { getRegExp } from "utils/validations";
import properties from "properties";
import styles from "./footer.module.scss";
import useSnackbar from "hooks/UseSnackBar/useSnackBar";
// import { authentication } from "actions";
// import Button from "components/Button/Button";
// import Recaptcha from "components/Recaptcha";

interface Props {}
const Footer: React.FC<Props> = () => {
    const dispatch = useAppDispatch();
    const location = useLocation();
    const { translations } = useTranslations();
    const routesWithfullWidthFooter = ["/web/locations"];
    const {
        // authenticationReducer: { action },
        // homePageReducer: { language },
        layoutReducer: { footerLinks }
    } = useAppSelector((state) => state);
    const [email, setEmail] = useState<string>("");
    const { showSnackBar } = useSnackbar();

    useEffect(() => {
        if (footerLinks.length === 0) {
            dispatch(getFooterLinks());
        }
    }, [dispatch, footerLinks]);

    const submit = () => {
        if (getRegExp("email").test(email)) {
            handleSubscribe();
        } else {
            showSnackBar(translations("valid.email"), "red");
        }
    };

    const handleSubscribe = async () => {
        const response = await dispatch(newsletterSubscribe(email));
        const result = await response.json();
        if (response.ok) {
            showSnackBar(translations("label.successfully_subscribed"), "green");
            setEmail("");
        } else {
            showSnackBar(translations(result?.detail || "error.something_went_wrong"), "red");
        }
    };

    return (
        <div
            className="mainFooter"
            style={routesWithfullWidthFooter.includes(location.pathname) ? { width: "100%" } : { maxWidth: "1514px" }}>
            <div className={styles.wrapper}>
                {/* <div className={styles.firstSection}>
                    {language === "en" ? (
                        <>
                            {translations("label.your")}{" "}
                            <span className={styles.healthcare}>{translations("label.healthcare")}</span>{" "}
                            {translations("label.companion")}
                        </>
                    ) : (
                        <>
                            {translations("label.your")}{" "}
                            <span className={styles.healthcare}>{translations("label.healthcare")}</span>
                            <span className={styles.healthcare}>{translations("label.companion")}</span>
                        </>
                    )}
                </div> */}

                <div className={styles.secondSection}>
                    <div style={{ flex: 0.5 }} className={styles.inputField}>
                        <LazyLoadImage src={EcocareLogoWhite} alt="EcoCare Logo" className={styles.ecocareLogo} />
                        {/* <div className={styles.title}>{translations("label.business")}</div> */}

                        {/* <div className={styles.buttonContainer}>
                            <Button
                                label={translations("button.subscribe.now")}
                                class={"btn btn-blue"}
                                handleClick={handleModal}
                            />
                            <Recaptcha
                                openModal={openModal}
                                setOpenModal={(open) => setOpenModal(open)}
                                callBack={(token) => handleSubscribe(token)}
                            />
                        </div>
                        <div className={styles.inputBox}>
                            <input
                                type="email"
                                value={subscriptionEmail}
                                onChange={(e) => setSubscriptionEmail(e.target.value)}
                                placeholder={translations("label.email")}
                            />
                            <div className={styles.arrowIcon} onClick={handleModal}></div>
                        </div> */}
                    </div>{" "}
                    <div style={{ flex: 0.5 }} className={styles.infoSection}>
                        <div className={styles.title}>Inhalt</div>
                        {footerLinks.map((el) => {
                            return (
                                <a
                                    target={"_blank"}
                                    key={el.id}
                                    className={`${styles.description} ${styles.link}`}
                                    href={`${properties.MY_HEALTHCARE_URL}${el?.attributes?.url}`}>
                                    {el?.attributes?.title}
                                </a>
                            );
                        })}
                    </div>
                    <div style={{ flex: 1 }} className={styles.addressSection}>
                        <div className={styles.title}>Wieso EcoCare</div>
                        <div>
                            <div className={styles.textContact}>
                                <div className={styles.icon}></div>
                                <h3>Hergestellt in Deutschland</h3>
                            </div>
                            <div className={styles.textContact}>
                                <div className={styles.icon}></div>
                                <h3>Kostenloser Versand</h3>
                            </div>
                            <div className={styles.textContact}>
                                <div className={styles.icon}></div>
                                <h3>Zertifizierte Medizinprodukte</h3>
                            </div>
                            <div className={styles.textContact}>
                                <div className={styles.icon}></div>
                                <h3>Höchste Sicherheit Zertifizierungen</h3>
                            </div>
                        </div>
                    </div>
                    <div style={{ flex: 0.5 }} className={styles.addressSection}>
                        <div className={styles.title}>Kontakt</div>
                        <div className={styles.textContact}>
                            <i className={styles.contactIcon}></i>
                            <h3>service@ecocare.de</h3>
                        </div>
                        <div className={styles.textContact}>
                            <i className={styles.phoneIcon}></i>
                            <h3>+4921141872902</h3>
                        </div>
                        <div className={styles.textContact}>
                            <i className={styles.locationIcon}></i>
                            <h3>
                                Kolberger Str. 7 <br /> 40599 Düsseldorf <br /> Deutschland
                            </h3>
                        </div>
                    </div>
                    <div style={{ flex: 0.5 }} className={styles.addressSection}>
                        <div className={styles.newsletterTitle}>
                            Newsletter abonnieren und <br /> Aktionsangebote sichern!
                        </div>

                        <div className={styles.subscribeInput}>
                            <input
                                className={styles.inputContainer}
                                placeholder={translations("label.email_example")}
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                            <button className={styles.subscribeButton} onClick={submit}>
                                {translations("button.subscribe")}
                                <i className={styles.rightIcon} />
                            </button>
                        </div>
                    </div>
                </div>

                <div className={styles.secondSection}>
                    <div style={{ flex: 2 }} className={styles.inputField}>
                        <div className={styles.title}>Zahlungsmöglichkeiten</div>
                        <div className={styles.payIcon}>
                            <i className={styles.visa}></i>
                            <i className={styles.amex}></i>
                            <i className={styles.card}></i>
                            <i className={styles.giropay}></i>
                            <i className={styles.pp}></i>
                        </div>
                    </div>
                    <div style={{ flex: 2 }} className={styles.infoSection}>
                        <div className={styles.title}>Ecocare App</div>
                        <div className={styles.payIcon}>
                            <a
                                href="https://play.google.com/store/apps/details?id=center.ecocare.client"
                                aria-label="Google Play Store">
                                <i className={styles.googlePlay} />
                            </a>
                            <a href="https://apps.apple.com/us/app/ecocare/id1569014752" aria-label="App Sotre">
                                <i className={styles.appStore} />
                            </a>
                        </div>
                    </div>
                    <div style={{ flex: 1, alignItems: "flex-end" }} className={styles.addressSection}>
                        <div className={styles.title}>Ecocare folgen</div>

                        <div className={styles.socialMediaIcons}>
                            <a href="https://twitter.com/EcoCareDE" target="_blank" aria-label="X">
                                <i className={styles.xIcon}> </i>
                            </a>
                            <a
                                href="https://www.linkedin.com/company/79905274/admin/feed/posts/"
                                target="_blank"
                                aria-label="Linkedin">
                                <i className={styles.linkdinIcon}></i>
                            </a>
                            <a href="https://www.instagram.com/ecocare.de/" target="_blank" aria-label="Instagram">
                                <i className={styles.instagramIcon}></i>
                            </a>
                        </div>
                    </div>
                </div>
                {/* 
                <div className={styles.socialMediaIcons}>
                    <a href="https://de.linkedin.com/company/ecocaregroup" target="_blank">
                        <i className={styles.linkdinIcon}> </i>
                    </a>
                    <a href="https://www.instagram.com/ecocare.de/" target="_blank">
                        <div className={styles.instagramIcon}></div>
                    </a>
                </div> */}
            </div>{" "}
            <div className={styles.lastSection}>
                <p>Alle Rechte vorbehalten © 2024 EcoCare</p>
                <p>
                    Kundenservice: <b> Mo-Fr: 09:00 - 18:00 Uhr</b>
                </p>
            </div>
        </div>
    );
};

export default Footer;
