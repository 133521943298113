import { useEffect, useState } from 'react'
import { Outlet } from 'react-router-dom';
import { useAppSelector } from 'hooks/hooks';
import { addToIndexedDb, getAllNotificationByPersonId } from 'services/indexedDB';

// interface Event {
//     conversation_id?: string;
//     payload?: string;
//     created_at?: string;
//     person_id?: number;
//     notification_type?: string;
//     status: string;
// }

export default function NotificationMiddleware() {
    const { isLoggedIn, userProfile } = useAppSelector((state) => state.authenticationReducer);
    const [eventSource, setEventSource] = useState(null);

    useEffect(() => {
        //Notifications event handler
        if (isLoggedIn && userProfile?.person_id) {
            getAllNotificationByPersonId(userProfile?.person_id);
            if (eventSource === null) {
                const eventSrc = new EventSource("/notification/subscribe", { withCredentials: true });

                eventSrc.onmessage = (event: any) => {
                    const data = JSON.parse(event.data);
                    if (data?.status === "connected") setEventSource(eventSrc)
                    if (data.conversation_id) addToIndexedDb({ ...data, person_id: userProfile.person_id })
                };

                eventSrc.onerror = (error: any) => {
                    eventSrc.close();
                    setEventSource(null);
                }
                eventSrc.addEventListener("ping", () => console.log("ping"))
                window.addEventListener('beforeunload', () => eventSrc?.close());
            }
        } else {
            eventSource && eventSource?.close()
            setEventSource(null)
        }
        return () => {
            eventSource?.close()
            window.removeEventListener('beforeunload', () => eventSource?.close());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoggedIn, userProfile?.person_id])

    return (
        <Outlet />
    )
}
