import React from "react";
import { useTranslations } from "hooks/UseTranslations";
import styles from "./WithLoader.module.scss";

const WithLoader = React.memo(({ isLoading, children }: any) => {
    const { translations } = useTranslations();
    if (isLoading)
        return (
            <div className={styles.container}>
                <div className={styles.loader} />
                <p className={styles.loadingText}>{translations("label.loading")}</p>
            </div>
        );

    if (!children) return null;

    return typeof children === "function" ? children() : children;
});
export default WithLoader;
