import Cookies from "js-cookie";

const setKeyValue = (key, value) => {
    let dataToStore = JSON.stringify(value);
    Cookies.set(key, dataToStore, { expires: 5 });
};

const getDataByKey = (key) => {
    const cookies = Cookies.get(key);
    let data = null;
    if (cookies) {
        data = JSON.parse(cookies);
    }
    return data;
};

const deleteCookieByKey = (key: string) => {
    Cookies.remove(key);
};

const updateProductCalendarItems = (hasCalendarItems: boolean) => {
    const cookies = Cookies.get("merchantData");
    const data = { ...JSON.parse(cookies), hasCalendarItems: hasCalendarItems };
    Cookies.set("merchantData", JSON.stringify(data), { expires: 2 });
};

const StorageService = {
    setKeyValue,
    getDataByKey,
    deleteCookieByKey,
    updateProductCalendarItems
};

export default StorageService;
