import { ChoosePersonParams, SignupResendIState } from "./../../models/Authentication";
import { LoginParams } from "models";
import api from "services/api";
import StorageService from "services/storage.service";

export const AUTHORIZE_BEGIN = "AUTHORIZE_BEGIN";
export const AUTHORIZE_SUCCESS = "AUTHORIZE_SUCCESS";
export const AUTHORIZE_FAILED = "AUTHORIZE_FAILED";
export const LOGIN_BEGIN = "LOGIN_BEGIN";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILED = "LOGIN_FAILED";
export const CHOOSE_PERSON_BEGIN = "CHOOSE_PERSON_BEGIN";
export const CHOOSE_PERSON_SUCCESS = "CHOOSE_PERSON_SUCCESS";
export const CHOOSE_PERSON_FAILED = "CHOOSE_PERSON_FAILED";
export const LOGOUT_BEGIN = "LOGOUT_BEGIN";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAILED = "LOGOUT_FAILED";
export const SIGNUP_RESEND_BEGIN = "SIGNUP_RESEND_BEGIN";
export const SIGNUP_RESEND_SUCCESS = "SIGNUP_RESEND_SUCCESS";
export const SIGNUP_RESEND_FAILED = "SIGNUP_RESEND_FAILED";
export const SIGNUP_BEGIN = "SIGNUP_BEGIN";
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
export const SIGNUP_FAILED = "SIGNUP_FAILED";
export const SET_PASSWORD_BEGIN = "SET_PASSWORD_BEGIN";
export const SET_PASSWORD_SUCCESS = "SET_PASSWORD_SUCCESS";
export const SET_PASSWORD_FAILED = "SET_PASSWORD_FAILED";
export const SEND_RESET_PASSWORD_BEGIN = "SEND_RESET_PASSWORD_BEGIN";
export const SEND_RESET_PASSWORD_SUCCESS = "SEND_RESET_PASSWORD_SUCCESS";
export const SEND_RESET_PASSWORD_FAILED = "SEND_RESET_PASSWORD_FAILED";
export const SET_PERSONAL_DETAILS_BEGIN = "SET_PERSONAL_DETAILS_BEGIN";
export const SET_PERSONAL_DETAILS_SUCCESS = "SET_PERSONAL_DETAILS_SUCCESS";
export const SET_PERSONAL_DETAILS_FAILED = "SET_PERSONAL_DETAILS_FAILED";
export const SET_PERSONAL_ADDRESS_BEGIN = "SET_PERSONAL_ADDRESS_BEGIN";
export const SET_PERSONAL_ADDRESS_SUCCESS = "SET_PERSONAL_ADDRESS_SUCCESS";
export const SET_PERSONAL_ADDRESS_FAILED = "SET_PERSONAL_ADDRESS_FAILED";
export const AUTHORIZATION_BEGIN = "AUTHORIZATION_BEGIN";
export const AUTHORIZATION_SUCCESS = "AUTHORIZATION_SUCCESS";
export const AUTHORIZATION_FAILED = "AUTHORIZATION_FAILED";
export const GET_USER_DATA_BEGIN = "GET_USER_DATA_BEGIN";
export const GET_USER_DATA_FAILED = "GET_USER_DATA_FAILED";
export const GET_USER_DATA_SUCCESS = "GET_USER_DATA_SUCCESS";
export const VERIFY_BEGIN = "VERIFY_BEGIN";
export const VERIFY_SUCCESS = "VERIFY_SUCCESS";
export const VERIFY_FAILED = "VERIFY_FAILED";
export const SET_PERSONAL_DETAILS_ATRIBUTE = "SET_PERSONAL_DETAILS_ATRIBUTE";
export const VERIFY_PHONE_BEGIN = "VERIFY_PHONE_BEGIN";
export const VERIFY_PHONE_SUCCESS = "VERIFY_PHONE_SUCCESS";
export const VERIFY_PHONE_FAILED = "VERIFY_PHONE_FAILED";
export const SENT_PHONE_VERIFICATION_CODE_BEGIN = "SENT_PHONE_VERIFICATION_CODE_BEGIN";
export const SENT_PHONE_VERIFICATION_CODE_SUCCESS = "SENT_PHONE_VERIFICATION_CODE_SUCCESS";
export const SENT_PHONE_VERIFICATION_CODE_FAILED = "SENT_PHONE_VERIFICATION_CODE_FAILED";
export const ADD_ADDRESS_BEGIN = "ADD_ADDRESS_BEGIN";
export const ADD_ADDRESS_SUCCESS = "ADD_ADDRESS_SUCCESS";
export const ADD_ADDRESS_FAILED = "ADD_ADDRESS_FAILED";
export const UPDATE_ADDRESS_BEGIN = "UPDATE_ADDRESS_BEGIN";
export const UPDATE_ADDRESS_SUCCESS = "UPDATE_ADDRESS_SUCCESS";
export const UPDATE_ADDRESS_FAILED = "UPDATE_ADDRESS_FAILED";
export const CLEAR_ACTION = "CLEAR_ACTION";
export const DELETE_ACCOUNT_BEGIN = "DELETE_ACCOUNT_BEGIN";
export const DELETE_ACCOUNT_SUCCESS = "DELETE_ACCOUNT_SUCCESS";
export const DELETE_ACCOUNT_FAILED = "DELETE_ACCOUNT_FAILED";
export const ADD_JOINT_USER_BEGIN = "ADD_JOINT_USER_BEGIN";
export const ADD_JOINT_USER_SUCCESS = "ADD_JOINT_USER_SUCCESS";
export const ADD_JOINT_USER_FAILED = "ADD_JOINT_USER_FAILED";
export const UPDATE_USER_SETTINGS_BEGIN = "UPDATE_USER_SETTINGS_BEGIN";
export const UPDATE_USER_SETTINGS_SUCCESS = "UPDATE_USER_SETTINGS_SUCCESS";
export const UPDATE_USER_SETTINGS_FAILED = "UPDATE_USER_SETTINGS_FAILED";
export const GET_LOCATION_HISTORY_BEGIN = "GET_LOCATION_HISTORY_BEGIN";
export const GET_LOCATION_HISTORY_SUCCESS = "GET_LOCATION_HISTORY_SUCCESS";
export const GET_LOCATION_HISTORY_FAILED = "GET_LOCATION_HISTORY_FAILED";
export const NEWSLETTER_SUBSCRIBE_BEGIN = "NEWSLETTER_SUBSCRIBE_BEGIN";
// export const NEWSLETTER_SUBSCRIBE_SUCCESS = "NEWSLETTER_SUBSCRIBE_SUCCESS";
export const NEWSLETTER_SUBSCRIBE_FAILED = "NEWSLETTER_SUBSCRIBE_FAILED";
export const VERIFY_TOKEN_RECAPTCHA_BEGIN = "VERIFY_TOKEN_RECAPTCHA_BEGIN";
export const VERIFY_TOKEN_RECAPTCHA_SUCCESS = "VERIFY_TOKEN_RECAPTCHA_SUCCESS";
export const VERIFY_TOKEN_RECAPTCHA_FAILED = "VERIFY_TOKEN_RECAPTCHA_FAILED";
export const UPDATE_PHONE_NUMBER_AUTH = "UPDATE_PHONE_NUMBER_AUTH";

const getUserDataBegin = () => ({
    type: GET_USER_DATA_BEGIN
});

const getUserDataSuccess = (data) => ({
    type: GET_USER_DATA_SUCCESS,
    payload: data
});

const getUserDataFailed = (error) => ({
    type: GET_USER_DATA_FAILED,
    payload: error
});

export const authorizeBegin = () => ({
    type: AUTHORIZE_BEGIN
});

export const authorizeSuccess = (data) => ({
    type: AUTHORIZE_SUCCESS,
    payload: data
});

export const authorizeFail = (error) => ({
    type: AUTHORIZE_FAILED,
    payload: error
});

export const loginBegin = () => ({
    type: LOGIN_BEGIN
});

export const loginSuccess = (data) => ({
    type: LOGIN_SUCCESS,
    payload: data
});

export const loginFail = (error) => ({
    type: LOGIN_FAILED,
    payload: error
});

export const choosePersonBegin = () => ({
    type: CHOOSE_PERSON_BEGIN
});

export const choosePersonSuccess = (data) => ({
    type: CHOOSE_PERSON_SUCCESS,
    payload: data
});

export const choosePersonFailed = (error) => ({
    type: CHOOSE_PERSON_FAILED,
    payload: error
});

export const logoutBegin = () => ({
    type: LOGOUT_BEGIN
});

export const logoutSuccess = (reason?: string) => ({
    type: LOGOUT_SUCCESS,
    payload: reason
});

export const logoutFail = (error) => ({
    type: LOGOUT_FAILED,
    payload: error
});

export const signupResendBegin = () => ({
    type: SIGNUP_RESEND_BEGIN
});

export const signupResendSuccess = (data) => ({
    type: SIGNUP_RESEND_SUCCESS,
    payload: data
});

export const signupResendFail = (error) => ({
    type: SIGNUP_RESEND_FAILED,
    payload: error
});

export const signupBegin = () => ({
    type: SIGNUP_BEGIN
});

export const signupSuccess = (statusCode) => ({
    type: SIGNUP_SUCCESS,
    payload: statusCode
});

export const signupFail = (error) => ({
    type: SIGNUP_FAILED,
    payload: error
});

export const setPasswordBegin = () => ({
    type: SET_PASSWORD_BEGIN
});
export const setPasswordSuccess = (data) => ({
    type: SET_PASSWORD_SUCCESS,
    payload: data
});
export const setPasswordFailed = (error) => ({
    type: SET_PASSWORD_FAILED,
    payload: error
});

export const sendResetPasswordBegin = () => ({
    type: SEND_RESET_PASSWORD_BEGIN
});

export const sendResetPasswordSuccess = (data) => ({
    type: SEND_RESET_PASSWORD_SUCCESS,
    payload: data
});

export const sendResetPasswordFailed = (error) => ({
    type: SEND_RESET_PASSWORD_FAILED,
    payload: error
});

export const setPersonalDetailsBegin = () => ({
    type: SET_PERSONAL_DETAILS_BEGIN
});

export const setPersonalDetailsSuccess = (data) => ({
    type: SET_PERSONAL_DETAILS_SUCCESS,
    payload: data
});

export const setPersonalDetailsFailed = (error) => ({
    type: SET_PERSONAL_DETAILS_FAILED,
    payload: error
});

export const setPersonalAddresBegin = () => ({
    type: SET_PERSONAL_ADDRESS_BEGIN
});

export const setPersonalAddresSuccess = (data) => ({
    type: SET_PERSONAL_ADDRESS_SUCCESS,
    payload: data
});

export const setPersonalAddresFailed = (error) => ({
    type: SET_PERSONAL_ADDRESS_FAILED,
    payload: error
});

export const authorizationBegin = () => ({
    type: AUTHORIZATION_BEGIN
});

export const authorizationSuccess = () => ({
    type: AUTHORIZATION_SUCCESS
});

export const authorizationFailed = (error) => ({
    type: AUTHORIZATION_FAILED,
    payload: error
});

export const verifyBegin = () => ({
    type: VERIFY_BEGIN
});

export const verifySuccess = (response) => ({
    type: VERIFY_SUCCESS,
    payload: response
});

export const verifyFailed = (error) => ({
    type: VERIFY_FAILED,
    payload: error
});

export const setPersonalDetailsAtribute = (key, value) => {
    return {
        type: SET_PERSONAL_DETAILS_ATRIBUTE,
        key,
        value
    };
};

export const verifyPhoneBegin = () => ({
    type: VERIFY_PHONE_BEGIN
});

export const verifyPhoneSuccess = (response) => ({
    type: VERIFY_PHONE_SUCCESS,
    payload: response
});

export const verifyPhoneFailed = (error) => ({
    type: VERIFY_PHONE_FAILED,
    payload: error
});

export const sentPhoneVerificationCodeBegin = () => ({
    type: SENT_PHONE_VERIFICATION_CODE_BEGIN
});

export const sentPhoneVerificationCodeSuccess = (response) => ({
    type: SENT_PHONE_VERIFICATION_CODE_SUCCESS,
    payload: response
});

export const sentPhoneVerificationCodeFailed = (error) => ({
    type: SENT_PHONE_VERIFICATION_CODE_FAILED,
    payload: error
});

export const addAddressBegin = () => ({
    type: ADD_ADDRESS_BEGIN
});

export const addAddressSuccess = (data) => ({
    type: ADD_ADDRESS_SUCCESS,
    payload: data
});

export const addAddressFail = (error) => ({
    type: ADD_ADDRESS_FAILED,
    payload: error
});

export const updateAddressBegin = () => ({
    type: UPDATE_ADDRESS_BEGIN
});

export const updateAddressSuccess = (data) => ({
    type: UPDATE_ADDRESS_SUCCESS,
    payload: data
});

export const updateAddressFail = (error) => ({
    type: UPDATE_ADDRESS_FAILED,
    payload: error
});

export const clearAction = () => ({
    type: CLEAR_ACTION
});

export const deleteAccountBegin = () => ({
    type: DELETE_ACCOUNT_BEGIN
});

export const deleteAccountSuccess = (data) => ({
    type: DELETE_ACCOUNT_SUCCESS,
    payload: data
});

export const deleteAccountFailed = (error) => ({
    type: DELETE_ACCOUNT_FAILED,
    payload: error
});

export const addJointUserBegin = () => ({
    type: ADD_JOINT_USER_BEGIN
});

export const addJointUserSuccess = (data) => ({
    type: ADD_JOINT_USER_SUCCESS,
    payload: data
});

export const addJointUserFailed = (error) => ({
    type: ADD_JOINT_USER_FAILED,
    payload: error
});

export const updateUserSettingsBegin = () => ({
    type: UPDATE_USER_SETTINGS_BEGIN
});

export const updateUserSettingsSuccess = (data) => ({
    type: UPDATE_USER_SETTINGS_SUCCESS,
    payload: data
});

export const updateUserSettingsFailed = (error) => ({
    type: UPDATE_USER_SETTINGS_FAILED,
    payload: error
});

export const getLocationHistoryBegin = () => ({
    type: GET_LOCATION_HISTORY_BEGIN
});

export const getLocationHistorySuccess = (data) => ({
    type: GET_LOCATION_HISTORY_SUCCESS,
    payload: data
});

export const getLocationHistoryFailed = (error) => ({
    type: GET_LOCATION_HISTORY_FAILED,
    payload: error
});

export const newsletterSubscribeBegin = () => ({
    type: NEWSLETTER_SUBSCRIBE_BEGIN
});

// export const newsletterSubscribeSuccess = (data) => ({
//     type: NEWSLETTER_SUBSCRIBE_SUCCESS,
//     payload: data
// });

export const newsletterSubscribeFailed = (error) => ({
    type: NEWSLETTER_SUBSCRIBE_FAILED,
    payload: error
});

export const updatePhoneNumberAuth = (phone: Array<any>) => ({
    type: UPDATE_PHONE_NUMBER_AUTH,
    payload: phone
});

export const authorize = () => {
    return async (dispatch: any) => {
        try {
            dispatch(authorizeBegin());
            const response = await api.get("/ident/oauth/authorize");
            dispatch(authorizeSuccess(response.data));
            return response.data.idp_state;
        } catch (error) {
            dispatch(authorizeFail(error.response.data));
        }
    };
};

export const login = (loginData: LoginParams) => {
    return async (dispatch: any) => {
        try {
            const idpState = await dispatch(authorize());
            dispatch(loginBegin());
            let request = {
                idp_state: idpState,
                username: loginData.username,
                password: loginData.password
            };
            const response = await api.post("/ident/oauth/login", request);
            StorageService.setKeyValue("loginInterface", {
                exp: response.data.exp,
                idp_state: response.data.idp_state
            });
            dispatch(loginSuccess(response.data));
        } catch (error) {
            dispatch(loginFail(error.response.data));
        }
    };
};

export const choosePerson = (personData: ChoosePersonParams) => {
    return async (dispatch: any) => {
        try {
            dispatch(choosePersonBegin());
            let request = { idp_state: personData.idp_state, person_id: personData.person_id };
            const response = await api.post("/ident/oauth/choose_person", request);
            dispatch(choosePersonSuccess(response.data));
            dispatch(getUserData());
        } catch (error) {
            dispatch(choosePersonFailed(error.response.data));
        }
    };
};

export const loginWithToken = (token: string) => {
    return async (dispatch: any) => {
        try {
            const idpState = await dispatch(authorize());
            dispatch(loginBegin());
            let request = {
                idp_state: await idpState,
                token: token
            };
            const response = await api.post("/ident/oauth/login_token", await request);
            dispatch(loginSuccess(response.data));
        } catch (error) {
            dispatch(loginFail(error.response.data));
        }
    };
};

export const logout = (reason?: string) => {
    return async (dispatch: any) => {
        try {
            dispatch(logoutBegin());
            await api.post("/ident/oauth/logout");
            dispatch(logoutSuccess(reason));
        } catch (error) {
            dispatch(logoutFail(error.response.data));
        }
    };
};

export const signupResend = (data: SignupResendIState) => {
    return async (dispatch: any) => {
        try {
            dispatch(signupResendBegin());
            const response = await api.post("/ident/oauth/signup_email", data);
            dispatch(signupResendSuccess(response.data));
        } catch (error) {
            dispatch(signupResendFail(error.response.data));
        }
    };
};

export const signup = (data: any) => {
    return async (dispatch: any) => {
        try {
            const idpState = await dispatch(authorize());
            dispatch(signupBegin());
            await api.post("/ident/oauth/signup", {
                ...data,
                idp_state: await idpState
            });
        } catch (error) {
            if (error.response.status === 401) {
                StorageService.setKeyValue("tokenidp", error.response.data.idp_state);
                return dispatch(signupSuccess(error.response.status));
            } else return dispatch(signupFail(error.response.data));
        }
    };
};

export const verify = (data: any) => {
    return async (dispatch: any) => {
        const idpState = await dispatch(authorize());
        dispatch(verifyBegin());
        try {
            await api.post("/ident/oauth/verify", {
                ...data,
                idp_state: idpState
            });
        } catch (error) {
            if (error.response.status === 401) {
                StorageService.setKeyValue("tokenidp", error.response.data.idp_state);
                return dispatch(verifySuccess(error.response.status));
            } else return dispatch(verifyFailed(error.response.data));
        }
    };
};

export const setPassword = (data: any) => {
    return async (dispatch: any) => {
        try {
            // await dispatch(authorize());
            dispatch(setPasswordBegin());
            const response = await api.post("/ident/oauth/set_password", {
                ...data,
                idp_state: StorageService.getDataByKey("tokenidp")
            });
            dispatch(setPasswordSuccess(response.data));
            StorageService.deleteCookieByKey("tokenidp");
        } catch (error) {
            dispatch(setPasswordFailed(error?.response?.data));
        }
    };
};

export const sendResetPasswordRequest = (email: string) => {
    return async (dispatch: any) => {
        try {
            const idpState = await dispatch(authorize());
            dispatch(sendResetPasswordBegin());
            await api.post("/ident/oauth/reset_password", {
                idp_state: idpState,
                email_address: email
            });
        } catch (error) {
            if (error.response.status === 401) {
                StorageService.setKeyValue("tokenidp", error.response.data.idp_state);
                return dispatch(sendResetPasswordSuccess(error.response.data));
            } else return dispatch(sendResetPasswordFailed(error?.response.data));
        }
    };
};

export const setPersonalDetails = (data: any) => {
    return async (dispatch: any) => {
        try {
            dispatch(setPersonalDetailsBegin());
            const response = await api.put("/ident/self/person", data);
            dispatch(setPersonalDetailsSuccess(response.data));
        } catch (error) {
            dispatch(setPersonalDetailsFailed(error.response.data));
        }
    };
};

export const setPersonalAddress = (data: any) => {
    return async (dispatch: any) => {
        try {
            dispatch(setPersonalAddresBegin());
            const response = await api.post("/ident/self/address", data);
            dispatch(setPersonalAddresSuccess(response.data));
        } catch (error) {
            dispatch(setPersonalAddresFailed(error.response.data));
        }
    };
};

export const verifyPhone = (phone_number: string) => {
    return async (dispatch: any) => {
        dispatch(verifyPhoneBegin());
        try {
            const response = await api.post("/ident/self/phone", { phone_number: phone_number });
            dispatch(verifyPhoneSuccess(response));
            return response.data;
        } catch (error) {
            dispatch(verifyPhoneFailed(error.response.data));
            return error.response.data;
        }
    };
};

export const sentPhoneVerificationCode = (phoneNumber: string, verificationCode: string) => {
    return async (dispatch: any) => {
        dispatch(sentPhoneVerificationCodeBegin());
        try {
            const response = await api.put("/ident/self/phone", {
                phone_number: phoneNumber,
                verification_code: verificationCode
            });
            dispatch(sentPhoneVerificationCodeSuccess(response.data.need_verification));
            return response;
        } catch (error) {
            dispatch(sentPhoneVerificationCodeFailed(error.response.data));
        }
    };
};

export const authorization = () => {
    return async (dispatch: any) => {
        // dispatch(authorizationBegin());
        try {
            await api.get("/core/person/authorization");
            dispatch(authorizationSuccess());
        } catch (error) {
            dispatch(logout());
            dispatch(authorizationFailed(error?.response?.data));
        }
    };
};

export const getAccount = () => {
    return async () => {
        try {
            return await api.get("/core/account");
        } catch (error) {
            console.debug("error", error);
        }
    };
};

export const getAddress = () => {
    return async () => {
        try {
            return await api.get("/ident/self/address");
        } catch (error) {
            console.debug("error", error);
        }
    };
};

export const getPhone = () => {
    return async () => {
        try {
            return await api.get("/ident/self/phone");
        } catch (error) {
            console.debug("error", error);
        }
    };
};

export const getPerson = () => {
    return async () => {
        try {
            return await api.get("/ident/person");
        } catch (error) {
            console.debug("error", error);
        }
    };
};

export const getSettings = () => {
    return async () => {
        try {
            return await api.get("/ident/self/settings");
        } catch (error) {
            console.debug("error", error);
        }
    };
};

export const getJointUser = () => {
    return async () => {
        try {
            return await api.get("/ident/self/joint_person");
        } catch (error) {
            console.debug("error", error);
        }
    };
};

export const getUserData = () => {
    return (dispatch: any) => {
        try {
            dispatch(getUserDataBegin());
            Promise.all([
                dispatch(getAccount()),
                dispatch(getAddress()),
                dispatch(getPhone()),
                dispatch(getPerson()),
                dispatch(getSettings()),
                dispatch(getJointUser())
            ]).then((value) => {
                if (value[3].data.is_merchant) {
                    dispatch(logout("merchant_cannot_login"));
                    return;
                }
                dispatch(getUserDataSuccess(value));
            });
        } catch (error) {
            dispatch(getUserDataFailed(error));
        }
    };
};

export const getSelfToken = () => {
    return async () => {
        try {
            const response = await api.get("/ident/self/token");
            return response?.data?.token;
        } catch (error) {
            console.debug(error);
        }
    };
};

export const getEyesonGuestToken = (ta_id, product_order_id, product_order_item_id) => {
    return async () => {
        try {
            const response = await api.get(
                `/core/eyeson/guest?ta_id=${ta_id}&product_order_id=${product_order_id}&product_order_item_id=${product_order_item_id}`
            );
            return response;
        } catch (error) {
            console.debug(error);
        }
    };
};

export const addAddress = (address: any) => {
    return async (dispatch: any) => {
        try {
            // dispatch(addAddressBegin());
            const response = await api.post("/ident/self/address", address);
            dispatch(addAddressSuccess(response.data));
            dispatch(getUserData());
        } catch (error) {
            // dispatch(addAddressFail(error.response.data));
        }
    };
};

export const updateAddress = (address_id: string, address: any) => {
    return async (dispatch: any) => {
        try {
            // dispatch(updateAddressBegin());
            const response = await api.put(`/ident/self/address/${address_id}`, address);
            dispatch(updateAddressSuccess(response.data));
            dispatch(getUserData());
        } catch (error) {
            dispatch(updateAddressFail(error.response.data));
        }
    };
};

export const deleteAccount = () => {
    return async (dispatch: any) => {
        try {
            // dispatch(deleteAccountBegin());
            const response = await api.delete("/ident/self/person");
            return dispatch(deleteAccountSuccess(response.data));
        } catch (error) {
            return dispatch(deleteAccountFailed(error.response.data));
        }
    };
};

export const addJointUser = (relationship: string, address: any, details: any, isRegisteringAsNewUser: boolean) => {
    return async (dispatch: any) => {
        try {
            // dispatch(addJointUserBegin());
            const response = await api.post(
                "/ident/self/joint_person",
                address?.street === ""
                    ? {
                          birth_date: details.birthDate,
                          given_name: details.firstName,
                          language: details.language,
                          name: details.lastName,
                          relationship: relationship,
                          sex: details.sex
                      }
                    : {
                          address: {
                              building_identifier: address.houseNumber,
                              city: address.city,
                              country: address.country,
                              post_code: address.postCode,
                              street: address.street
                          },
                          birth_date: details.birthDate,
                          given_name: details.firstName,
                          language: details.language,
                          name: details.lastName,
                          relationship: relationship,
                          sex: details.sex
                      }
            );

            if (!isRegisteringAsNewUser) await dispatch(getUserData());
            return dispatch(addJointUserSuccess(response.data));
        } catch (error) {
            return dispatch(addJointUserFailed(error.response.data));
        }
    };
};

export const updateSettings = (data: any) => {
    return async (dispatch: any) => {
        try {
            dispatch(updateUserSettingsBegin());
            const response = await api.put("/ident/self/settings", data);
            dispatch(getUserData());
            return dispatch(updateUserSettingsSuccess(response.data));
        } catch (error) {
            return dispatch(updateUserSettingsFailed(error.response.data));
        }
    };
};

export const getLocationHistory = (taId: string, locationId: string) => {
    return async (dispatch: any) => {
        dispatch(getLocationHistoryBegin());
        try {
            const response = await api.get(`/core/person/location_history?ta_id=${taId}&location_id=${locationId}`);
            dispatch(
                getLocationHistorySuccess({
                    data: response.data,
                    testResultObject: { ta_id: taId, location_id: locationId }
                })
            );
        } catch (error) {
            dispatch(getLocationHistoryFailed(error?.response?.data));
        }
    };
};

export const newsletterSubscribe = (email: string) => {
    return async (dispatch: any) => {
        try {
            dispatch(newsletterSubscribeBegin());
            const response = await fetch("https://alpha.ecocare.de/api/subscribe", {
                method: "POST",
                body: JSON.stringify({ email })
            });
            return response;
            // return dispatch(newsletterSubscribeSuccess(response));
        } catch (error) {
            return dispatch(newsletterSubscribeFailed(error || "Something went wrong!"));
        }
    };
};

export const verifyRecaptchaToken = (token: string) => {
    return async (dispatch: any) => {
        try {
            dispatch({
                type: VERIFY_TOKEN_RECAPTCHA_BEGIN
            });
            const response = await api.post(`/verify-token`, {
                reCAPTCHA_TOKEN: token
            });
            dispatch({
                type: VERIFY_TOKEN_RECAPTCHA_SUCCESS,
                payload: response
            });
            return response;
        } catch (error) {
            return dispatch({
                type: VERIFY_TOKEN_RECAPTCHA_FAILED,
                payload: error
            });
        }
    };
};
