import { handleSnackbar } from "actions/layout/layout";
import { useAppDispatch } from "hooks/hooks";
import { useRef } from "react";

const useSnackbar = () => {
    const dispatch = useAppDispatch();
    const disableFunctionRef = useRef<boolean>(false);

    const showSnackBar = (message: string, color: string) => {
        if (disableFunctionRef.current) return;
        dispatch(handleSnackbar(true, message, color));
        disableFunctionRef.current = true;

        setTimeout(() => {
            dispatch(handleSnackbar(false));
            disableFunctionRef.current = false;
        }, 4000);
    };

    return {
        showSnackBar
    };
};

export default useSnackbar;
