import {
    CANCEL_ORDER_BEGIN,
    CANCEL_ORDER_FAILED,
    CANCEL_ORDER_SUCCESS,
    CLEAR_ACTION,
    ORDER_BEGIN,
    ORDER_FAILED,
    ORDER_SUCCESS,
    PAYPAL_ORDER_BEGIN,
    PAYPAL_ORDER_SUCCESS,
    PAYPAL_ORDER_FAILED,
    REQUEST_INVOICE_BEGIN,
    REQUEST_INVOICE_FAILED,
    REQUEST_INVOICE_SUCCESS,
    USE_VOUCHER_BEGIN,
    USE_VOUCHER_FAILED,
    USE_VOUCHER_SUCCESS
} from "./../../actions/checkout/checkout";
import { produce } from "immer";
import StorageService from "services/storage.service";

const initialState: any = {
    orderData: null,
    action: {
        isLoading: false,
        error: null,
        message: "",
        isSuccess: false,
        actionName: null,
        isVoucher: false
    }
};

const checkoutReducer = (state = initialState, action) =>
    produce(state, (draft) => {
        switch (action.type) {
            case PAYPAL_ORDER_BEGIN:
            case ORDER_BEGIN:
            case REQUEST_INVOICE_BEGIN:
            case CANCEL_ORDER_BEGIN:
            case USE_VOUCHER_BEGIN:
                draft.action.isLoading = true;
                draft.action.isSuccess = false;
                draft.action.error = null;
                break;
            case PAYPAL_ORDER_FAILED:
            case ORDER_FAILED:
            case REQUEST_INVOICE_FAILED:
            case CANCEL_ORDER_FAILED:
            case USE_VOUCHER_FAILED:
                draft.action.isLoading = false;
                draft.action.error = action.payload || "Unknown error";
                draft.action.isSuccess = false;
                draft.action.actionName = action.type;
                break;
            case PAYPAL_ORDER_SUCCESS:
            case ORDER_SUCCESS:
            case REQUEST_INVOICE_SUCCESS:
            case CANCEL_ORDER_SUCCESS:
                StorageService.deleteCookieByKey("voucher");
                draft.action.isLoading = false;
                draft.action.isSuccess = true;
                draft.action.isVoucher = false;
                break;
            case USE_VOUCHER_SUCCESS:
                StorageService.setKeyValue("voucher", [
                    action.payload.data,
                    { appliedVoucher: action.payload.appliedVoucher }
                ]);
                draft.action.isLoading = false;
                draft.action.isSuccess = true;
                draft.action.isVoucher = true;
                break;
            case CLEAR_ACTION:
                draft.action = initialState.action;
                break;
            default:
                break;
        }
    });

export default checkoutReducer;
