import React from "react";
import { useSelector } from "react-redux";
import { Outlet, useNavigate } from "react-router-dom";
import { GlobalIState } from "models";
import { CloseButton } from "utils/constants/images";
import { useAppDispatch } from "hooks/hooks";
import { setShowShoppingCartView } from "actions/layout/layout";
import { totalPrice } from "utils/utils";
import { useTranslations } from "hooks/UseTranslations";
import ModalComponent from "components/Modal/ModalComponent";
import AuthNavbar from "components/Header/AuthNavbar/AuthNavbar";
import CartItem from "components/CartItem/CartItem";
import Button from "components/Button/Button";
import Snackbar from "components/SnackBar/SnackBar";
import useCheckMobileScreen from "hooks/UseCheckMobileScreen/UseCheckMobileScreen";

const AuthLayoutMiddleware: React.FC<any> = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { translations } = useTranslations();
    const {
        modalReducer: { openModal },
        layoutReducer: { shoppingCart, showShoppingCartView }
    } = useSelector((state: GlobalIState) => state);
    const isMobile = useCheckMobileScreen();

    return (
        <>
            <Snackbar />
            <div className={`home-page-main ${openModal ? "blured" : null}`}>
                <AuthNavbar />
                <div className={`flex-grow-1 ${!isMobile ? "bg-light" : null}`}>
                    <Outlet />
                </div>
            </div>
            {showShoppingCartView ? (
                <div className="shopping-cart-right-popup">
                    <div className="popup-header">
                        <div>
                            <h1>Cart</h1>
                        </div>
                        <div>
                            <button type="button" onClick={(e) => dispatch(setShowShoppingCartView(false))}>
                                <img src={CloseButton} alt="Close Button" />
                            </button>
                        </div>
                    </div>
                    {shoppingCart.length > 0 ? (
                        <div className="cart-items">
                            {shoppingCart.map((el) => {
                                return <CartItem key={el.product_id} product={el} checkout={false} />;
                            })}
                            <div className="justifyBetween">
                                <h3>Total</h3>
                                <h3>
                                    <b>€ {totalPrice(shoppingCart) / 100}</b>
                                </h3>
                            </div>
                            <div className="popup-bottom-buttons">
                                <Button label={translations("button.checkout")} handleClick={() => {}} />
                                <Button label={translations("button.view_cart")} handleClick={() => navigate("cart")} />
                            </div>
                        </div>
                    ) : (
                        <div>
                            <p>{translations("label.there_are_no_items_selected")}</p>
                        </div>
                    )}
                </div>
            ) : null}
            <ModalComponent />
        </>
    );
};
export default AuthLayoutMiddleware;
