import { useEffect } from "react";
import { postConfirmHomeTest, postConfirmHomeTestManually, resetAction } from "actions/dashboard/dashboard";
import { useAppDispatch, useAppSelector } from "hooks/hooks";
import { useTranslations } from "hooks/UseTranslations";
import { useNavigate } from "react-router-dom";
import Button from "components/Button/Button";
import dayjs from "dayjs";
import styles from "./PreScannedKit.module.scss";
import useCheckMobileScreen from "hooks/UseCheckMobileScreen/UseCheckMobileScreen";
import useSnackbar from "hooks/UseSnackBar/useSnackBar";

interface Props {
    item?: any;
    setBarcode: React.Dispatch<React.SetStateAction<string>>;
    barcode: string;
    manually?: boolean;
}

export default function PreScannedKit({ item, setBarcode, barcode, manually }: Props) {
    const dispatch = useAppDispatch();
    const { translations } = useTranslations();
    const { action } = useAppSelector((state) => state.dashboardReducer);
    const { showSnackBar } = useSnackbar();

    const navigate = useNavigate();
    const isMobile = useCheckMobileScreen();

    useEffect(() => {
        if (action.actionName === "POST_CONFIRM_HOME_TEST_SUCCESS") {
            navigate("/web/home-testing/instructions");
            showSnackBar(translations("error.the_barcode_is_successfully"), "green");
        }
        if (action.actionName === "POST_CONFIRM_HOME_TEST_FAILED") {
            showSnackBar(translations("app.service.error.1030316"), "red");
        }
        if (action.actionName === "GET_PRODUCT_BARCODE_FAILED") {
            showSnackBar(translations(`app.service.error.${action.error.error_code}`), "red");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
        return () => {
            dispatch(resetAction());
        };
    }, [action]);

    const handleConfirm = (e) => {
        e.preventDefault();
        if (manually) {
            dispatch(postConfirmHomeTestManually(barcode));
        } else dispatch(postConfirmHomeTest(item.ta_id, item.product_order_id, item.product_order_item_id, barcode));
    };

    return (
        <>
            <div className={styles.cubeContainer}>
                <i className={styles.cubeIcon} />
            </div>
            <div className="wrapper">
                {!manually && (
                    <div>
                        <p className={styles.title}>{item.product_name}</p>
                        <p className={styles.subTitle}>{`${dayjs(item.booking_date).format(
                            "DD.MM.YYYY"
                        )}  |  ${translations("label.home_test")}`}</p>
                    </div>
                )}
                <p className={styles.confirmBarcode}>{translations("label.confirm_barcode")}</p>
                {!isMobile && <div className="divider mb-3" />}
                <div className={styles.box}>
                    <div className={styles.textBox}>
                        {manually ? (
                            <input onChange={(e) => setBarcode(e.target.value)} value={barcode} />
                        ) : (
                            <input disabled onChange={(e) => setBarcode(e.target.value)} value={barcode} />
                        )}
                    </div>
                    <div className="content">
                        <p>{translations("label.note_sample")} </p>
                        {/* <p>Edit manually if needed. An incorrect sample number will not allow to start the test.</p> */}
                    </div>
                    <Button
                        class={"btn btn-blue"}
                        style={{ maxWidth: "350px", width: "100%" }}
                        label={translations("label.confirm")}
                        handleClick={handleConfirm}
                    />
                </div>
            </div>
        </>
    );
}
