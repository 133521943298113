import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "hooks/hooks";
import { getLocations, getMarchant, setSearchKeysHome, setLanguage } from "actions/home/home";
import {
    getMenuItems,
    populateShoppingCart,
    clearShoppingCart,
    getPublicMerchantMenuItems,
    setDiscountParam
} from "actions/layout/layout";
import { Navigate, Outlet, useSearchParams } from "react-router-dom";
import { authorization, getUserData, loginWithToken } from "actions/authentication/authentication";
import { addAccountNumberPostfix } from "helpers";
import { useTranslations } from "hooks/UseTranslations";
import { checkProductsUrl } from "utils/utils";
import StorageService from "services/storage.service";
import properties from "properties";
import dayjs from "dayjs";
import useSnackbar from "hooks/UseSnackBar/useSnackBar";

export default function EstablishConnectionMiddleware() {
    const {
        homePageReducer: { locations },
        authenticationReducer: { isLoggedIn, action, userProfile },
        layoutReducer: { isProductsInlineUrl }
    } = useAppSelector((state) => state);
    const dispatch = useAppDispatch();
    const [searchParams] = useSearchParams();

    let shoppingCartStorage = localStorage.getItem("shoppingCart");
    let bookAppointmentProduct = localStorage.getItem("bookAppointmentProduct");
    let accountNumber = searchParams.get("account_number");
    let token = searchParams.get("token");
    let languageParam = searchParams.get("language");
    let discount = searchParams.get("discount");
    let homePagePath = window.location.pathname === "/web" || window.location.pathname === "/web/";
    const { translations } = useTranslations();
    const { showSnackBar } = useSnackbar();

    localStorage.removeItem("notification_data");

    // Checks if user is loggedIn
    useEffect(() => {
        dispatch(authorization());
    }, [dispatch]);

    // If user is loggedIn fetch userInformations
    useEffect(() => {
        if (isLoggedIn && !userProfile) {
            dispatch(getUserData());
        }
        if (action.actionName === "LOGOUT_SUCCESS" && action.message === "merchant_cannot_login") {
            showSnackBar(translations("error.merchant_cannot_login"), "red");
        }
    }, [dispatch, userProfile, isLoggedIn, action.message === "merchant_cannot_login"]); // eslint-disable-line react-hooks/exhaustive-deps

    // Fetches marchant data and checks if accountNumber is stored
    useEffect(() => {
        dispatch(setDiscountParam(discount));
        dispatch(setSearchKeysHome(["officialName", "price"]));
        if (accountNumber) {
            dispatch(getMarchant(accountNumber));
            dispatch(getMenuItems(Number(properties.PARENT_MENU), accountNumber));
            let storedMerchantNumber = StorageService.getDataByKey("merchantData")?.merchantNumber;
            if (storedMerchantNumber !== accountNumber) {
                dispatch(clearShoppingCart());
            } else {
                if (shoppingCartStorage || bookAppointmentProduct) dispatch(populateShoppingCart());
            }
        } else {
            if (locations.length === 0) {
                // dispatch(clearShoppingCart());
                dispatch(getLocations());
                dispatch(getPublicMerchantMenuItems(Number(properties.PARENT_MENU)));
            }
        }
    }, [dispatch, accountNumber]); // eslint-disable-line react-hooks/exhaustive-deps

    // Keep session alive
    useEffect(() => {
        const interval = setInterval(() => dispatch(authorization()), 5 * 60 * 1000);
        return () => clearInterval(interval);
    });

    // If token param login with token
    useEffect(() => {
        if (token && window.location.pathname !== "/web/verify" && window.location.pathname !== "/web/paypal") {
            dispatch(loginWithToken(token));
        }
        // If token language parameter, set language on init
        if (languageParam) {
            dispatch(setLanguage(languageParam));
            dayjs.locale(languageParam);
        }
    }, [dispatch, token, languageParam]);

    return homePagePath && !token ? (
        <Navigate
            to={`${
                accountNumber
                    ? addAccountNumberPostfix(
                          `/web/${checkProductsUrl(isProductsInlineUrl ? "productsinline" : "products")}`
                      )
                    : `/web/${checkProductsUrl(isProductsInlineUrl ? "productsinline" : "products")}`
            }`}
        />
    ) : (
        <Outlet />
    );
}
