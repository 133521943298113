import React, { useEffect, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "hooks/hooks";
import { filterByGroupId, clearFilter } from "actions/home/home";
import { setSelectedMenu } from "actions/layout/layout";
import { useTranslations } from "hooks/UseTranslations";
import { locationsWithCheapestPriceOfProduct } from "utils/utils";
import { siwtchPropBasedOnLang } from "helpers";
import { WithLoader } from "components";
import { LazyLoadImage } from "react-lazy-load-image-component";
import ProductsFilter from "./ProductsFilter/ProductsFilter";
import SingleHealthpointShopHeader from "components/Header/SingleHealthpointShopHeader/SingleHealthpointShopHeader";
import ProductItem from "components/ProductItem/ProductItem";
import useCheckMobileScreen from "hooks/UseCheckMobileScreen/UseCheckMobileScreen";
import GTMEventsHandler from "utils/GTMEventsHandler";
import discountBanner from "../../styles/assets/discountBanner.png";
import discountBannerDE from "../../styles/assets/discountBannerDE.png";
import styles from "./Products.module.scss";

interface Props {}

const Products: React.FC<Props> = () => {
    const dispatch = useAppDispatch();
    const { translations } = useTranslations();
    const {
        authenticationReducer: { userProfile },
        layoutReducer: { selectedMenu, menuItems, publicMenuItems, showDiscount },
        homePageReducer: { filteredProducts, locations, merchantData, products, action, language, selectedMainToggleId }
    } = useAppSelector((state) => state);
    console.log(window.location.origin);

    const isMobile = useCheckMobileScreen();
    const [searchParams] = useSearchParams();
    let groupId = searchParams.get("group_id");
    let accountNumber = searchParams.get("account_number");

    const menuItemsData = accountNumber ? menuItems : publicMenuItems;
    const hasProductGroupH = Boolean(merchantData?.products?.some((element) => element.product_group === "H"));

    useEffect(() => {
        if (userProfile) {
            (window as any).pendo.initialize({
                visitor: {
                    id: `${userProfile.person_id}`,
                    email: `${userProfile?.person?.primary_email_address}`,
                    full_name: `${userProfile?.given_name} ${userProfile.name}`
                },
                account: {
                    id: `${userProfile.account_id}`
                }
            });
        }
    }, [userProfile]);

    useEffect(() => {
        document.title = `Ecocare`;
        if (groupId) {
            dispatch(
                filterByGroupId({
                    id: groupId,
                    menuItems: menuItemsData,
                    // selectedMainToggleId: selectedMainToggleId,
                    products: accountNumber ? products : locationsWithCheapestPriceOfProduct(locations)
                })
            );
            dispatch(setSelectedMenu(groupId, false, menuItemsData));
        } else {
            dispatch(clearFilter());
            dispatch(setSelectedMenu("", false));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        dispatch,
        groupId,
        accountNumber,
        products
        // selectedMainToggleId
    ]);

    const firstLanding = useRef(true);
    useEffect(() => {
        if (firstLanding.current) {
            firstLanding.current = false;
            return;
        }
        if (filteredProducts) {
            const data = filteredProducts?.map((el: any) => {
                return {
                    officialName: el.officialName,
                    product_id: el.product_id,
                    price: el.price,
                    item_brand: "",
                    item_variant: "",
                    quantity: 1,
                    item_list_name: "",
                    item_list_id: groupId
                };
            });
            GTMEventsHandler("view_item_list", {
                items: data
            });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filteredProducts]);

    const category = siwtchPropBasedOnLang(selectedMenu?.parent, language);
    const selectedCategory = ["All", "Alle"].includes(category) ? translations("products.all_products") : category;
    const selectedChildCategory = siwtchPropBasedOnLang(selectedMenu?.child, language);

    return (
        <div className="container" style={{ minHeight: "80vh" }}>
            {showDiscount && (
                <LazyLoadImage
                    src={language === "de" ? discountBannerDE : discountBanner}
                    alt="discount"
                    className={styles.discountBanner}
                />
            )}
            <WithLoader isLoading={action.isLoading}>
                {!hasProductGroupH && merchantData && accountNumber ? <SingleHealthpointShopHeader /> : null}
                <div className="d-flex">
                    {!isMobile && (
                        <div className={styles.selectedMenuSection}>
                            <div className={styles.menus}>
                                <span className={styles.primaryMenu}>{translations("title.home")}</span>
                                <span className={styles.primaryMenu}>|</span>
                                <span className={styles.selectedMenu}>{selectedCategory}</span>
                                {selectedMenu?.child && (
                                    <>
                                        <span className={styles.primaryMenu}>|</span>
                                        <span className={styles.selectedMenu}>{selectedChildCategory}</span>
                                    </>
                                )}
                            </div>
                        </div>
                    )}
                </div>

                <div className={styles.mainContent}>
                    <div className={styles.leftSidebar}>{<ProductsFilter />}</div>

                    <div className={styles.productsContainer}>
                        <p className={styles.title}>{selectedCategory}</p>
                        <div className={styles.products}>
                            {filteredProducts?.length > 0 ? (
                                filteredProducts?.map((item, i) => {
                                    return (
                                        <div key={item.product_id} className={styles.product}>
                                            <ProductItem
                                                product={item}
                                                idx={i}
                                                isMobile={isMobile}
                                                showDiscount={showDiscount}
                                            />
                                        </div>
                                    );
                                })
                            ) : (
                                <div className={styles.zeroPoductsContainer}>
                                    <h3 className={styles.categoriesBlueTitle}>
                                        {translations("title.there_are_no_products_for")}
                                    </h3>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </WithLoader>
        </div>
    );
};
export default Products;
