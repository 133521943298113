import React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Typography from "@mui/material/Typography";
import { useStyles } from "hooks/hooks";
import { useTranslations } from "hooks/UseTranslations";

interface Props {
    steps: string[];
    activeStep: number;
    completed: number[];
    children?: any;
}

export default function HorizontalStepper({ steps, activeStep, completed, children }: Props) {
    const { translations } = useTranslations();

    const isStepCompleted = (step: number) => {
        return completed?.includes(step);
    };

    const styles = useStyles({
        root: {
            "& .Mui-active": { color: "#0b1b5c !important" },
            "& .Mui-completed": { color: "#0b1b5c !important " },
            "& .Mui-disabled .MuiStepIcon-root": { color: "cyan" },
            "& .MuiStepLabel-label": { fontWeight: "bold" }
        }
    });

    return (
        <>
            <Box sx={{ width: { sm: "70%", md: "60%", lg: "50%" } }} style={{ marginBottom: "20px" }}>
                <Stepper nonLinear activeStep={activeStep} className={styles.root}>
                    {steps.map((label, index) => {
                        return (
                            <Step key={label} completed={isStepCompleted(index)}>
                                <StepLabel>
                                    <Typography sx={{ fontWeight: "bold", color: "#0b1b5c" }}>
                                        {translations(label)}
                                    </Typography>
                                </StepLabel>
                            </Step>
                        );
                    })}
                </Stepper>
            </Box>
            {children || null}
        </>
    );
}
