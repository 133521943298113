import { useState } from "react";
import { Outlet } from "react-router-dom";
import { Toolbar } from "@mui/material";
import { Footer } from "components";
import SideBar from "../Sidebar/Sidebar";
import AppBarComponent from "./AppBar/AppBar";
import useCheckMobileScreen from "hooks/UseCheckMobileScreen/UseCheckMobileScreen";

const PurchassingLayout = ({ handleProfileOpen, handleLanguageOpen, classes }) => {
    const [mobileOpen, setMobileOpen] = useState<boolean>(false);
    let isMobile = useCheckMobileScreen();

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    return (
        <>
            <AppBarComponent
                classes={classes}
                handleDrawerToggle={handleDrawerToggle}
                handleLanguageOpen={handleLanguageOpen}
                handleProfileOpen={handleProfileOpen}
            />
            <SideBar
                handleDrawerToggle={() => handleDrawerToggle()}
                mobileOpen={mobileOpen}
                purchasingLayout={true}
                handleLanguageOpen={handleLanguageOpen}
            />
            <main className="main">
                <Toolbar style={isMobile ? { minHeight: "65px" } : { minHeight: "135px" }} />
                <Outlet />
                <Footer />
            </main>
        </>
    );
};

export default PurchassingLayout;
