import "./ProductImageSlider.scss";
import "react-image-gallery/styles/css/image-gallery.css";
import ImageGallery from "react-image-gallery";

interface IImages {
    original: string;
    thumbnail: string;
}
interface Props {
    images: IImages[];
    isMobile: Boolean;
    thumbnailPosition: String;
}
const ProductImageSlider: React.FC<Props> = (props) => {
    const { images, isMobile, thumbnailPosition } = props;

    return images ? (
        <ImageGallery items={images} showBullets={isMobile ? true : false} showThumbnails={isMobile ? false : true} thumbnailPosition={thumbnailPosition} />
    ) : null;
};

export default ProductImageSlider;
