import { Link, useNavigate } from "react-router-dom";
import { Box, IconButton, Toolbar, AppBar } from "@mui/material";
import { useAddAccountNumberPostfix, useAppSelector, useCheckEnvAndLang } from "hooks/hooks";
import { useTranslations } from "hooks/UseTranslations";
import styles from "../../layout.module.scss";

export default function AppBarDashboard({ classes, handleDrawerToggle, handleProfileOpen, handleLanguageOpen }) {
    const {
        authenticationReducer: { isLoggedIn, userProfile },
        layoutReducer: { shoppingCart },
        homePageReducer: { language }
    } = useAppSelector((state) => state);
    const nav = useNavigate();
    const { getRoute } = useAddAccountNumberPostfix();
    const { translations } = useTranslations();
    const EcocareCareUrl = useCheckEnvAndLang();
    const shoppingCartItems = shoppingCart.reduce((res, el) => {
        return res + el._quantity;
    }, 0);

    return (
        <AppBar className={classes.appBar} position="fixed">
            <Toolbar className={classes.toolBar}>
                <Toolbar sx={{ display: { xs: "none", lg: "flex" } }}>
                    <div onClick={() => (window.location.href = EcocareCareUrl)} className={styles.ecocareLogoSvg} />
                </Toolbar>

                {/* ***************** Hamburger Icon and Logo in the middle ************** */}
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    edge="start"
                    onClick={handleDrawerToggle}
                    sx={{ display: { lg: "none" } }}>
                    <i className="hamburger-icon mx-1"></i>
                </IconButton>
                <Toolbar sx={{ display: { lg: "none" } }}>
                    <div onClick={() => (window.location.href = EcocareCareUrl)} className={styles.ecocareLogoSvg} />
                </Toolbar>

                {/* **************Profile Info (visible only on "md" ang "lg" screens)*************** */}
                <Box sx={{ marginRight: "20px", display: { xs: "none", lg: "flex" } }}>
                    <div className={styles.languagesContainer} onClick={handleLanguageOpen}>
                        <i className={styles.languageIcon} />
                        <div className={styles.languageTitle}>{language?.toUpperCase()}</div>
                    </div>
                    <Link to={getRoute("/web/cart")}>
                        <i className="cart-icon">{shoppingCartItems || ""}</i>
                    </Link>
                    {isLoggedIn && userProfile ? (
                        <div className={styles.userProfileWrapper} onClick={handleProfileOpen}>
                            <i className="user-icon mr-1"></i>
                            <div className="d-flex" style={{ flexDirection: "column", width: "max-content" }}>
                                <p>{`${userProfile.person.given_name} ${userProfile.person.name}`}</p>
                                <p className={styles.secondaryText}>{translations("label.customer")}</p>
                            </div>
                            <i className="arrowDownIcon ml-1"></i>
                        </div>
                    ) : (
                        <IconButton
                            size="large"
                            aria-label="user"
                            color="inherit"
                            onClick={() =>
                                nav("/web/login", {
                                    state: { lastRoute: window.location.pathname + window.location.search }
                                })
                            }>
                            <i className="user-icon"></i>
                        </IconButton>
                    )}
                </Box>

                {/* **************Cart icon (visible only on small screens)*************** */}
                <Box sx={{ display: { xs: "flex", lg: "none" } }}>
                    <Link to={getRoute("/web/cart")}>
                        <i className="cart-icon">{shoppingCartItems || ""}</i>
                    </Link>
                </Box>
            </Toolbar>
        </AppBar>
    );
}
