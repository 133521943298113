import React from "react";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useAppDispatch, useStyles } from "hooks/hooks";
import { WHITELISTED_PURCHASING_LAYOUT_ROUTES } from "enums";
import { ModalComponent } from "components";
import { deleteIsProductsInlineUrlCookie, setIsProductsInlineUrlCookie } from "actions/layout/layout";
import PurchassingLayout from "./PurchasingLayout/PurchasingLayout";
import DashboardLayout from "./DashboardLayout/DashboardLayout";
import LanguageDropdown from "components/LanguageDropdown/LanguageDropdown";
import ProfileDropdown from "components/ProfileDropdown/ProfileDropdown";
import styles from "./layout.module.scss";
import Snackbar from "components/SnackBar/SnackBar";

export default function LayoutMiddleware(props: any) {
    const dispatch = useAppDispatch();
    const classes = useStyles({
        appBar: {
            backgroundColor: "#fff !important",
            boxShadow: "none !important",
            alignItems: "center"
        },
        wrapper: {
            borderBottom: "1px solid #F1F1F1 !important",
            width: "100%",
            display: "flex",
            justifyContent: "center"
        },
        content: {
            "@media (max-width: 650px)": {
                padding: "0px"
            },
            flexGrow: 1,
            padding: "0px 40px",
            display: "block",
            maxWidth: "1920px",
            minWidth: "300px"
        },
        container: {
            display: "flex",
            width: "100%",
            // maxWidth: "1920px",
            maxWidth: "1640px"
        },
        containerPurchasing: {
            width: "100%"
        },
        popupMenu: {
            "& .MuiList-root": {
                padding: "0px !important"
            },
            maxHeight: "350px !important",
            "& .MuiMenu-paper": {
                marginTop: "5px",
                boxShadow: "0px 8px 18px #1A284D1A !important",
                border: "1px solid #EBECF2 !important",
                borderRadius: "5px !important"
            }
        },
        menuItem: {
            fontSize: "14px !important",
            width: "204px",
            height: "40px"
        },
        active: {
            backgroundColor: "#edeff5 !important"
        },
        logoToolbar: {
            borderBottom: "1px solid #d8d8d8",
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
        },
        toolBar: {
            display: "flex",
            justifyContent: "space-between",
            width: "1640px",
            maxWidth: "100%"
        },
        purchasingToolBar: {
            display: "flex",
            justifyContent: "space-between",
            width: "1280px",
            padding: "0px !important"
        },
        mainNavBar: {
            "@media (max-width: 900px)": {
                display: "none"
            }
        },
        btn: {
            display: "flex !important",
            alignItems: "center !important",
            justifyContent: "center !important",
            // marginLeft: "23px !important",
            fontSize: "15px !important",
            lineHeight: "24px !important",
            color: "#10121E !important",
            borderRadius: "0px !important",
            textTransform: "none !important",
            fontFamily: "PolySans Neutral, sans-serif !important",
            "&:hover": {
                backgroundColor: "#fff !important"
            }
        },
        btnActive: {
            color: "#0B1B5C !important"
            // borderBottom: " 2px solid #0B1B5C !important",
        },
        mobileOutlet: {
            height: "100vh"
        }
    });

    const location = useLocation();
    const [profileAnchor, setProfileAnchor] = useState<null | HTMLElement>(null);
    const [languageAnchorEl, setLanguageAnchorEl] = useState<null | HTMLElement>(null);
    // const { language } = useAppSelector((state) => state.homePageReducer);
    // const { translations } = useTranslations();
    const checkIsPurchasingRoute = Boolean(WHITELISTED_PURCHASING_LAYOUT_ROUTES.includes(location.pathname));

    const handleProfileOpen = (event: React.MouseEvent<HTMLElement> | null) => {
        if (!event) return setProfileAnchor(null);
        return setProfileAnchor(event.currentTarget);
    };

    const handleLanguageOpen = (event: React.MouseEvent<HTMLElement> | null) => {
        if (!event) return setLanguageAnchorEl(null);
        return setLanguageAnchorEl(event.currentTarget);
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        if (window.location.pathname.includes("productsinline")) dispatch(setIsProductsInlineUrlCookie(true));
        else if (window.location.pathname.includes("products")) dispatch(deleteIsProductsInlineUrlCookie());

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <div className={checkIsPurchasingRoute ? "" : styles.wrapper}>
                <Snackbar />
                <LayoutComponents
                    handleLanguageOpen={handleLanguageOpen}
                    handleProfileOpen={handleProfileOpen}
                    classes={classes}
                    checkIsPurchasingRoute={checkIsPurchasingRoute}
                />
                <ProfileDropdown
                    handleProfileOpen={handleProfileOpen}
                    profileAnchor={profileAnchor}
                    classes={classes}
                />
                <LanguageDropdown
                    handleLanguageOpen={handleLanguageOpen}
                    languageAnchorEl={languageAnchorEl}
                    classes={classes}
                />
                <ModalComponent />
            </div>
            {/* <CookieConsent
                flipButtons
                location="bottom"
                buttonText={language === "de" ? "Akzeptiere Alle" : "Accept All"}
                cookieName="AcceptCookies"
                style={{ background: "#2f3447cf", alignItems: "center", zIndex: "1201" }}
                expires={7}>
                <div className={styles.contentWrap}>
                    {translations("label.cookie_consent")}
                    <Link
                        to={`${properties.MY_HEALTHCARE_URL}${language === "de" ? "de/cookies?p=mvp" : "en/cookies?p=mvp"
                            }`}
                        className={styles.link}>
                        {language === "de" ? "Details anzeigen" : "View Details"}
                    </Link>
                </div>
            </CookieConsent> */}
        </>
    );
}

const LayoutComponents = ({ checkIsPurchasingRoute, handleProfileOpen, handleLanguageOpen, classes }) => {
    return (
        <div className={checkIsPurchasingRoute ? classes.containerPurchasing : classes.container}>
            {checkIsPurchasingRoute ? (
                <PurchassingLayout
                    handleProfileOpen={handleProfileOpen}
                    handleLanguageOpen={handleLanguageOpen}
                    classes={classes}
                />
            ) : (
                <DashboardLayout
                    handleProfileOpen={handleProfileOpen}
                    handleLanguageOpen={handleLanguageOpen}
                    classes={classes}
                />
            )}
        </div>
    );
};
