import layoutReducer from "./Layout";
import homePageReducer from "./Home";
import modalReducer from "./Modal";
import authenticationReducer from "./Authentication";
import checkoutReducer from "./Checkout";
import dashboardReducer from "./Dashboard";
import myAccountReducer from "./MyAccount";
import chatReducer from "./Chat";
import uploadDocumentsReducer from "./UploadDocuments";

const reducers = {
    layoutReducer,
    homePageReducer,
    modalReducer,
    authenticationReducer,
    checkoutReducer,
    dashboardReducer,
    myAccountReducer,
    chatReducer,
    uploadDocumentsReducer
};

export default reducers;
