import en from "../localizations/en.json";
import de from "../localizations/de.json";
import React, { createContext, useContext } from "react";
type TranslationsFunction = (text: string) => string;

interface TranslationContextType {
    translations: TranslationsFunction;
}

const TranslationContext = createContext<TranslationContextType>({
    translations: () => ""
});

export const useTranslations = () => useContext(TranslationContext);

export const TranslationProvider = ({ children }) => {
    const translations: TranslationsFunction = (text) => {
        const lang = localStorage.getItem("lang");

        return en[text] === undefined && de[text] === undefined
            ? text
            : lang === "en" || de[text] === undefined
            ? en[text]
            : de[text];
    };

    return <TranslationContext.Provider value={{ translations }}>{children}</TranslationContext.Provider>;
};
