import { Outlet } from "react-router-dom";
import { useState, useEffect } from "react";
import { useAppSelector } from "hooks/hooks";
import WithLoader from "components/WithLoader/WithLoader";

const PublicRoutesMiddleware = () => {
    const { homePageReducer, layoutReducer } = useAppSelector((state) => state);
    const [shouldRender, setShouldRender] = useState(false);

    useEffect(() => {
        if (!homePageReducer.action.isLoading && !layoutReducer.action.isLoading) {
            setShouldRender(true);
        }
    }, [homePageReducer.action.isLoading, layoutReducer.action.isLoading]);

    return shouldRender ? <Outlet /> : <WithLoader isLoading={!shouldRender} />;
};

export default PublicRoutesMiddleware;
