// import TagManager from "react-gtm-module";

export default function GTMEventsHandler(eventName: string, eventProps: any) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        event: eventName,
        ecommerce: eventProps
    });

}
