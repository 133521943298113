import properties from "properties";
import api from "services/api";
import StorageService from "services/storage.service";

/***************
 * ACTION TYPES
 ***************/
export const OPEN_MODAL = "OPEN_MODAL";
export const CLOSE_MODAL = "CLOSE_MODAL";
export const HIDE_MODAL_HEADER = "HIDE_MODAL_HEADER";
export const FILTER_BY_ID = "FILTER_BY_ID";
export const CLEAR_FILTER = "CLEAR_FILTER";
export const GET_MARCHANT_BEGIN = "GET_MARCHANT_BEGIN";
export const GET_MARCHANT_SUCCESS = "GET_MARCHANT_SUCCESS";
export const GET_MARCHANT_FAILED = "GET_MARCHANT_FAILED";
export const SEARCH_DATA = "SEARCH_DATA";
export const GET_LOCATIONS_BEGIN = "GET_LOCATIONS_BEGIN";
export const GET_LOCATIONS_SUCCESS = "GET_LOCATIONS_SUCCESS";
export const GET_LOCATIONS_FAILED = "GET_LOCATIONS_FAILED";
export const GET_PRODUCT_BIOMARKERS_BEGIN = "GET_PRODUCT_BIOMARKERS_BEGIN";
export const GET_PRODUCT_BIOMARKERS_SUCCESS = "GET_PRODUCT_BIOMARKERS_SUCCESS";
export const GET_PRODUCT_BIOMARKERS_FAILED = "GET_PRODUCT_BIOMARKERS_FAILED";
export const SEARCH_LOCATIONS_DATA = "SEARCH_LOCATIONS_DATA";
export const SET_SEARCH_KEYS_HOME = "SET_SEARCH_KEYS_HOME";
export const SET_LANGUAGE = "SET_LANGUAGE";
export const SET_SELECTED_MAIN_TOGGLE = "SET_SELECTED_MAIN_TOGGLE";

export const setSelectedMainToggle = (id: number) => {
    return {
        type: SET_SELECTED_MAIN_TOGGLE,
        payload: id
    };
};

export const openModal = ({ content, modalStyle = {}, type = "xl" }) => {
    return { type: OPEN_MODAL, payload: { content, type, modalStyle } };
};

export const closeModal = () => {
    return { type: CLOSE_MODAL };
};

export const hideModalHeader = () => {
    return { type: HIDE_MODAL_HEADER };
};

export const setLanguage = (language: string) => ({
    type: SET_LANGUAGE,
    payload: language
});

export const getMarchantBegin = () => ({
    type: GET_MARCHANT_BEGIN
});

export const getMarchantSuccess = (data) => ({
    type: GET_MARCHANT_SUCCESS,
    payload: data
});

export const getMarchantFailed = (error) => ({
    type: GET_MARCHANT_FAILED,
    payload: error
});

export const getMarchant = (account_number: String) => {
    return async (dispatch: any) => {
        try {
            dispatch(getMarchantBegin());
            const response = await api.get(`/core/public/merchant/${account_number}/merchant_data`);
            let data: any = { ...response.data, stripe_key: properties.STRIPE_KEY };
            if (response.data?.has_own_stripe === true) {
                try {
                    const stripe_pub_key = await api.get(
                        `/core/person/stripe_pub_key?account_number=${account_number}`
                    );
                    if (stripe_pub_key.status === 200) {
                        data = { ...response.data, stripe_key: stripe_pub_key?.data?.stripe_pub_key };
                    }
                } catch (err) {}
            }
            if (account_number) {
                StorageService.setKeyValue("merchantData", {
                    merchantNumber: account_number,
                    calendar: response.data.calendar,
                    city: response.data.city,
                    street: response.data.street,
                    merchant_name: response.data.merchant_name,
                    longitude: response.data.longitude,
                    latitude: response.data.latitude,
                    opening_hours: response.data.opening_hours,
                    email_address: response.data.email_address,
                    phone_number: response.data.phone_number,
                    logo: response.data.logo
                });
            }
            dispatch(getMarchantSuccess(data));
        } catch (error) {
            dispatch(getMarchantFailed(error));
        }
    };
};

export const filterByGroupId = (data: object) => {
    return { type: FILTER_BY_ID, payload: data };
};

export const clearFilter = () => ({
    type: CLEAR_FILTER
});

export const searchData = (keyword: String) => {
    return {
        type: SEARCH_DATA,
        keyword
    };
};

export const getLocations = () => {
    return async (dispatch: any) => {
        try {
            dispatch({ type: GET_LOCATIONS_BEGIN });
            const response = await api.get(`/core/public/merchant/product_calendar_list`);
            dispatch({ type: GET_LOCATIONS_SUCCESS, payload: response.data });
            return response;
        } catch (error) {
            dispatch({ type: GET_LOCATIONS_FAILED, payload: error });
        }
    };
};
export const getProductBiomarkers = (ta_id: number) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: GET_PRODUCT_BIOMARKERS_BEGIN
            });
            const response = await api.get(`/core/public/merchant/merchant_product_diagnoses/${ta_id}`, {});
            dispatch({
                type: GET_PRODUCT_BIOMARKERS_SUCCESS,
                payload: response.data
            });
        } catch (error) {
            dispatch({
                type: GET_PRODUCT_BIOMARKERS_FAILED,
                error: error
            });
        }
    };
};

export const searchLocationsData = (keyword: String) => {
    return {
        type: SEARCH_LOCATIONS_DATA,
        keyword: keyword
    };
};

export const setSearchKeysHome = (keys: Array<string>) => {
    return {
        type: SET_SEARCH_KEYS_HOME,
        payload: keys
    };
};
