// import NavTab from "components/NavTab/NavTab";
import { useAddAccountNumberPostfix, useAppDispatch, useAppSelector } from "hooks/hooks";
import { setSelectedMenu } from "actions/layout/layout";
// import { setSelectedMainToggle } from 'actions/home/home';
import { useNavigate, useSearchParams } from "react-router-dom";
import { checkProductsUrl, filterProductsByGroupId } from "utils/utils";
import { siwtchPropBasedOnLang } from "helpers";
import { Button } from "@mui/material";
import styles from "../layout.module.scss";

export default function MainNavBar({ classes }) {
    const {
        layoutReducer: { menuItems, publicMenuItems, isProductsInlineUrl, selectedMenu },
        homePageReducer: { products, language, selectedMainToggleId }
    } = useAppSelector((state) => state);

    const dispatch = useAppDispatch();
    const { navigate } = useAddAccountNumberPostfix();
    const [searchParams] = useSearchParams();

    let accountNumber = searchParams.get("account_number");
    const menuItemsData = accountNumber ? menuItems : publicMenuItems;

    const navigateTo = (categoryId?: Number) => {
        dispatch(setSelectedMenu(categoryId, false, menuItemsData));
        if (accountNumber)
            navigate(`/web/${checkProductsUrl(isProductsInlineUrl ? "productsinline" : "products")}`, {
                group_id: categoryId
            });
        else
            navigate(`/web/${checkProductsUrl(isProductsInlineUrl ? "productsinline" : "products")}`, {
                group_id: categoryId
            });
    };

    return (
        <div className={styles.mainNavBar}>
            <div className={styles.container}>
                <div className={styles.productCategoriess}>
                    {menuItemsData?.map((item) => {
                        const groupIdItems = filterProductsByGroupId(
                            { id: item?.product_category_id, menuItems: menuItemsData },
                            products
                        )?.length;

                        return groupIdItems === 0 ? null : (
                            <Button
                                sx={{ display: "block" }}
                                className={
                                    selectedMenu.parent === item.product_category
                                        ? `${classes.btn} ${classes.btnActive}`
                                        : `${classes.btn}`
                                }
                                key={item.product_category_id}
                                onClick={() => {
                                    navigateTo(item.product_category_id);
                                }}>
                                {siwtchPropBasedOnLang(item.product_category, language)}
                            </Button>
                        );
                    })}
                </div>
                <div className={styles.toggleWrapper}>
                    {/* <NavTab style={{
                        border: "1px solid #E4E4E4",
                        background: "#fff",
                        fontSize: "14px"
                    }}
                        tabs={["Home", "On-Site"]}
                        activeTab={selectedMainToggleId}
                        cb={(id) => dispatch(setSelectedMainToggle(id))}
                    /> */}
                </div>
            </div>
        </div>
    );
}
