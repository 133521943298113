import { Menu, MenuItem } from "@mui/material";
import { useEffect } from "react";
import { setLanguage } from "actions/home/home";
import { updateSettings } from "actions/authentication/authentication";
import { useAppDispatch, useAppSelector } from "hooks/hooks";
import "dayjs/locale/de";
import dayjs from "dayjs";
import { useSearchParams } from "react-router-dom";
import { authentication } from "actions";
import { useTranslations } from "hooks/UseTranslations";
import useSnackbar from "hooks/UseSnackBar/useSnackBar";
interface Props {
    handleLanguageOpen: (event: React.MouseEvent<HTMLElement> | null) => void;
    languageAnchorEl: null | HTMLElement;
    classes: any;
}
export default function LanguageDropdown({ handleLanguageOpen, languageAnchorEl, classes }: Props) {
    let openMenu = Boolean(languageAnchorEl);
    let languageStored = localStorage.getItem("lang") || "de";
    const dispatch = useAppDispatch();
    const { translations } = useTranslations();
    const { language } = useAppSelector((state) => state.homePageReducer);
    const [searchParams, setSearchParams] = useSearchParams();
    const { showSnackBar } = useSnackbar();

    const changeLanguage = async (lang: string) => {
        dispatch(setLanguage(lang));
        dayjs.locale(lang);
        handleLanguageOpen(null);
        if (searchParams.has("language")) {
            searchParams.delete("language");
            setSearchParams(searchParams);
        }
        const res = await dispatch(updateSettings({ language: lang.toLowerCase() }));
        if (res.type === authentication.UPDATE_USER_SETTINGS_SUCCESS) {
            dispatch(setLanguage(lang));
            showSnackBar(translations("error.language_changed_successfuly"), "green");
        }
    };

    useEffect(() => {
        dispatch(setLanguage(languageStored));
    }, [dispatch]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Menu
            anchorEl={languageAnchorEl}
            open={openMenu}
            onClose={() => handleLanguageOpen(null)}
            className={classes.popupMenu}>
            <MenuItem
                className={language === "en" ? `${classes.menuItem} ${classes.active}` : classes.menuItem}
                onClick={() => changeLanguage("en")}>
                {translations("english")} (EN)
            </MenuItem>
            <MenuItem
                className={language === "de" ? `${classes.menuItem} ${classes.active}` : classes.menuItem}
                onClick={() => changeLanguage("de")}>
                {translations("german")} (DE)
            </MenuItem>
        </Menu>
    );
}
