import { Navigate, Outlet, useSearchParams } from "react-router-dom";
import { useAddAccountNumberPostfix, useAppDispatch, useAppSelector } from "hooks/hooks";
import { WithLoader } from "components";
import { useEffect } from "react";
import { addAccountNumberPostfix, merchantNumber } from "helpers";
import { getLocationHistory } from "actions/authentication/authentication";

export default function ProtectedRoutesMiddleware() {
    const {
        isLoggedIn,
        action: { isLoading }
    } = useAppSelector((state) => state.authenticationReducer);
    const dispatch = useAppDispatch();
    const [searchParams] = useSearchParams();
    const { getRoute } = useAddAccountNumberPostfix();

    let taId = searchParams.get("ta_id");
    let locationId = searchParams.get("location_id");
    let token = searchParams.get("token");
    let guestToken = searchParams.get("guest_token");
    let name = searchParams.get("name");
    let surname = searchParams.get("surname");
    let email = searchParams.get("email");
    let merchantAccountNumber = merchantNumber();

    // Get location history to view testresult
    useEffect(() => {
        if (isLoggedIn && taId && locationId) {
            dispatch(getLocationHistory(taId, locationId));
            return;
        }
    }, [dispatch, taId, isLoggedIn, locationId]);

    return (
        <WithLoader isLoading={isLoading}>
            {isLoggedIn ? (
                <Outlet />
            ) : (
                <Navigate
                    to="/web/login"
                    state={
                        taId && locationId
                            ? {
                                  lastRoute: `${getRoute("/web/my-health-profile")}${
                                      merchantAccountNumber ? "&" : "?"
                                  }ta_id=${taId}&location_id=${locationId}`
                              }
                            : token && guestToken
                            ? {
                                  lastRoute: `/web/video-consultation-room?token=${token}&guest_token=${guestToken}&name=${name}&surname=${surname}&email=${email}`
                              }
                            : null
                    }
                />
            )}
        </WithLoader>
    );
}
