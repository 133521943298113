import React from "react";
import styles from "./LoaderBounce.module.scss";
interface LoaderBounceProps {}

const LoaderBounce: React.FC<LoaderBounceProps> = () => {
    let classnames = `${styles.loading} ${styles.bounce}`;
    return <div className={classnames}></div>;
};

export default LoaderBounce;
