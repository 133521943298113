import { AuthenticationIState } from "./../../models/Authentication";
import { authentication } from "actions";
import { reMapUserProfile } from "utils/utils";
import StorageService from "services/storage.service";
import { produce } from "immer";

const initialState: AuthenticationIState = {
    userProfile: null,
    exp: null,
    idp_state: null,
    isLoggedIn: false,
    action: {
        isLoading: true,
        error: null,
        message: "",
        isSuccess: false,
        actionName: null,
        statusCode: null,
        needsPhoneVerification: null
    },
    jointUsers: [],
    authParamsIdpState: null,
    userData: null,
    locationHistory: null,
    testResultObject: null
};

const authenticationReducer = (state = initialState, action) =>
    produce(state, (draft) => {
        switch (action.type) {
            case authentication.LOGIN_BEGIN:
            case authentication.AUTHORIZE_BEGIN:
            case authentication.LOGOUT_BEGIN:
            case authentication.SIGNUP_RESEND_BEGIN:
            case authentication.SIGNUP_BEGIN:
            case authentication.SET_PASSWORD_BEGIN:
            case authentication.SEND_RESET_PASSWORD_BEGIN:
            case authentication.SET_PERSONAL_DETAILS_BEGIN:
            case authentication.SET_PERSONAL_ADDRESS_BEGIN:
            case authentication.AUTHORIZATION_BEGIN:
            case authentication.GET_USER_DATA_BEGIN:
            case authentication.VERIFY_BEGIN:
            case authentication.VERIFY_PHONE_BEGIN:
            case authentication.SENT_PHONE_VERIFICATION_CODE_BEGIN:
            case authentication.ADD_ADDRESS_BEGIN:
            case authentication.UPDATE_ADDRESS_BEGIN:
            case authentication.ADD_JOINT_USER_BEGIN:
            case authentication.CHOOSE_PERSON_BEGIN:
            case authentication.DELETE_ACCOUNT_BEGIN:
            case authentication.GET_LOCATION_HISTORY_BEGIN:
            case authentication.NEWSLETTER_SUBSCRIBE_BEGIN:
            case authentication.VERIFY_TOKEN_RECAPTCHA_BEGIN:
                draft.action.isLoading = true;
                draft.action.isSuccess = false;
                draft.action.error = null;
                draft.action.actionName = action.type;
                break;
            case authentication.AUTHORIZATION_FAILED:
                draft.action.isLoading = false;
                // draft.action.error = action.payload ? action.payload : "Unknown error";
                draft.action.isSuccess = false;
                draft.isLoggedIn = false;
                break;
            case authentication.AUTHORIZE_FAILED:
            case authentication.LOGOUT_FAILED:
            case authentication.SIGNUP_RESEND_FAILED:
            case authentication.SIGNUP_FAILED:
            case authentication.SET_PASSWORD_FAILED:
            case authentication.SEND_RESET_PASSWORD_FAILED:
            case authentication.SET_PERSONAL_DETAILS_FAILED:
            case authentication.SET_PERSONAL_ADDRESS_FAILED:
            case authentication.VERIFY_FAILED:
            case authentication.VERIFY_PHONE_FAILED:
            case authentication.SENT_PHONE_VERIFICATION_CODE_FAILED:
            case authentication.ADD_ADDRESS_FAILED:
            case authentication.UPDATE_ADDRESS_FAILED:
            case authentication.ADD_JOINT_USER_FAILED:
            case authentication.CHOOSE_PERSON_FAILED:
            case authentication.DELETE_ACCOUNT_FAILED:
            case authentication.GET_LOCATION_HISTORY_FAILED:
            case authentication.NEWSLETTER_SUBSCRIBE_FAILED:
            case authentication.VERIFY_TOKEN_RECAPTCHA_FAILED:
                draft.action.isLoading = false;
                draft.action.error = action.payload ? action.payload : "Unknown error";
                draft.action.isSuccess = false;
                draft.action.actionName = action.type;
                break;
            case authentication.LOGIN_FAILED:
                draft.action.isLoading = false;
                draft.action.error = action.payload ? action.payload : "Unknown error";
                draft.action.isSuccess = false;
                draft.action.actionName = action.type;
                if (action.payload.authParams) {
                    if (action.payload?.authParams["joint_persons"]) {
                        draft.jointUsers = action.payload.authParams["joint_persons"];
                    }
                    if (action.payload?.authParams["idp_state"]) {
                        draft.authParamsIdpState = action.payload.authParams["idp_state"];
                    }
                }
                break;
            case authentication.AUTHORIZE_SUCCESS:
                draft.exp = action.payload.exp;
                draft.idp_state = action.payload.idp_state;
                draft.action.isLoading = false;
                draft.action.isSuccess = true;
                break;
            case authentication.LOGIN_SUCCESS:
            case authentication.CHOOSE_PERSON_SUCCESS:
                draft.isLoggedIn = true;
                draft.action.isLoading = false;
                draft.action.isSuccess = true;
                break;
            case authentication.LOGOUT_SUCCESS:
                draft.userProfile = null;
                draft.isLoggedIn = false;
                draft.action.isLoading = false;
                draft.action.isSuccess = true;
                draft.action.message = action.payload;
                draft.action.actionName = action.type;
                break;
            case authentication.DELETE_ACCOUNT_SUCCESS:
                draft.isLoggedIn = false;
                draft.action.isLoading = false;
                draft.action.isSuccess = true;
                break;
            case authentication.AUTHORIZATION_SUCCESS:
                draft.isLoggedIn = true;
                // draft.action.isLoading = false;
                draft.action.isSuccess = true;
                break;
            case authentication.GET_USER_DATA_SUCCESS:
                draft.userProfile = reMapUserProfile(action.payload);
                draft.action.isLoading = false;
                draft.action.isSuccess = true;
                draft.action.actionName = action.type;
                break;
            case authentication.UPDATE_PHONE_NUMBER_AUTH:
                draft.userProfile.phone = action.payload;
                break;
            case authentication.SET_PERSONAL_ADDRESS_SUCCESS:
            case authentication.SIGNUP_RESEND_SUCCESS:
            case authentication.VERIFY_SUCCESS:
            case authentication.SENT_PHONE_VERIFICATION_CODE_SUCCESS:
                // case authentication.NEWSLETTER_SUBSCRIBE_SUCCESS:
                draft.action.isLoading = false;
                draft.action.isSuccess = true;
                draft.action.statusCode = 200;
                draft.action.actionName = action.type;
                break;
            case authentication.VERIFY_PHONE_SUCCESS:
                draft.action.isLoading = false;
                draft.action.isSuccess = true;
                draft.action.statusCode = 200;
                draft.action.needsPhoneVerification = action.payload.data.need_verification;
                break;
            case authentication.SIGNUP_SUCCESS:
                draft.action.isLoading = false;
                draft.action.isSuccess = true;
                draft.action.statusCode = null;
                draft.action.statusCode = action.payload;
                break;
            case authentication.SET_PERSONAL_DETAILS_SUCCESS:
                draft.action.isLoading = false;
                draft.action.isSuccess = true;
                draft.action.statusCode = null;
                draft.action.statusCode = 204;
                break;
            case authentication.SET_PERSONAL_DETAILS_ATRIBUTE:
                StorageService.setKeyValue("userData", {
                    ...draft.userData,
                    [action.key]: action.value
                });
                draft.userData = {
                    ...draft.userData,
                    [action.key]: action.value
                };
                break;
            case authentication.SET_PASSWORD_SUCCESS:
                draft.action.isLoading = false;
                draft.action.isSuccess = true;
                draft.action.statusCode = null;
                draft.isLoggedIn = true;
                break;
            case authentication.SEND_RESET_PASSWORD_SUCCESS:
                draft.action.isLoading = false;
                draft.action.isSuccess = true;
                draft.action.statusCode = null;
                break;
            case authentication.ADD_ADDRESS_SUCCESS:
            case authentication.UPDATE_ADDRESS_SUCCESS:
            case authentication.ADD_JOINT_USER_SUCCESS:
                // case authentication.UPDATE_USER_SETTINGS_SUCCESS:
                draft.action.isLoading = false;
                draft.action.isSuccess = true;
                break;
            case authentication.GET_LOCATION_HISTORY_SUCCESS:
                draft.action.isLoading = false;
                draft.action.isSuccess = true;
                draft.locationHistory = action.payload.data;
                draft.testResultObject = action.payload.testResultObject;
                break;
            case authentication.CLEAR_ACTION:
                draft.action.isLoading = false;
                draft.action.error = null;
                draft.action.message = "";
                break;
            default:
                break;
        }
    });

export default authenticationReducer;
