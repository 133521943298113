import Breadcrumbs from "@mui/material/Breadcrumbs";
import { useTranslations } from "hooks/UseTranslations";
import { useLocation } from "react-router-dom";
import "./index.scss";

interface Props {
    className?: string;
    style?: object;
}

export default function BreadCrumbsNav({ className, style }: Props) {
    const location = useLocation();
    const links = location.pathname.split("/").slice(1);
    const { translations } = useTranslations();

    const routeNames = (name: string) => {
        switch (name) {
            case "web":
                return translations("title.home");
            case "my-appointments":
                return translations("title.my_appointments");
            case "dashboard":
                return translations("title.my_dashboard");
            case "my-orders":
                return translations("title.my_orders");
            case "my-upcomings":
                return translations("title.my_upcomings");
            case "my-health-profile":
                return translations("title.my_health_profile");
            case "my-account":
                return translations("title.my_account");
            case "view-order":
                return translations("button.view_order");
            case "locations":
                return translations("title.locations");
            case "consultations":
                return translations("title.consultations");
            case "family-and-friends":
                return translations("title.family_members");
            case "product":
                return translations("title.product");
            case "details":
                return translations("title.details");
            default:
                return name;
        }
    };

    return (
        <div
            className={className ? `${className} breadCrumbs` : "breadCrumbs"}
            role="presentation"
            style={{ ...style }}>
            <Breadcrumbs separator="•" aria-label="breadcrumb" style={{ color: "#afb2c3" }}>
                {links.map((el, i) => {
                    if (i + 1 === links.length) {
                        return (
                            <p className="active" key={i}>
                                {routeNames(el).replaceAll("-", " ")}
                            </p>
                        );
                    } else {
                        return (
                            <p className="unactive" key={i}>
                                {routeNames(el).replaceAll("-", " ")}
                            </p>
                        );
                    }
                })}
            </Breadcrumbs>
        </div>
    );
}
