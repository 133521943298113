import { ProductExample } from "utils/constants/images";
import properties from "properties";
import StorageService from "services/storage.service";
import { checkProductsUrl } from "utils/utils";

export const merchantNumber = () => {
    return StorageService.getDataByKey("merchantData")?.merchantNumber;
};

export const isProductsInlineUrl = () => {
    return StorageService.getDataByKey("productsInlineUrl");
};

export const productsUrl = () => {
    return `/web/${checkProductsUrl(
        isProductsInlineUrl ? "productsinline" : "products"
    )}?account_number=${merchantNumber()}`;
};
export const imageUrl = (images, language) => {
    if (images) {
        const foundImage = images?.find((item) => item?.source === "web" && item?.language === language);
        return foundImage ? foundImage?.url : images?.find((item) => item?.source === "web")?.url;
    }
    return ProductExample;
};

export const singleImageUrl = (image: string) => {
    return image
        ? [
              {
                  original: image,
                  thumbnail: image
              }
          ]
        : ProductExample;
};

export const mainHostUrl = (postFix: string) => {
    // return properties.MAIN_HOST + postFix
    return window.location.origin + postFix;
};

export const addAccountNumberPostfix = (route: string) => {
    // Add account number postfix only if it's stored in cookies
    return merchantNumber() ? `${route}?account_number=${merchantNumber()}` : route;
};

export const checkForProductImages = (item: any) => {
    if (item?.product_images) {
        return item?.product_images?.length > 0 ? item?.product_images.find((item) => item?.source === "web") : null;
    }
};

export const EcocareCareUrl = (accountNumber?: string, language?: string, subRoute?: string) => {
    // if (accountNumber === "0000005995") return `${properties.FRANKFURT_AIRPORT}${language === "de" ? "de" : ""}`;
    // if (accountNumber === "0000008480") return `${properties.DUSSELDORF_AIRPORT}${language === "de" ? "de" : ""}`;
    // if (subRoute) return `${properties.MY_HEALTHCARE_URL}${language === "de" ? "de" : "en"}/${subRoute}`;
    // return `${properties.MY_HEALTHCARE_URL}${language === "de" ? "de" : ""}`;
    return `${properties.MY_HEALTHCARE_URL}`;
};

export const downloadFromExternalLink = (link, filename) => {
    fetch(link)
        .then((response) => response.blob())
        .then((blob) => {
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement("a");
            link.href = url;
            link.download = filename;
            // link.target = "_blank";
            // link.rel = "noreferrer";
            link.click();
        })
        .catch(console.error);
};

export const siwtchPropBasedOnLang = (property, lang) => {
    if (!property) return "";
    const res = property.find((el) => el.language === lang);
    return res?.text?.toString() || property[0].text.toString();
};

// type method = "GET" | "POST" | "PUT" | "DELETE";
