import { Drawer } from "@mui/material";
import { useStyles } from "hooks/hooks";
import { Toolbar } from "@mui/material";
import BreadCrumbsNav from "components/BreadCrumbsNav/indes";
import DrawerItems from "./DrawerItems";

interface Props {
    mobileOpen: boolean;
    handleDrawerToggle: any;
    purchasingLayout: boolean;
    handleLanguageOpen: any;
}

export default function SideBar({ handleDrawerToggle, mobileOpen, purchasingLayout, handleLanguageOpen }: Props) {
    const drawerWidth = purchasingLayout ? 0 : 280;
    const classes = useStyles({
        list: {
            display: "flex !important",
            flexDirection: "column",
            alignItems: "flex-start",
            paddingTop: "0px !important",
            paddingBottom: "0px !important"
            // margin: "35px 10px 0px 10px !important",
        },

        listItem: {
            fontFamily: "PolySans Median",
            fontSize: "15px",
            color: "#11131F !important",
            backgroundColor: "transparent !important",
            borderRadius: "15px !important",
            padding: "15px !important",
            marginBottom: "3px !important",
            position: "relative",
            // borderBottom: "1px solid #d8d8d8 !important",
            "&:hover": {
                backgroundColor: "#F5F6FA !important",
                color: "#202359 !important",
                fontWeight: "700 !important"
            },
            "&.active": {
                backgroundColor: "#F5F6FA !important",
                color: "#202359 !important",
                fontWeight: "700 !important"
            },
            "&.last-drawer-link": {
                border: "1px solid #202359",
                justifyContent: "center",
                marginTop: "3px",
                height: "50px",
                display: "flex",
                alignItems: "center",
                width: "100%"
            },
            "&:after": {
                // display: "flex",
                // alignItems: "center",
                // justifyContent: "center",
                height: "15px",
                width: "15px",
                backgroundColor: "#ba0f0f",
                borderRadius: "50%",
                color: "#fff",
                fontSize: "10px",
                position: "absolut",
                right: "0px"
            }
        },
        drawerWebView: {
            "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
                top: "65px",
                left: "auto",
                borderRight: "none",
                marginLeft: "15px"
            }
        },
        drawerMobileView: {
            "& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth }
        },
        purchasingDrawerMobileView: {
            "& .MuiDrawer-paper": { boxSizing: "border-box", width: 280 }
        },
        logo: {
            borderBottom: "1px solid #d8d8d8",
            justifyContent: "center"
        }
    });

    const DrawerMobileView = () => (
        <Drawer
            variant="temporary"
            open={mobileOpen}
            onClose={() => handleDrawerToggle()}
            className={purchasingLayout ? classes.purchasingDrawerMobileView : classes.drawerMobileView}
            sx={{
                display: { xs: "block", lg: "none" }
            }}>
            <DrawerItems
                classes={classes}
                handleDrawerToggle={() => handleDrawerToggle()}
                purchasingLayout={purchasingLayout}
                handleLanguageOpen={handleLanguageOpen}
            />
        </Drawer>
    );

    return (
        <>
            {/* *************** Mobile View *************** */}
            {DrawerMobileView()}

            {/* *************** Web View only in DashboardLayout *************** */}
            {!purchasingLayout ? (
                <Drawer
                    variant="permanent"
                    className={classes.drawerWebView}
                    sx={{
                        display: { xs: "none", lg: "block" },
                        width: { lg: drawerWidth },
                        flexShrink: { xs: 0 }
                    }}
                    open={true}>
                    <BreadCrumbsNav />
                    <Toolbar />
                    <Toolbar />
                    <DrawerItems
                        classes={classes}
                        purchasingLayout={purchasingLayout}
                        handleDrawerToggle={() => handleDrawerToggle()}
                    />
                </Drawer>
            ) : null}
        </>
    );
}
