import { createRoot } from "react-dom/client";
import { store } from "./reducers/store";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { TranslationProvider } from "hooks/UseTranslations";

const root = createRoot(document.getElementById("root"));

root.render(
    <BrowserRouter>
        <Provider store={store}>
            <TranslationProvider>
                <App />
            </TranslationProvider>
        </Provider>
    </BrowserRouter>
);
