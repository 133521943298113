import React, { useState } from "react";
import { GoogleMap, useJsApiLoader, InfoWindow, Marker, MarkerClusterer } from "@react-google-maps/api";
import { useTranslations } from "hooks/UseTranslations";
import { useAppSelector } from "hooks/hooks";
import { useNavigate } from "react-router-dom";
import { checkProductsUrl } from "utils/utils";
import Button from "components/Button/Button";
import properties from "properties";
interface Props {
    heightSize?: number | string;
    isZoomed?: boolean;
    markers?: any;
}
const containerStyle = {
    width: "100%",
    height: "100vh"
};
const Map: React.FC<Props> = ({ heightSize, isZoomed, markers }) => {
    // const { translations } = useTranslations();
    const {
        homePageReducer: { merchantData }
    } = useAppSelector((state) => state);
    const center = {
        lat: merchantData?.latitude || 1,
        lng: merchantData?.longitude || 2
    };
    const { isLoaded } = useJsApiLoader({
        id: "google-map-script",
        googleMapsApiKey: properties.GOOGLE_MAP_API_KEY
    });

    return markers ? (
        <MapWithMarkers isLoaded={isLoaded} markers={markers} />
    ) : (
        <>
            {isLoaded ? (
                <GoogleMap
                    mapContainerStyle={heightSize ? { height: heightSize } : containerStyle}
                    center={center}
                    zoom={isZoomed ? 12 : 8}>
                    {/* Child components, such as markers, info windows, etc. */}
                    {/* <InfoWindow position={center}>
                        <div className="available-info-window">
                            <div className="available-info-window__header">
                                {translations("label.available_times_at")}
                            </div>
                            <div className="available-info-window__divider"></div>
                            <div className="available-info-window__info">
                                <b>{merchantData?.merchant_name}</b>
                                <br /> {`${merchantData?.street}, ${merchantData?.city}`}
                            </div>
                        </div>
                    </InfoWindow> */}
                </GoogleMap>
            ) : (
                <></>
            )}
        </>
    );
};
const MapWithMarkers = ({ isLoaded, markers }: { isLoaded: boolean; markers: any }) => {
    const [activeMarker, setActiveMarker] = useState("");
    const [currentLocation, setCurrentLocation] = useState({
        lat: 51.1657,
        lng: 10.4515
    });
    const navigate = useNavigate();
    const { translations } = useTranslations();
    const { isProductsInlineUrl } = useAppSelector((state) => state.layoutReducer);

    const handleOnLoad = (map) => {
        navigator?.geolocation.getCurrentPosition(({ coords: { latitude: lat, longitude: lng } }) => {
            setCurrentLocation({ lat, lng });
        });
        // const bounds = new google.maps.LatLngBounds();
        // markers.forEach((el) => bounds.extend({ lat: el.latitude, lng: el.longitude }));
        // map.fitBounds(bounds);
        map.setZoom(7);
    };
    const handleActiveMarker = (marker) => {
        if (marker === activeMarker) {
            return;
        }
        setActiveMarker(marker);
    };
    return (
        <>
            {isLoaded ? (
                <GoogleMap
                    onLoad={handleOnLoad}
                    mapContainerStyle={containerStyle}
                    onClick={() => setActiveMarker(null)}
                    center={currentLocation}>
                    <MarkerClusterer averageCenter gridSize={50}>
                        {(clusterer) => (
                            <>
                                {markers.map((el, idx) => {
                                    return (
                                        <Marker
                                            key={idx}
                                            position={{ lat: el.latitude, lng: el.longitude }}
                                            onClick={() => handleActiveMarker(idx)}
                                            clusterer={clusterer}
                                            // icon={{
                                            //   url: require("../../styles/assets/icons/location-ecocare.svg").default,
                                            // }}
                                        >
                                            {activeMarker === idx ? (
                                                <InfoWindow onCloseClick={() => setActiveMarker(null)}>
                                                    <div className="available-info-window">
                                                        <div className="available-info-window__header center uppercase">
                                                            {el.name}
                                                        </div>
                                                        <div className="available-info-window__divider"></div>
                                                        <div className="available-info-window__header center">{`${el.street}, ${el.city}`}</div>
                                                        <div className="available-info-window__header center w-100 mt-2">
                                                            <Button
                                                                class={"btn btn-blue chooseLocationBtn"}
                                                                label={translations("choose.this.location")}
                                                                handleClick={() =>
                                                                    navigate(
                                                                        `/web/${checkProductsUrl(
                                                                            isProductsInlineUrl
                                                                                ? "productsinline"
                                                                                : "products"
                                                                        )}?account_number=${el.accountNumber}`
                                                                    )
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                </InfoWindow>
                                            ) : null}
                                        </Marker>
                                    );
                                })}
                            </>
                        )}
                    </MarkerClusterer>
                </GoogleMap>
            ) : (
                <></>
            )}
        </>
    );
};
export default Map;
