import styles from "./AuthNavbar.module.scss";
import { useCheckEnvAndLang } from "hooks/hooks";
// import { useAppDispatch } from "hooks";
// import { setShowShoppingCartView } from "actions/layout/layout";
// import { useSelector } from "react-redux";
// import { GlobalIState } from "models";

interface Props {
    showProgressBar?: boolean;
}
export default function AuthNavbar(props: Props) {
    // const dispatch = useAppDispatch();
    // const {
    //   layoutReducer: { showShoppingCartView },
    // } = useSelector((state: GlobalIState) => state);
    const { showProgressBar } = props;
    const EcocareCareUrl = useCheckEnvAndLang();

    return (
        <>
            <div className={styles.navbarWrapper}>
                <i className={styles.logo} onClick={() => (window.location.href = EcocareCareUrl)} />
                <div className={styles.blankSpace}></div>
            </div>
            {showProgressBar ? (
                <div className={styles.progressBarHolder}>
                    <div className={styles.progressBarLeft}></div>
                </div>
            ) : null}
        </>
    );
}
