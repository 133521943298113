import { produce } from "immer";
import UplaodIState from "models/UploadDocuments";
import { uploadDocuments } from "actions";

const initialState: UplaodIState = {
    uploadedDocuments: [],
    uploadedDocumentsLocally: [],
    voiceRecorder: "",
    captureScreen: "",
    captureType: "",
    currentModal: "",
    action: {
        isLoading: false,
        error: null,
        isSuccess: false,
        message: "",
        actionName: null
    }
};

const uploadDocumentsReducer = (state = initialState, action) =>
    produce(state, (draft) => {
        switch (action.type) {
            case uploadDocuments.SET_CAPTURE_SCREEN:
                draft.captureScreen = action.payload;
                break;
            case uploadDocuments.SET_CAPTURE_TYPE:
                draft.captureType = action.payload;
                break;
            case uploadDocuments.POST_UPLOAD_DOCUMENT_BEGIN:
                draft.action.isLoading = true;
                break;
            case uploadDocuments.POST_UPLOAD_DOCUMENT_SUCCESS:
                draft.action.isLoading = false;
                draft.action.message = "success";
                break;
            case uploadDocuments.POST_UPLOAD_DOCUMENT_FAILED:
                draft.action.isLoading = false;
                draft.action.message = "failed";
                break;
            case uploadDocuments.GET_UPLOAD_DOCUMENTS_SUCCESS:
                draft.uploadedDocuments = action.payload.attachments || [];
                draft.action.isSuccess = true;
                draft.action.actionName = uploadDocuments.GET_UPLOAD_DOCUMENTS_SUCCESS;
                draft.action.isLoading = false;
                draft.uploadedDocumentsLocally = [];
                break;
            case uploadDocuments.SET_CURRENT_MODAL:
                draft.currentModal = action.payload;
                break;
            case uploadDocuments.SET_UPLOADED_DOCUMENTS_MESSAGE:
                draft.action.message = action.payload;
                break;
            case uploadDocuments.SET_UPLOAD_DOCUMENTS_LOCALLY:
                draft.uploadedDocumentsLocally = [...draft.uploadedDocumentsLocally, action.payload];
                break;
            case uploadDocuments.DELETE_UPLOAD_DOCUMENT_LOCALLY:
                const indexToDelete = action.payload;
                draft.uploadedDocumentsLocally.splice(indexToDelete, 1);
                break;
            case uploadDocuments.CLEAR_UPLOAD_DOCS_ACTION:
                draft.action.message = "";
                break;
            default:
                break;
        }
    });
export default uploadDocumentsReducer;
