import { getIdentNotifications } from "./../../utils/utils";
import { produce } from "immer";
import { MyAccountIState } from "./../../models/Authentication";
import { myAccount } from "actions";

const initialState: MyAccountIState = {
    action: {
        isLoading: false,
        error: null,
        message: "",
        isSuccess: false,
        actionName: null,
        statusCode: null,
        needsPhoneVerification: null
    },
    notificationSettings: [],
    identNotifications: [],
    notifications: []
};

const myAccountReducer = (state = initialState, action) =>
    produce(state, (draft) => {
        switch (action.type) {
            case myAccount.MY_ACCOUNT_CHANGE_PASSWORD_BEGIN:
            case myAccount.MY_ACCOUNT_GET_NOTIFICATION_SETTINGS_BEGIN:
            case myAccount.MY_ACCOUNT_SET_PERSONAL_DETAILS_BEGIN:
            case myAccount.MY_ACCOUNT_VERIFY_PHONE_BEGIN:
            case myAccount.MY_ACCOUNT_UPDATE_ADDRESS_BEGIN:
            case myAccount.UPDATE_NOTIFICATION_SETTINGS_BEGIN:
                draft.action.isLoading = true;
                draft.action.isSuccess = false;
                draft.action.error = null;
                draft.action.actionName = action.type;
                break;
            case myAccount.MY_ACCOUNT_CHANGE_PASSWORD_FAILED:
            case myAccount.MY_ACCOUNT_GET_NOTIFICATION_SETTINGS_FAILED:
            case myAccount.MY_ACCOUNT_SET_PERSONAL_DETAILS_FAILED:
            case myAccount.MY_ACCOUNT_VERIFY_PHONE_FAILED:
            case myAccount.MY_ACCOUNT_UPDATE_ADDRESS_FAILED:
            case myAccount.UPDATE_NOTIFICATION_SETTINGS_FAILED:
                draft.action.isLoading = false;
                draft.action.error = action.payload ? action.payload : "Unknown error";
                draft.action.isSuccess = false;
                draft.action.actionName = action.type;
                break;
            case myAccount.MY_ACCOUNT_CHANGE_PASSWORD_SUCCESS:
            case myAccount.MY_ACCOUNT_VERIFY_PHONE_SUCCESS:
                draft.action.isLoading = false;
                draft.action.isSuccess = true;
                break;
            case myAccount.MY_ACCOUNT_GET_NOTIFICATION_SETTINGS_SUCCESS:
                draft.notificationSettings = action.payload;
                draft.identNotifications = getIdentNotifications(action.payload);
                draft.action.isLoading = false;
                draft.action.isSuccess = true;
                break;
            case myAccount.MY_ACCOUNT_SET_PERSONAL_DETAILS_SUCCESS:
            case myAccount.MY_ACCOUNT_UPDATE_ADDRESS_SUCCESS:
            case myAccount.UPDATE_NOTIFICATION_SETTINGS_SUCCESS:
                draft.action.isLoading = false;
                draft.action.isSuccess = true;
                draft.action.actionName = action.type;
                break;
            case myAccount.MY_ACCOUNT_CLEAR_ACTION:
                draft.action.isLoading = false;
                draft.action.error = null;
                draft.action.message = "";
                draft.action.statusCode = null;
                draft.action.actionName = "";
                break;
            case myAccount.SET_NOTIFICATIONS:
                draft.notifications = action.payload;
                break;
            case myAccount.DELETE_NOTIFICATIONS_BY_CONVERSATION_ID:
                draft.notifications = state.notifications.filter((el) => el.conversation_id !== action.payload);
                break;
            case myAccount.ADD_NOTIFICATION_ITEM:
                draft.notifications = [...state.notifications, action.payload];
                break;
            default:
                break;
        }
    });

export default myAccountReducer;
