import { useSearchParams } from "react-router-dom";
import { imageUrl, siwtchPropBasedOnLang } from "helpers";
import { useTranslations } from "hooks/UseTranslations";
import { IProduct } from "models/Product";
import { useAddAccountNumberPostfix, useAppSelector } from "hooks/hooks";
import { useDispatch } from "react-redux";
import { selectBookAppointmentProduct, setShoppingCartState } from "actions/layout/layout";
import { LazyLoadImage } from "react-lazy-load-image-component";
import styles from "./ProductItem.module.scss";
import useSnackbar from "hooks/UseSnackBar/useSnackBar";
// import useCheckMobileScreen from "hooks/UseCheckMobileScreen/UseCheckMobileScreen";

interface Props {
    product: IProduct;
    idx: number;
    isMobile: boolean;
    showDiscount: boolean;
}

const ProductItem: React.FC<Props> = ({ product, idx, isMobile, showDiscount }) => {
    const {
        homePageReducer: { language }
    } = useAppSelector((state) => state);
    const { navigate } = useAddAccountNumberPostfix();
    const dispatch = useDispatch();
    const { translations } = useTranslations();
    const [searchParams] = useSearchParams();
    let accountNumber = searchParams.get("account_number");
    let groupId = searchParams.get("group_id");
    let hasMerchant = searchParams.get("account_number");
    const name: string = siwtchPropBasedOnLang(product?.name, language);
    const { showSnackBar } = useSnackbar();

    const navigateTo = (e, id: Number) => {
        e.preventDefault();
        window.scrollTo(0, 0);
        groupId
            ? navigate(`/web/product/details`, {
                  group_id: groupId,
                  product_id: id
              })
            : navigate(`/web/product/details`, {
                  product_id: id
              });
    };

    const handleAddToCart = () => {
        if (product?.product_group === "H") {
            dispatch(setShoppingCartState(product, 1));
            showSnackBar(translations("cart.successfully_added"), "green");
        } else {
            dispatch(selectBookAppointmentProduct(product));
            navigate("/web/book-appointment", {
                product_id: product.product_id,
                action: "add-to-cart"
            });
        }
    };

    return (
        <div className={styles.productContainer}>
            <div className={styles.imageContainer}>
                {/* {idx >= 0 && idx <= 7 ? (
                    <img
                        fetchPriority="high"
                        width={226}
                        height={270}
                        src={product?.image || imageUrl(product?.images, language)}
                        alt={name}
                        className={styles.productImage}
                        onClick={(e) => navigateTo(e, product.product_id)}
                        fetchpriority="high"
                    />
                ) : ( */}
                <LazyLoadImage
                    width={226}
                    height={270}
                    src={product?.image || imageUrl(product?.images, language)}
                    alt={name}
                    className={styles.productImage}
                    onClick={(e) => navigateTo(e, product.product_id)}
                />
                {/* )} */}
                {hasMerchant && (
                    <p className={styles.bluredBackground} onClick={handleAddToCart}>
                        {translations("button.add_to_cart")}
                    </p>
                )}
            </div>
            <p className={styles.productName} onClick={(e) => navigateTo(e, product.product_id)}>
                {name?.length <= 30 ? name : name?.slice(0, 30) + "..."}
            </p>
            <div className={styles.productPrice}>
                <div className="d-flex" style={{ gap: "10px" }}>
                    {product.price !== 0 && !accountNumber ? translations("label.as_per") + " " : null}
                    <p
                        className={
                            showDiscount && product.discount_price ? "productItemPrice redAlert" : "productItemPrice"
                        }>
                        {product?.price === 0
                            ? `${translations("label.free")}`
                            : `€ ${(product?.price / 100).toFixed(2)}`}
                    </p>
                    {showDiscount && product.discount_price && (
                        <p className="productItemPrice">
                            {product?.discount_price === 0
                                ? `${translations("label.free")}`
                                : `€ ${(product?.discount_price / 100).toFixed(2)}`}
                        </p>
                    )}
                </div>
            </div>
        </div>
    );
};
export default ProductItem;
