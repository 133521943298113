import { Box, Modal } from "@mui/material";
import { useAppDispatch, useAppSelector } from "hooks/hooks";
import styles from "./MyQrCode.module.scss";
import React from "react";
import { showQrCodePopup } from "actions/dashboard/dashboard";
import { useTranslations } from "hooks/UseTranslations";
const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 360,
    bgcolor: "background.paper",
    border: "2px solid #fff",
    borderRadius: 2,
    boxShadow: 24,
    p: 2
};

function MyQrCode({ open }) {
    const dispatch = useAppDispatch();
    const { translations } = useTranslations();
    const {
        authenticationReducer: { userProfile }
    } = useAppSelector((state) => state);

    const onClose = () => {
        dispatch(showQrCodePopup());
    };
    return (
        <Modal open={open} onClose={onClose}>
            <Box sx={style}>
                <Box className={styles.container}>
                    <Box className={styles.title}>{translations("title.my_qr-code")}</Box>
                    <Box className={styles.closeIcon} onClick={onClose} data-testid="close-icon"></Box>
                </Box>
                <Box className={styles.subtitle}> {translations("subtitle.show_your_qr_code_to_your")}</Box>
                <Box className={styles.qrCode}>
                    <img
                        className={styles.img}
                        src={`/core/account/${userProfile?.account_number}/qr.png`}
                        alt="QR Code"
                    />
                </Box>
            </Box>
        </Modal>
    );
}

export default MyQrCode;
