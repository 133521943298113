import { Link, useLocation, useNavigate } from "react-router-dom";
import { Button, Box, IconButton, Toolbar, AppBar } from "@mui/material";
import { useAddAccountNumberPostfix, useAppDispatch, useAppSelector, useCheckEnvAndLang } from "hooks/hooks";
import { EcocareLogoNew } from "utils/constants/images";
import { setSelectedMenu } from "actions/layout/layout";
import { useTranslations } from "hooks/UseTranslations";
import { searchData } from "actions/home/home";
import MainNavBar from "../MainNavBar";
import styles from "../../layout.module.scss";

const AppBarComponent = ({ classes, handleDrawerToggle, handleProfileOpen, handleLanguageOpen }) => {
    const { translations } = useTranslations();
    const dispatch = useAppDispatch();
    const nav = useNavigate();
    const { navigate, getRoute } = useAddAccountNumberPostfix();
    const location = useLocation();
    const EcocareCareUrl = useCheckEnvAndLang();

    const {
        authenticationReducer: { isLoggedIn, userProfile },
        layoutReducer: { shoppingCart, selectedMenu, isProductsInlineUrl, showDiscount },
        homePageReducer: { language, merchantData }
    } = useAppSelector((state) => state);
    // const hasDiscount = Boolean(merchantData?.products?.some((element) => element.discount_price));

    const shoppingCartItems = shoppingCart.reduce((res, el) => {
        return res + el._quantity;
    }, 0);

    // const homeCareExist = locations.some((e) => e.id === properties.HOMECARE_ACCOUNT);
    // const findLocationButton = Boolean(pathname === "/web/products" && merchantNumber === properties.HOMECARE_ACCOUNT);
    // const isAirport = ["0000008480", "0000005995"].includes(merchantNumber);

    // const homeCareButton = () => {
    //     if (pathname === "/web/locations" && homeCareExist) {
    //         return (
    //             <Link
    //                 to={`products?account_number=${properties.HOMECARE_ACCOUNT}`}
    //             // to={`products?account_number=${properties.HOMECARE_ACCOUNT}&group_id=${properties.HOMECARE_MERCHANT_DIAGNOSTICS_CATEGORY_ID}`}
    //             >
    //                 <div className="home-care-btn">Home Care</div>
    //             </Link>
    //         );
    //     }
    //     if (findLocationButton) {
    //         return (
    //             <Link to={`locations`}>
    //                 <div className="home-care-btn">Find Location</div>
    //             </Link>
    //         );
    //     }
    //     return null;
    // };

    const goToLocationsPage = () => {
        dispatch(setSelectedMenu("locations", false));
        navigate(`/web/locations`);
    };

    return (
        <>
            <div style={{ height: "40px" }} />
            <AppBar className={classes.appBar} position="fixed">
                {showDiscount &&
                    (language === "de" ? (
                        <div className={styles.discountBanner}>
                            🔥 Exklusiv bis <span>zu 50% Rabatt,</span>limitiertes Angebot für unsere treuen Kunden! 🔥
                        </div>
                    ) : (
                        <div className={styles.discountBanner}>
                            🔥 Exclusive <span>UP TO 50% OFF</span> for Loyal Customers! Limited Quantity – Act Fast! 🔥
                        </div>
                    ))}
                <div className={classes.wrapper}>
                    <Toolbar className={`${classes.purchasingToolBar}`}>
                        <Toolbar sx={{ display: { xs: "none", lg: "flex" } }}>
                            {isProductsInlineUrl ? null : (
                                <img
                                    width="166px"
                                    height="33px"
                                    fetchpriority="high"
                                    src={EcocareLogoNew}
                                    alt="Logo"
                                    style={{ cursor: "pointer" }}
                                    // className={styles.ecocareLogoSvg}
                                    onClick={() => (window.location.href = EcocareCareUrl)}
                                />
                            )}
                        </Toolbar>

                        {/* ***************** Hamburger Icon and Logo in the middle ************** */}
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            edge="start"
                            onClick={handleDrawerToggle}
                            sx={{ display: { lg: "none", md: "none" } }}>
                            <i className="hamburger-icon mx-1"></i>
                        </IconButton>

                        {isProductsInlineUrl ? null : (
                            <Toolbar sx={{ display: { lg: "none" } }}>
                                <img
                                    width="166px"
                                    height="33px"
                                    fetchpriority="high"
                                    src={EcocareLogoNew}
                                    alt="Logo"
                                    // className={styles.ecocareLogoSvg}
                                    onClick={() => (window.location.href = EcocareCareUrl)}
                                />
                            </Toolbar>
                        )}

                        {/* **************Profile Info (visible only on "lg" screens)*************** */}
                        <div className="app-bar-right-wrapper">
                            <Toolbar
                                sx={{
                                    display: { xs: "none", md: "flex" },
                                    padding: { md: "0px 5px" },
                                    width: "100%",
                                    justifyContent: "end"
                                }}>
                                {location.pathname !== "/web/locations" ? (
                                    <div className={styles.searchInput}>
                                        <input
                                            type="text"
                                            placeholder={translations("placeholder.search...")}
                                            onChange={(e) => dispatch(searchData(e.target.value))}
                                        />
                                        <i className={`${styles.searchIcon}`} />
                                    </div>
                                ) : null}
                                {isProductsInlineUrl ? null : merchantData?.merchant_type !== "H" ? ( // !findLocationButton && !isAirport ? (
                                    <Button
                                        sx={{ my: 2, display: "block" }}
                                        className={
                                            selectedMenu.parent[0]?.text === "locations"
                                                ? `${classes.btn} ${classes.btnActive}`
                                                : `${classes.btn}`
                                        }
                                        onClick={() => goToLocationsPage()}>
                                        {translations("title.locations")}
                                    </Button>
                                ) : null}
                            </Toolbar>
                            <Box sx={{ display: { xs: "none", md: "flex" } }}>
                                {/* {homeCareButton()} */}
                                <div className={styles.languagesContainer} onClick={handleLanguageOpen}>
                                    <i className={styles.languageIcon} />
                                    <div className={styles.languageTitle}>{language?.toUpperCase()}</div>
                                </div>
                            </Box>
                            <Link to={getRoute("/web/cart")} aria-label="User Profile">
                                <i className="cart-icon">{shoppingCartItems || ""}</i>
                            </Link>
                            <Box sx={{ display: { xs: "none", md: "flex" } }}>
                                {isLoggedIn && userProfile ? (
                                    <div
                                        className={styles.userProfileWrapper}
                                        onClick={handleProfileOpen}
                                        aria-label="User Profile">
                                        <i className="user-icon mr-1"></i>
                                        <div
                                            className="d-flex"
                                            style={{ flexDirection: "column", width: "max-content" }}>
                                            <p>{`${userProfile?.person?.given_name} ${userProfile?.person?.name}`}</p>
                                            <p className={styles.secondaryText}>{translations("label.customer")}</p>
                                        </div>
                                        <i className="arrowDownIcon ml-1"></i>
                                    </div>
                                ) : (
                                    <IconButton
                                        aria-label="User Profile"
                                        style={{ padding: "0px" }}
                                        size="large"
                                        onClick={() =>
                                            nav("/web/login", {
                                                state: { lastRoute: window.location.pathname + window.location.search }
                                            })
                                        }>
                                        <i className="user-icon"></i>
                                    </IconButton>
                                )}
                            </Box>
                        </div>
                    </Toolbar>
                </div>
                <div className={`${classes.wrapper} ${classes.mainNavBar}`}>
                    <Toolbar className={classes.purchasingToolBar}>
                        <MainNavBar classes={classes} />
                    </Toolbar>
                </div>
            </AppBar>
        </>
    );
};

export default AppBarComponent;
