import React from "react";
import { useAppSelector } from "hooks/hooks";
import { useSearchParams } from "react-router-dom";
import { useTranslations } from "hooks/UseTranslations";
import { filterProductsByGroupId } from "utils/utils";
import { siwtchPropBasedOnLang } from "helpers";
import { ALL_PRODUCTS_FILTER } from "enums";
import styles from "./ProductsFilter.module.scss";

interface ProductsFilterProps {}

const ProductsFilter: React.FC<ProductsFilterProps> = () => {
    // const dispatch = useAppDispatch();
    const { translations } = useTranslations();
    const {
        layoutReducer: { selectedMenu, menuItems, publicMenuItems },
        homePageReducer: { products, language }
    } = useAppSelector((state) => state);

    const [searchParams, setSearchParams] = useSearchParams();
    let accountNumber = searchParams.get("account_number");
    const menuItemsData = accountNumber ? menuItems : publicMenuItems;

    const handleFilter = (categoryId: Number) => {
        setSearchParams((params) => {
            categoryId ? params.set("group_id", categoryId.toString()) : params.delete("group_id");
            return params;
        });
    };

    return (
        <div className={styles.categories}>
            <p className={styles.categoriesTitle}>{translations("products.categories")}</p>
            <div className={styles.mainCategories}>
                <ParentCategory
                    item={{ product_category: ALL_PRODUCTS_FILTER, product_category_id: "" }}
                    selectedMenu={selectedMenu}
                    groupIdItems={products?.length}
                    handleFilter={handleFilter}
                    language={language}
                />
                {menuItemsData?.map((item) => {
                    const groupIdItems = filterProductsByGroupId(
                        { id: item?.product_category_id, menuItems: menuItemsData },
                        products
                    )?.length;
                    return groupIdItems === 0 ? null : (
                        <div key={item.product_category_id}>
                            <ParentCategory
                                item={item}
                                selectedMenu={selectedMenu}
                                groupIdItems={groupIdItems}
                                handleFilter={handleFilter}
                                language={language}
                            />
                            <ChildCategories
                                item={item}
                                selectedMenu={selectedMenu}
                                handleChildClick={handleFilter}
                                language={language}
                            />
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default ProductsFilter;

const ParentCategory = ({ item, selectedMenu, groupIdItems, handleFilter, language }) => {
    const selectClassName = () => {
        if (
            ["All", "Alle"].includes(selectedMenu.parent[0]?.text) &&
            ["All", "Alle"].includes(item.product_category[0]?.text)
        )
            return styles.itemWrapper;
        if (item.product_category === selectedMenu.parent) {
            return styles.itemWrapper;
        } else {
            return `${styles.itemWrapper} ${styles.deActivate}`;
        }
    };

    return (
        <div className={styles.filterItem}>
            <div
                className={selectClassName()}
                onClick={() => {
                    handleFilter(item?.product_category_id);
                }}>
                {siwtchPropBasedOnLang(item?.product_category, language)}
            </div>

            <p className={styles.itemsTag}>{groupIdItems}</p>
        </div>
    );
};

const ChildCategories = ({ item, selectedMenu, handleChildClick, language }) => {
    const selectedParentCategory = siwtchPropBasedOnLang(selectedMenu.parent, language);
    const currentItem = siwtchPropBasedOnLang(item?.product_category, language);

    return (
        <div
            className={
                currentItem === selectedParentCategory
                    ? `${styles.subCategories}`
                    : `${styles.subCategories} ${styles.deActive}`
            }>
            {item?.childItems?.map((child) => {
                const selectedChildCategory = siwtchPropBasedOnLang(selectedMenu.child, language);
                const currentChildItem = siwtchPropBasedOnLang(child?.product_category, language);

                return (
                    <div className={`${styles.filterItem} ml-2`} key={child?.product_category_id}>
                        <div className={styles.categoryChild}>
                            <div
                                className={
                                    currentChildItem === selectedChildCategory
                                        ? styles.activeCategory
                                        : styles.nonActiveCategoryy
                                }
                                onClick={() => handleChildClick(child.product_category_id)}>
                                {siwtchPropBasedOnLang(child?.product_category, language)}
                            </div>
                        </div>
                    </div>
                );
            })}
        </div>
    );
};
