import { useNavigate } from "react-router-dom";
import { Menu, MenuItem, Divider } from "@mui/material";
import { useAddAccountNumberPostfix, useAppDispatch, useAppSelector } from "hooks/hooks";
import { logout } from "actions/authentication/authentication";
import { useTranslations } from "hooks/UseTranslations";
import styles from "../../middlewares/LayoutMiddleware/layout.module.scss";
interface Props {
    handleProfileOpen: (event: React.MouseEvent<HTMLElement> | null) => void;
    profileAnchor: null | HTMLElement;
    classes: any;
}
export default function ProfileDropdown({ handleProfileOpen, profileAnchor, classes }: Props) {
    const {
        authenticationReducer: { isLoggedIn, userProfile }
    } = useAppSelector((state) => state);

    const nav = useNavigate();
    const { navigate } = useAddAccountNumberPostfix();
    const { translations } = useTranslations();
    let openMenu = Boolean(profileAnchor);
    const dispatch = useAppDispatch();

    const handleLogout = () => {
        dispatch(logout()).then(() => {
            nav("/web/login", {
                state: { lastRoute: window.location.pathname + window.location.search }
            });
            handleProfileOpen(null);
        });
    };

    const menuOptions = [
        {
            display: `${translations("title.my_dashboard")}`,
            onclick: () => {
                navigate("/web/dashboard");
                handleProfileOpen(null);
            }
        },
        {
            display: `${translations("title.my_account")}`,
            onclick: () => {
                navigate("/web/my-account");
                handleProfileOpen(null);
            }
        }
    ];

    return (
        <Menu
            anchorEl={profileAnchor}
            open={openMenu}
            onClose={() => handleProfileOpen(null)}
            className={classes.popupMenu}>
            <div className={styles.userProfileMenuItem}>
                <i className={`user-icon ${styles.icon}`}></i>
                <div className="d-flex" style={{ flexDirection: "column" }}>
                    <p>{`${userProfile?.person?.given_name} ${userProfile?.person?.name}`}</p>
                    <p style={{ fontSize: "12px", color: "#8094AE", lineHeight: "20px" }}>
                        {translations("label.customer")}
                    </p>
                </div>
            </div>
            <Divider sx={{ marginBottom: "10px" }} />
            {isLoggedIn ? (
                menuOptions.map((el, idx) => (
                    <MenuItem className={classes.menuItem} onClick={el.onclick} key={idx}>
                        {el.display}
                    </MenuItem>
                ))
            ) : (
                <MenuItem
                    className={classes.menuItem}
                    onClick={() =>
                        nav("/web/login", {
                            state: { lastRoute: window.location.pathname + window.location.search }
                        })
                    }>
                    Login
                </MenuItem>
            )}
            <Divider sx={{ marginBottom: "0px !important", marginTop: "10px" }} />
            <div className={styles.signOutMenuIcon} onClick={handleLogout}>
                {translations("label.sign_out")}
            </div>
        </Menu>
    );
}
