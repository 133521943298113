import { NavbarIState } from "models";
import { produce } from "immer";
import { layout } from "actions";
import { handleQuantity } from "utils/utils";
import { ALL_PRODUCTS_FILTER } from "enums";
import shortUUID from "short-uuid";
import StorageService from "services/storage.service";

const initialState: NavbarIState = {
    selectedMenu: { parent: ALL_PRODUCTS_FILTER, child: "" },
    showDiscount: false,
    selectedSidebar: "",
    shoppingCart: [],
    bookAppointmentProduct: null,
    menuItems: [],
    publicMenuItems: [],
    unBookedItems: [],
    footerLinks: [],
    action: {
        isLoading: false,
        error: null,
        message: "",
        isSuccess: false,
        actionName: ""
    },
    showShoppingCartView: false,
    isProductsInlineUrl: false,
    snackbar: {
        show: false,
        message: "",
        color: ""
    }
};

const getSelectedMenu = (id, menuItems) => {
    if (id === "") return { parent: ALL_PRODUCTS_FILTER, child: "" };
    let parent = menuItems.find(
        (item: any) => item?.product_category_id.toString() === id?.toString()
    )?.product_category;
    let child = menuItems
        .map((item) => item.childItems)
        .flat(2)
        .find((item: any) => item?.product_category_id.toString() === id?.toString())?.product_category;

    if (child)
        parent = menuItems.find((item: any) =>
            item?.childItems?.some((i) => i?.product_category_id.toString() === id?.toString())
        )?.product_category;

    return { parent, child };
};

// const handleBookAppointmentProps = (id: string, shoppingCarts: any, props: any) => {
//     return shoppingCarts.map((el) => {
//         if (el.product_id === id) {
//             return {
//                 ...el,
//                 _bookAppointment: props
//             };
//         }
//         return el;
//     });
// };

const handleShippingInformations = (state: any, shoppingCarts: any) => {
    return shoppingCarts.map((item) => {
        if (item.product_group === "H") {
            return { ...item, shippingInformations: state };
        }

        return item;
    });
};

const layoutReducer = (state = initialState, action) =>
    produce(state, (draft) => {
        switch (action.type) {
            case layout.SET_SELECTED_MENU:
                const selectedMenu =
                    action.payload.id === "locations"
                        ? { parent: ALL_PRODUCTS_FILTER, child: "" }
                        : getSelectedMenu(action.payload.id, action.payload.menuItems);
                draft.selectedMenu = {
                    parent: selectedMenu.parent,
                    child: selectedMenu.child ? selectedMenu.child : ""
                };
                break;
            case layout.SELECT_BOOKAPPOINTMENT_PRODUCT:
                if (action.payload !== null) {
                    const newCartItem = {
                        ...action.payload,
                        _uid: shortUUID.generate(),
                        _selected: false,
                        _quantity: 1,
                        _subtotal:
                            draft.showDiscount && action.payload.discount_price
                                ? action.payload.discount_price / 100
                                : action.payload.price / 100,
                        _bookAppointment: null,
                        shippingInformations: null
                    };
                    localStorage.setItem("bookAppointmentProduct", JSON.stringify(newCartItem));
                    draft.bookAppointmentProduct = newCartItem;
                    break;
                } else {
                    localStorage.removeItem("bookAppointmentProduct");
                    draft.bookAppointmentProduct = null;
                    break;
                }
            case layout.SET_MENU_ITEMS:
                draft.menuItems = null;
                draft.menuItems = action.payload;
                break;
            case layout.SET_SHOW_SHOPPING_CART_VIEW:
                draft.showShoppingCartView = action.payload;
                break;
            // case layout.GET_FOOTER_LINKS_BEGIN:
            case layout.GET_MENUITEMS_BEGIN:
            case layout.GET_PUBLIC_MENUITEMS_BEGIN:
                draft.action.isLoading = true;
                break;
            case layout.GET_MENUITEMS_SUCCESS:
                draft.action.isLoading = false;
                draft.action.isSuccess = true;
                draft.menuItems = action.payload;
                break;
            case layout.GET_PUBLIC_MENUITEMS_SUCCESS:
                draft.action.isLoading = false;
                draft.action.isSuccess = true;
                draft.publicMenuItems = action.payload;
                break;
            // case layout.GET_FOOTER_LINKS_FAILED:
            case layout.GET_MENUITEMS_FAILED:
            case layout.GET_PUBLIC_MENUITEMS_FAILED:
                draft.action.isLoading = false;
                draft.action.error = action.payload ? action.payload : "Unknown error";
                break;
            case layout.GET_FOOTER_LINKS_SUCCESS:
                draft.action.isLoading = false;
                draft.action.isSuccess = true;
                draft.footerLinks = action.payload;
                break;
            case layout.SET_BOOKAPPOINTMENT_PRODUCT:
                localStorage.setItem("bookAppointmentProduct", JSON.stringify(action.payload));
                draft.bookAppointmentProduct = action.payload;
                break;
            case layout.EDIT_BOOKAPPOINTMENT:
                const index = draft.shoppingCart.findIndex((el) => el._uid === action.payload._uid);
                draft.shoppingCart[index] = action.payload;
                draft.shoppingCart = [...draft.shoppingCart];
                break;
            case layout.SET_SHOPPING_CART_STATE: {
                if (action.payload.isBookAppointment) {
                    draft.shoppingCart = [...draft.shoppingCart, action.payload.data];
                    localStorage.setItem("shoppingCart", JSON.stringify([...draft.shoppingCart]));
                    break;
                }
                const index = draft.shoppingCart.findIndex((el) => el.product_id === action.payload.data.product_id);
                if (index !== -1) {
                    const quantitySum = draft.shoppingCart[index]._quantity + action.payload.quantity;
                    draft.shoppingCart[index]._quantity = quantitySum;
                    draft.shoppingCart[index]._subtotal =
                        draft.showDiscount && action.payload.data.discount_price
                            ? (action.payload.data.discount_price / 100) * quantitySum
                            : (action.payload.data.price / 100) * quantitySum;
                } else {
                    draft.shoppingCart = [
                        ...draft.shoppingCart,
                        {
                            _uid: shortUUID.generate(),
                            ...action.payload.data,
                            _selected: false,
                            _quantity: action.payload.quantity,
                            _subtotal:
                                draft.showDiscount && action.payload.data.discount_price
                                    ? (action.payload.data.discount_price / 100) * action.payload.quantity
                                    : (action.payload.data.price / 100) * action.payload.quantity,
                            _bookAppointment: null,
                            shippingInformations: null
                        }
                    ];
                }
                localStorage.setItem("shoppingCart", JSON.stringify([...draft.shoppingCart]));
                break;
            }
            case layout.DELETE_SHOPPING_CART:
                const filtered = state.shoppingCart.filter((el) => el._uid !== action.payload);
                localStorage.setItem("shoppingCart", JSON.stringify([...filtered]));
                StorageService.deleteCookieByKey("voucher");
                draft.shoppingCart = [...filtered];
                break;
            case layout.SELECT_CART_ITEM:
                const data = state.shoppingCart.map((el) => {
                    if (el._uid === action.payload)
                        return {
                            ...el,
                            _selected: !el._selected
                        };

                    return el;
                });
                localStorage.setItem("shoppingCart", JSON.stringify([...data]));
                draft.shoppingCart = [...data];
                break;
            case layout.DELETE_SELECTED_CART:
                const filteredData = state.shoppingCart.filter((el) => !el._selected);
                localStorage.setItem("shoppingCart", JSON.stringify([...filteredData]));
                draft.shoppingCart = [...filteredData];
                break;
            case layout.HANDLE_QUANTITY:
                const updatedCart = handleQuantity(
                    action.payload.id,
                    action.payload.operation,
                    state.shoppingCart,
                    draft.showDiscount
                );
                localStorage.setItem("shoppingCart", JSON.stringify([...updatedCart]));
                draft.shoppingCart = [...updatedCart];
                break;
            case layout.POPULATE_SHOPPING_CART:
                draft.shoppingCart = JSON.parse(localStorage.getItem("shoppingCart")) || [];
                draft.bookAppointmentProduct = JSON.parse(localStorage.getItem("bookAppointmentProduct")) || null;
                break;
            case layout.CLEAR_SHOPPING_CART:
                localStorage.removeItem("shoppingCart");
                localStorage.removeItem("bookAppointmentProduct");
                StorageService.deleteCookieByKey("voucher");
                draft.action = state.action;
                draft.shoppingCart = [];
                break;
            case layout.SET_BOOK_APPOINTMENT_PROPS:
                localStorage.setItem(
                    "bookAppointmentProduct",
                    JSON.stringify({ ...state.bookAppointmentProduct, _bookAppointment: action.props })
                );
                draft.bookAppointmentProduct = { ...state.bookAppointmentProduct, _bookAppointment: action.props };
                draft.action.actionName = action.type;
                break;
            case layout.SET_UN_BOOKED_ITEMS:
                draft.unBookedItems = action.payload;
                break;
            case layout.CLEAR_ACTION_LAYOUT:
                draft.action = initialState.action;
                break;
            case layout.SELECT_SIDEBAR:
                draft.selectedSidebar = action.sidebar_nav;
                break;
            case layout.UPDATE_SHOPPING_CART:
                const updatedData = handleShippingInformations(action.payload.state, state.shoppingCart);
                localStorage.setItem("shoppingCart", JSON.stringify([...updatedData]));
                draft.shoppingCart = [...updatedData];
                break;
            case layout.DELETE_IS_PRODUCTS_INLINE_URL_COOKIE:
                StorageService.deleteCookieByKey("productsInlineUrl");
                draft.isProductsInlineUrl = null;
                break;
            case layout.SET_IS_PRODUCTS_INLINE_URL_COOKIE:
                StorageService.setKeyValue("productsInlineUrl", action.payload);
                draft.isProductsInlineUrl = action.payload;
                break;
            case layout.SET_SNACKBAR_OBJECT: {
                const options = action.payload;
                draft.snackbar = options.show ? options : { ...draft.snackbar, show: false };
                break;
            }
            case layout.SET_DISCOUNT_PARAM: {
                draft.showDiscount = action.payload;
                break;
            }
            default:
                break;
        }
    });

export default layoutReducer;
