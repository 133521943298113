// import { getUploadedDocuments } from "actions/uploadDocuments/uploadDocuments";
import api from "services/api";
export const GET_PRODUCT_ORDERS_BEGIN = "GET_PRODUCT_ORDERS_BEGIN";
export const GET_PRODUCT_ORDERS_SUCCESS = "GET_PRODUCT_ORDERS_SUCCESS";
export const GET_PRODUCT_ORDERS_FAILED = "GET_PRODUCT_ORDERS_FAILED";

export const POST_CONFIRM_HOME_TEST_BEGIN = "POST_CONFIRM_HOME_TEST_BEGIN";
export const POST_CONFIRM_HOME_TEST_SUCCESS = "POST_CONFIRM_HOME_TEST_SUCCESS";
export const POST_CONFIRM_HOME_TEST_FAILED = "POST_CONFIRM_HOME_TEST_FAILED";

export const POST_CONFIRM_HOME_TEST_BEGIN_MANUALLY = "POST_CONFIRM_HOME_TEST_BEGIN_MANUALLY";
export const POST_CONFIRM_HOME_TEST_SUCCESS_MANUALLY = "POST_CONFIRM_HOME_TEST_SUCCESS_MANUALLY";
export const POST_CONFIRM_HOME_TEST_FAILED_MANUALLY = "POST_CONFIRM_HOME_TEST_FAILED_MANUALLY";

export const RESET_ACTION = "RESET_ACTION";
export const SHOW_QRCODE_POP_UP = "SHOW_QRCODE_POP_UP";

export const UPDATE_CONSULTATION_STATE_RECORD = "UPDATE_CONSULTATION_STATE_RECORD";
export const HANDLE_BARCODE = "HANDLE_BARCODE";

export const GET_PRODUCT_BARCODE_BEGIN = "GET_PRODUCT_BARCODE_BEGIN";
export const GET_PRODUCT_BARCODE_SUCCESS = "GET_PRODUCT_BARCODE_SUCCESS";
export const GET_PRODUCT_BARCODE_FAILED = "GET_PRODUCT_BARCODE_FAILED";

const getProductOrdersBegin = () => ({
    type: GET_PRODUCT_ORDERS_BEGIN
});

export const resetAction = () => ({
    type: RESET_ACTION
});

const getProductOrdersSuccess = (data) => ({
    type: GET_PRODUCT_ORDERS_SUCCESS,
    payload: data
});

const getProductOrdersFailed = (error) => ({
    type: GET_PRODUCT_ORDERS_FAILED,
    error: error
});

export const handleBarcode = (key: string) => ({
    type: HANDLE_BARCODE,
    payload: key
});

export const showQrCodePopup = () => ({
    type: SHOW_QRCODE_POP_UP
});

export const updateConsultationStateRecord = (id: string) => ({
    type: UPDATE_CONSULTATION_STATE_RECORD,
    id: id
});

export const getProductOrders = (accountNumber: string, dateFrom: string, dateTo: string) => {
    return async (dispatch: any) => {
        try {
            dispatch(getProductOrdersBegin());
            const response = await api.get(`/core/account/${accountNumber}/product_orders`, {
                params: {
                    date_from: dateFrom,
                    date_to: dateTo
                }
            });
            dispatch(getProductOrdersSuccess(response.data || []));
            return response;
        } catch (error) {
            dispatch(getProductOrdersFailed(error.response));
        }
    };
};

const postConfirmHomeTestBegin = () => ({
    type: POST_CONFIRM_HOME_TEST_BEGIN
});

const postConfirmHomeTestSuccess = (data) => ({
    type: POST_CONFIRM_HOME_TEST_SUCCESS,
    payload: data
});

const postConfirmHomeTestFailed = (error) => ({
    type: POST_CONFIRM_HOME_TEST_FAILED,
    error: error
});
const postConfirmHomeTestBeginManually = () => ({
    type: POST_CONFIRM_HOME_TEST_BEGIN_MANUALLY
});

const postConfirmHomeTestSuccessManually = (data) => ({
    type: POST_CONFIRM_HOME_TEST_SUCCESS_MANUALLY,
    payload: data
});

const postConfirmHomeTestFailedManually = (error) => ({
    type: POST_CONFIRM_HOME_TEST_FAILED_MANUALLY,
    error: error
});

export const postConfirmHomeTest = (
    ta_id: number,
    product_order_id: number,
    product_order_item_id: number,
    test_id: string
) => {
    return async (dispatch: any) => {
        try {
            dispatch(postConfirmHomeTestBegin());
            const response = await api.post(
                `/core/person/confirm_home_test?ta_id=${ta_id}&product_order_id=${product_order_id}&product_order_item_id=${product_order_item_id}&test_id=${test_id}`
            );
            dispatch(postConfirmHomeTestSuccess(response.data));
            return response;
        } catch (error) {
            dispatch(postConfirmHomeTestFailed(error.response));
        }
    };
};
export const postConfirmHomeTestManually = (barcode: string) => {
    return async (dispatch: any) => {
        try {
            dispatch(postConfirmHomeTestBeginManually());
            const response = await api.post(`/core/payments/product_order_activate`, {
                barcode: barcode
            });
            return dispatch(postConfirmHomeTestSuccessManually(response.data));
        } catch (error) {
            dispatch(postConfirmHomeTestFailedManually(error.response));
        }
    };
};

export const getProductBarcode = (ta_id: number, product_order_id: number, product_order_item_id: number) => {
    return async (dispatch: any) => {
        try {
            dispatch({
                type: GET_PRODUCT_BARCODE_BEGIN
            });
            const response = await api.get(
                `/core/product_order_barcode?ta_id=${ta_id}&product_order_id=${product_order_id}&product_order_item_id=${product_order_item_id}`
            );
            dispatch({
                type: GET_PRODUCT_BARCODE_SUCCESS,
                payload: response.data
            });
            return response;
        } catch (error) {
            dispatch({
                type: GET_PRODUCT_BARCODE_FAILED,
                payload: error.response.data
            });
        }
    };
};

export const DASHBOARD_SEARCH_DATA = "DASHBOARD_SEARCH_DATA";
export const dashboardSearchData = (keyword: string) => {
    return {
        type: DASHBOARD_SEARCH_DATA,
        keyword
    };
};

export const SET_SEARCH_KEYS = "SET_SEARCH_KEYS";
export const setSearchKeys = (keys: Array<string>) => {
    return {
        type: SET_SEARCH_KEYS,
        payload: keys
    };
};

export const SET_DATA_TO_RENDER = "SET_DATA_TO_RENDER";
export const setDataToRender = (dataToRender: any) => {
    return {
        type: SET_DATA_TO_RENDER,
        payload: dataToRender
    };
};

export const SET_COLUMN_PER_PAGE = "SET_COLUMN_PER_PAGE";
export const setColumnPerPage = (value: number) => {
    return {
        type: SET_COLUMN_PER_PAGE,
        payload: value
    };
};

export const SET_ORDER = "SET_ORDER";
export const setOrder = (value: string) => {
    return {
        type: SET_ORDER,
        payload: value
    };
};

export const SET_STATUS = "SET_STATUS";
export const setStatus = (value: string) => {
    return {
        type: SET_STATUS,
        payload: value
    };
};

export const CLEAR_TABLE_FILTER = "CLEAR_TABLE_FILTER";
export const clearTableFilter = () => {
    return {
        type: CLEAR_TABLE_FILTER
    };
};
