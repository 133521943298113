import React from "react";
import { useAppSelector } from "hooks/hooks";
import { useTranslations } from "hooks/UseTranslations";
import { weekdayFromNumberToString } from "utils/utils";
import StorageService from "services/storage.service";
import Map from "components/Map/Map";
import styles from "./SingleHealthpointShopHeader.module.scss";

export default function MapPopup() {
    const {
        homePageReducer: {
            merchantData: { merchant_name, city, street, email_address, latitude, longitude, phone_number }
        }
    } = useAppSelector((state) => state);

    const openingHoursCalendar = StorageService.getDataByKey("merchantData")?.opening_hours;
    const { translations } = useTranslations();

    return (
        <div className={styles.popUpContainer}>
            <p className={styles.infoTitle}>{translations("label.information")}</p>
            <div className={styles.map}>
                <Map heightSize={250} />
            </div>
            <div className={styles.merchantInfoContainer}>
                <div className={styles.descriptionContainer}>
                    <p className={styles.modalTitle}>{merchant_name}</p>
                    <p className={styles.modalSubtitle}>
                        {street}, {city}
                    </p>
                </div>
                <div className={styles.directionContainer}>
                    <i className={styles.directionIcon} />
                    <div
                        onClick={() =>
                            window.open(
                                `https://maps.google.com/maps?q=${latitude},${longitude}`,
                                "_blank",
                                "noopener,noreferrer"
                            )
                        }
                        style={{ paddingTop: "5px" }}>
                        {" "}
                        {translations("label.directions")}
                    </div>
                </div>
            </div>
            <div>
                <div className={styles.workingHoursTitle}>{translations("label.opening_hours")}</div>
                <div className={styles.daysHoursContainer}>
                    {openingHoursCalendar?.map((item, index) => {
                        return (
                            <div key={index} className={styles.calendarItemsContainer}>
                                <div className={styles.weekDayContainer}>
                                    <div className={styles.weekDay}>
                                        {translations(
                                            `week_day.${weekdayFromNumberToString(item.weekday_id).toLowerCase()}`
                                        )}
                                    </div>
                                </div>
                                <div className={styles.workingHoursContainer}>
                                    <div className={styles.workingHours}>
                                        {item.start_time} - {item.end_time}
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
            <div className={styles.bottomContainer}>
                <div className={styles.emailContainer}>
                    <p className={styles.emailTitle}>{translations("label.email")}</p>
                    <div className={styles.email}>{email_address}</div>
                </div>
                {phone_number ? (
                    <div>
                        <p className={styles.phone}>{translations("label.phone")}</p>
                        <div className={styles.number}>{phone_number}</div>
                    </div>
                ) : null}
            </div>
        </div>
    );
}
