import useCheckMobileScreen from "hooks/UseCheckMobileScreen/UseCheckMobileScreen";
import React, { useState } from "react";
import BarcodeScannerComponent from "react-qr-barcode-scanner";

interface BarcodeReaderProps {
    data: string;
    setData: (data: string) => void;
    setOnError?: (error: string) => void;
}

function BarcodeReader(props: BarcodeReaderProps): JSX.Element {
    const { data, setData, setOnError } = props;
    const [stopStream, setStopStream] = useState<boolean>(false);
    const isMobile = useCheckMobileScreen();

    return (
        <>
            <BarcodeScannerComponent
                width={isMobile ? 350 : 500}
                height={isMobile ? 350 : 500}
                onError={setOnError}
                stopStream={stopStream}
                onUpdate={(err: any, result: any) => {
                    if (result) {
                        setData(result.text);
                        setStopStream(true);
                    }
                }}
            />
            <p>{data}</p>
        </>
    );
}

export default BarcodeReader;
