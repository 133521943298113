import properties from "properties";
import api from "services/api";
import GTMEventsHandler from "utils/GTMEventsHandler";
import { menuItemsMapped } from "utils/utils";

/***************
 * ACTION TYPES
 ***************/
export const SET_SELECTED_MENU = "SET_SELECTED_MENU";
export const EDIT_BOOKAPPOINTMENT = "EDIT_BOOKAPPOINTMENT";
export const SET_SHOPPING_CART_STATE = "SET_SHOPPING_CART_STATE";
export const SELECT_BOOKAPPOINTMENT_PRODUCT = "SELECT_BOOKAPPOINTMENT_PRODUCT";
export const SET_MENU_ITEMS = "SET_MENU_ITEMS";
export const GET_MENUITEMS_BEGIN = "GET_MENUITEMS_BEGIN";
export const GET_MENUITEMS_SUCCESS = "GET_MENUITEMS_SUCCESS";
export const GET_MENUITEMS_FAILED = "GET_MENUITEMS_FAILED";
export const GET_PUBLIC_MENUITEMS_BEGIN = "GET_PUBLIC_MENUITEMS_BEGIN";
export const GET_PUBLIC_MENUITEMS_SUCCESS = "GET_PUBLIC_MENUITEMS_SUCCESS";
export const GET_PUBLIC_MENUITEMS_FAILED = "GET_PUBLIC_MENUITEMS_FAILED";
export const SET_SHOW_SHOPPING_CART_VIEW = "SET_SHOW_SHOPPING_CART_VIEW";
export const DELETE_SHOPPING_CART = "DELETE_SHOPPING_CART";
export const DELETE_SELECTED_CART = "DELETE_SELECTED_CART";
export const HANDLE_QUANTITY = "HANDLE_QUANTITY";
export const CLEAR_SHOPPING_CART = "CLEAR_SHOPPING_CART";
export const SELECT_CART_ITEM = "SELECT_CART_ITEM";
export const SET_BOOK_APPOINTMENT_PROPS = "SET_BOOK_APPOINTMENT_PROPS";
export const CLEAR_ACTION_LAYOUT = "CLEAR_ACTION_LAYOUT";
export const SET_UN_BOOKED_ITEMS = "SET_UN_BOOKED_ITEMS";
export const SELECT_SIDEBAR = "SELECT_SIDEBAR";
export const POPULATE_SHOPPING_CART = "POPULATE_SHOPPING_CART";
export const UPDATE_SHOPPING_CART = "UPDATE_SHOPPING_CART";
export const SET_IS_PRODUCTS_INLINE_URL_COOKIE = "SET_IS_PRODUCTS_INLINE_URL_COOKIE";
export const DELETE_IS_PRODUCTS_INLINE_URL_COOKIE = "DELETE_IS_PRODUCTS_INLINE_URL_COOKIE";
export const GET_FOOTER_LINKS_BEGIN = "GET_FOOTER_LINKS_BEGIN";
export const GET_FOOTER_LINKS_SUCCESS = "GET_FOOTER_LINKS_SUCCESS";
export const GET_FOOTER_LINKS_FAILED = "GET_FOOTER_LINKS_FAILED";
export const SET_BOOKAPPOINTMENT_PRODUCT = "SET_BOOKAPPOINTMENT_PRODUCT";
export const SET_SNACKBAR_OBJECT = "SET_SNACKBAR_OBJECT";
export const SET_DISCOUNT_PARAM = "SET_DISCOUNT_PARAM";

export const setSelectedMenu = (id: any, isChild: Boolean, menuItems?: any) => {
    return {
        type: SET_SELECTED_MENU,
        payload: { id, isChild, menuItems }
    };
};

export const setBookAppointmentProduct = (product) => {
    return {
        type: SET_BOOKAPPOINTMENT_PRODUCT,
        payload: product
    };
};

export const getMenuItemsBegin = () => ({
    type: GET_MENUITEMS_BEGIN
});

export const getMenuItemsSuccess = (data) => ({
    type: GET_MENUITEMS_SUCCESS,
    payload: data
});

export const getMenuItemsFailed = (error: any) => ({
    type: GET_MENUITEMS_FAILED,
    payload: error
});

export const getPublicMenuItemsBegin = () => ({
    type: GET_PUBLIC_MENUITEMS_BEGIN
});

export const getPublicMenuItemsSuccess = (data) => ({
    type: GET_PUBLIC_MENUITEMS_SUCCESS,
    payload: data
});

export const getPublicMenuItemsFailed = (error: any) => ({
    type: GET_PUBLIC_MENUITEMS_FAILED,
    payload: error
});

export const setShowShoppingCartView = (data: Boolean) => {
    return {
        type: SET_SHOW_SHOPPING_CART_VIEW,
        payload: data
    };
};

export const handleSnackbar = (show: boolean, message?: string, color?: string) => {
    return {
        type: SET_SNACKBAR_OBJECT,
        payload: { show, message, color }
    };
};

export const populateShoppingCart = () => {
    return {
        type: POPULATE_SHOPPING_CART
    };
};

export const setIsProductsInlineUrlCookie = (data: boolean) => ({
    type: SET_IS_PRODUCTS_INLINE_URL_COOKIE,
    payload: data
});

export const deleteIsProductsInlineUrlCookie = () => ({
    type: DELETE_IS_PRODUCTS_INLINE_URL_COOKIE
});

export const getMenuItems = (id: number, merchant_account_number: string) => {
    return async (dispatch: any) => {
        try {
            dispatch(getMenuItemsBegin());
            const response = await api.get(
                `/core/public/merchant/product_categories?product_category_id=${id}&merchant_account_number=${merchant_account_number}`
            );
            dispatch(getMenuItemsSuccess(menuItemsMapped(response.data)));
        } catch (error) {
            dispatch(getMenuItemsFailed(error));
        }
    };
};

export const getPublicMerchantMenuItems = (id: number) => {
    return async (dispatch: any) => {
        try {
            dispatch(getPublicMenuItemsBegin());
            const response = await api.get(`/core/public/merchant/product_categories?product_category_id=${id}`);
            dispatch(getPublicMenuItemsSuccess(menuItemsMapped(response.data)));
        } catch (error) {
            dispatch(getPublicMenuItemsFailed(error));
        }
    };
};

export const getFooterLinks = () => {
    return async (dispatch: any) => {
        try {
            dispatch({ type: GET_FOOTER_LINKS_BEGIN });
            const res = await fetch(
                `${properties.STRAPI_API_URL}/api/menus?populate=deep&filters[title][$eqi]=footer-sub`,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${properties.STRAPI_API_KEY}`
                    }
                }
            );
            const data = await res.json();
            const linksData = data?.data[0]?.attributes?.items.data;
            dispatch({ type: GET_FOOTER_LINKS_SUCCESS, payload: linksData });
        } catch (error) {
            dispatch({ type: GET_FOOTER_LINKS_FAILED, payload: error });
        }
    };
};

export const selectSidebar = (sidebar_nav: string) => {
    return {
        type: SELECT_SIDEBAR,
        sidebar_nav
    };
};

// ******************
// CART_ITEM ACTIONS
// ******************

export const selectBookAppointmentProduct = (data: any) => {
    return {
        type: SELECT_BOOKAPPOINTMENT_PRODUCT,
        payload: data
    };
};

export const editBookAppointment = (data: any) => {
    return {
        type: EDIT_BOOKAPPOINTMENT,
        payload: data
    };
};

export const setShoppingCartState = (data: any, quantity: number, isBookAppointment?: boolean) => {
    GTMEventsHandler("add_to_cart", {
        items: [
            {
                item_name: data.officialName,
                item_id: data.product_id,
                price: data.price,
                item_brand: "",
                item_variant: "",
                quantity: quantity
            }
        ]
    });

    return {
        type: SET_SHOPPING_CART_STATE,
        payload: { data, quantity, isBookAppointment }
    };
};

export const deleteShoppingCart = (id: string) => {
    return {
        type: DELETE_SHOPPING_CART,
        payload: id
    };
};

export const deleteSelectedCart = () => {
    return {
        type: DELETE_SELECTED_CART
    };
};

export const handleQuantity = (operation: string, id: string) => {
    return {
        type: HANDLE_QUANTITY,
        payload: { operation, id }
    };
};

export const selectCartItem = (id: string) => {
    return {
        type: SELECT_CART_ITEM,
        payload: id
    };
};

export const clearShoppingCart = () => {
    return {
        type: CLEAR_SHOPPING_CART
    };
};

export const updateShoppingCart = (state: any) => {
    return {
        type: UPDATE_SHOPPING_CART,
        payload: { state }
    };
};

export const setBookAppointmentProps = (id: string, props: any) => {
    return {
        type: SET_BOOK_APPOINTMENT_PROPS,
        id,
        props
    };
};

export const setUnBookedItems = (prducts: Array<object>) => {
    return {
        type: SET_UN_BOOKED_ITEMS,
        payload: prducts
    };
};

export const clearAction = () => {
    return {
        type: CLEAR_ACTION_LAYOUT
    };
};

export const setDiscountParam = (param) => {
    return {
        type: SET_DISCOUNT_PARAM,
        payload: param
    };
};

// ***************
// ***************
