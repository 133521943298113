import api from "services/api";
import dayjs from "dayjs";
import StorageService from "services/storage.service";
import { IProductOrder } from "models/Product";
import GTMEventsHandler from "utils/GTMEventsHandler";

export const ORDER_BEGIN = "ORDER_BEGIN";
export const ORDER_SUCCESS = "ORDER_SUCCESS";
export const ORDER_FAILED = "ORDER_FAILED";
export const CLEAR_ACTION = "CLEAR_ACTION";
export const REQUEST_INVOICE_BEGIN = "REQUEST_INVOICE_BEGIN";
export const REQUEST_INVOICE_SUCCESS = "REQUEST_INVOICE_SUCCESS";
export const REQUEST_INVOICE_FAILED = "REQUEST_INVOICE_FAILED";
export const CANCEL_ORDER_BEGIN = "CANCEL_ORDER_BEGIN";
export const CANCEL_ORDER_SUCCESS = "CANCEL_ORDER_SUCCESS";
export const CANCEL_ORDER_FAILED = "CANCEL_ORDER_FAILED";
export const PAYPAL_ORDER_BEGIN = "PAYPAL_ORDER_BEGIN";
export const PAYPAL_ORDER_SUCCESS = "PAYPAL_ORDER_SUCCESS";
export const PAYPAL_ORDER_FAILED = "PAYPAL_ORDER_FAILED";
export const USE_VOUCHER_BEGIN = "USE_VOUCHER_BEGIN";
export const USE_VOUCHER_SUCCESS = "USE_VOUCHER_SUCCESS";
export const USE_VOUCHER_FAILED = "USE_VOUCHER_FAILED";

const orderBegin = () => ({
    type: ORDER_BEGIN
});

const orderSuccess = (data) => ({
    type: ORDER_SUCCESS,
    payload: data
});

const orderFailed = (error) => ({
    type: ORDER_FAILED,
    payload: error
});

const requestInvoiceBegin = () => ({
    type: REQUEST_INVOICE_BEGIN
});

const requestInvoiceSuccess = (data) => ({
    type: REQUEST_INVOICE_SUCCESS,
    payload: data
});

const requestInvoiceFailed = (error) => ({
    type: REQUEST_INVOICE_FAILED,
    payload: error
});
const cancelOrderBegin = () => ({
    type: CANCEL_ORDER_BEGIN
});

const cancelOrderSuccess = (data) => ({
    type: CANCEL_ORDER_SUCCESS,
    payload: data
});

const cancelOrderFailed = (error) => ({
    type: CANCEL_ORDER_FAILED,
    payload: error
});

const useVoucherBegin = () => ({
    type: USE_VOUCHER_BEGIN
});

const useVoucherSuccess = (data, appliedVoucher) => ({
    type: USE_VOUCHER_SUCCESS,
    payload: { data, appliedVoucher }
});

const useVoucherFailed = (error) => ({
    type: USE_VOUCHER_FAILED,
    payload: error
});

export const clearAction = () => ({
    type: CLEAR_ACTION
});

export const order = (
    userProfile,
    merchantNumber,
    shoppingCart,
    language,
    payment_method,
    appliedVoucher,
    showDiscount
) => {
    return async (dispatch) => {
        try {
            let product: IProductOrder = {
                account_number: userProfile?.account_number,
                merchant_account_number: merchantNumber,
                payment_method: payment_method,
                items: shoppingCart.map((el: any) => {
                    const obj: any = {
                        quantity: el._quantity,
                        product_id: el.product_id,
                        languages: language || ["de"],
                        with_chatting: el.product_add_ons ? true : false,
                        with_discount: el.discount_price && showDiscount ? true : false
                    };
                    if (el._bookAppointment) {
                        obj.appointment = {
                            start: el._bookAppointment.start,
                            end: el._bookAppointment.end
                        };
                    }
                    if (el.shippingInformations) {
                        obj.delivery_date = dayjs(el.shippingInformations.delivery_date).format();
                        obj.delivery_note = el.shippingInformations.delivery_note;
                    }
                    return obj;
                })
            };
            if (appliedVoucher) {
                product.voucher_code = appliedVoucher;
            }
            let orderData = StorageService.getDataByKey("orderData");
            if (orderData && orderData?.passport) {
                product.passport_number = orderData.passport;
                product.nationality = orderData.nationality;
            }
            if (orderData && orderData?.sampleTakingMethodId) {
                product.items[0].method_taken_id = orderData?.sampleTakingMethodId;
            }

            dispatch(orderBegin());
            const response = await api.post("/core/payments/order_product", product);
            if (payment_method === "paypal") {
                window.location.href = response.data.payment_method_details.approve_url;
                return;
            }
            dispatch(orderSuccess(response.data));
            const items = shoppingCart.map((el: any) => {
                return {
                    item_name: el.officialName,
                    price: el.price,
                    item_brand: "",
                    quantity: el._quantity
                };
            });
            GTMEventsHandler("purchase", {
                transaction_id: response.data.transaction_id,
                currency: "EUR",

                value: shoppingCart.reduce((accumulator, product) => accumulator + product.price, 0),
                tax: shoppingCart[0].vat,
                shipping: shoppingCart.find((el) => el.shippingInformations)?.shippingInformations,
                coupon: appliedVoucher || "",
                items: items
            });
            return response;
        } catch (error) {
            dispatch(orderFailed(error?.response?.data));
        }
    };
};

// const eventData = shoppingCart.map((el) => {
//     return {
//         item_name: el.officialName,
//         item_id: el.product_id,
//         price: el.price,
//         item_brand: "",
//         item_variant: "",
//         quantity: 1
//     };
// });
// GTMEventsHandler("view_cart", {
//     items: eventData
// });
export const requestInvoice = (taId: string, transactionId: string) => {
    return async (dispatch: any) => {
        try {
            dispatch(requestInvoiceBegin());
            const response = await api.post("/core/payments/request_invoice", {
                ta_id: taId,
                transaction_id: transactionId
            });
            dispatch(requestInvoiceSuccess(response.data));
            return response;
        } catch (error) {
            return dispatch(requestInvoiceFailed(error?.response?.data));
        }
    };
};

export const cancelOrder = (taId: string, transactionId: string) => {
    return async (dispatch: any) => {
        try {
            dispatch(cancelOrderBegin());
            const response = await api.post("/core/payments/order_product_storno", {
                ta_id: taId,
                transaction_id: transactionId
            });
            dispatch(cancelOrderSuccess(response.data));
            return response;
        } catch (error) {
            return dispatch(cancelOrderFailed(error.response.data));
        }
    };
};

export const paypalOrder = (data: any) => {
    return async (dispatch: any) => {
        try {
            dispatch({
                type: PAYPAL_ORDER_BEGIN
            });
            const response = await api.post("/core/payments/paypal", {}, { params: data });
            dispatch({
                type: PAYPAL_ORDER_SUCCESS
            });
            return response;
        } catch (error) {
            return dispatch({
                type: PAYPAL_ORDER_FAILED,
                payload: error
            });
        }
    };
};

export const applyVoucher = (accountNumber: string, voucherCode: string, productId: string) => {
    /* eslint-disable react-hooks/rules-of-hooks */
    return async (dispatch: any) => {
        try {
            dispatch(useVoucherBegin());
            const response = await api.get(
                `/core/merchant/${accountNumber}/voucher/${voucherCode}?product_id=${productId}`
            );
            dispatch(useVoucherSuccess(response.data, voucherCode));
            return response.data.idp_state;
        } catch (error) {
            dispatch(useVoucherFailed(error?.response?.data));
        }
    };
};

export const getSampleTakingMethods = (product_id: number) => {
    return async () => {
        try {
            const response = await api.get(`/core/products/methods_taken?product_id=${product_id}`);
            return response;
        } catch (error) {
            console.log("error", error);
        }
    };
};
