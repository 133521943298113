import { addNotificaionItem, deleteNotificaionsByConversationId, setNotifications } from "actions/myAccount/myAccount";
import { openDB } from "idb";
import { store } from "reducers/store";

// Function to open or create the database
async function openIndexedDb() {
    const dbName = "notificaitons_db_v3"; // Change to your desired database name
    const dbVersion = 3; // Change to your desired database version

    const result = await openDB(dbName, dbVersion, {
        upgrade(db, oldVersion, newVersion, transaction, event) {
            if (oldVersion < dbVersion) {
                // Create or modify object stores (table)
                oldVersion === 1 && db.deleteObjectStore("notifications_db");
                oldVersion === 2 && db.deleteObjectStore("notifications_db");
                const objectStore = db.createObjectStore("notifications_data", {
                    keyPath: "id",
                    autoIncrement: true
                });
                // Create index for the tabel.
                objectStore.createIndex("conversation_index", ["conversation_id"], {
                    unique: false
                });
                objectStore.createIndex("person_index", ["person_id"], {
                    unique: false
                });
                objectStore.createIndex("person_and_conversaiont", ["person_id", "conversation_id"], {
                    unique: false
                });
            }
        }
    });

    return result;
}
// Function to add data to the object store
export async function addToIndexedDb(payload: any) {
    const db = await openIndexedDb();
    const transaction = db.transaction("notifications_data", "readwrite");
    const objectStore = transaction.objectStore("notifications_data");

    // Example: Add data to the object store
    objectStore.put(payload);
    store.dispatch(addNotificaionItem(payload));
}

export async function deleteByPersonIdandConversationId(person_id: number, conversation_id: string) {
    const db = await openIndexedDb();
    const transaction = db.transaction("notifications_data", "readwrite");
    const objectStore = transaction.objectStore("notifications_data");
    objectStore
        .index("person_and_conversaiont")
        .openCursor([person_id, conversation_id])
        .then(async (cursor) => {
            while (cursor) {
                cursor.delete();
                cursor = await cursor.continue();
            }
        });
    store.dispatch(deleteNotificaionsByConversationId(conversation_id));
}

export const getAllNotificationByPersonId = async (person_id: number) => {
    const db = await openIndexedDb();
    const transaction = db.transaction("notifications_data", "readwrite");
    const objectStore = transaction.objectStore("notifications_data");
    // const conversationIdQuery = await objectStore.index("person_index").getAll([person_id]);
    const conversationIdQuery = await objectStore.getAll();
    const data = conversationIdQuery.filter((el) => el.person_id === person_id);
    store.dispatch(setNotifications(data));
};
