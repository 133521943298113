import { HomePageIState } from "models";
import { home } from "actions";
import { filterProductsByGroupId, getProductInfoByLang, locationsWithCheapestPriceOfProduct } from "utils/utils";
import { produce } from "immer";
// import { HOME_PRODUCTS, ON_SITE_PRODUCTS } from "enums";
import Fuse from "fuse.js";

const initialState: HomePageIState = {
    merchantData: null,
    products: [],
    productBiomarkers: [],
    filteredProducts: [],
    locations: [],
    filteredLocations: [],
    selectedMainToggleId: 0,
    fuseSearch: new Fuse([], { keys: [], threshold: 0 }),
    locationsFuseSearch: new Fuse([], { keys: [], threshold: 0 }),
    fuseOptions: {
        keys: ["officialName", "price"],
        threshold: 0
    },
    searchKeyword: "",
    dataToFilter: null,
    language: localStorage.getItem("lang") || "de",
    action: {
        isLoading: true,
        error: null,
        message: "",
        isSuccess: false
    }
};

const reMapLocations = (data, language) => {
    return data.map((item: any) => {
        //* Merchant General Info
        let merchant: any = { id: item.identifier };
        item.name && (merchant.name = item.name);
        item.person_id && (merchant.personId = item.person_id);
        item.account_number && (merchant.accountNumber = item.account_number);
        merchant.type = item.merchant_type;
        merchant.product_list = item.product_list;
        merchant.product_list = reMapName(item.product_list, language);
        merchant.product_categories = item.product_categories;
        merchant.calendar = item.calendar;
        merchant.has_own_paypal = item?.has_own_paypal;
        merchant.has_own_stripe = item?.has_own_stripe;
        item.distance_km && (merchant.distance = item.distance_km);
        merchant.officialName = typeof item.name === "string" ? item.name : getProductInfoByLang(item.name, language);

        //* Merchant Addresses
        let itemAddress: any = item.address;
        if (itemAddress) {
            let address: any = {};
            itemAddress.street && (address.street = itemAddress.street);
            itemAddress.building_identifier && (address.buildingIdentifier = itemAddress.building_identifier);
            itemAddress.post_code && (address.postalCode = itemAddress.post_code);
            itemAddress.city && (address.city = itemAddress.city);
            itemAddress.country && (address.country = itemAddress.country);
            itemAddress.longitude && (address.longitude = itemAddress.longitude);
            itemAddress.latitude && (address.latitude = itemAddress.latitude);
            merchant.address = address;
        }
        return merchant;
    });
};

function reMapName(data, language) {
    return data?.map((product) => ({
        ...product,
        officialName: getProductInfoByLang(product.name, language)
    }));
}

// const distingushHomeAndOnSite = (id: number, products) => {
//     let result: Array<any> | undefined;
//     if (id === 0) result = products.filter((el: any) => HOME_PRODUCTS.includes(el.product_group));
//     if (id === 1) result = products.filter((el: any) => ON_SITE_PRODUCTS.includes(el.product_group));
//     return result;
// };

const homePageReducer = (state = initialState, action) =>
    produce(state, (draft) => {
        switch (action.type) {
            case home.GET_LOCATIONS_BEGIN:
            case home.GET_MARCHANT_BEGIN:
            case home.GET_PRODUCT_BIOMARKERS_BEGIN:
                draft.action.isLoading = true;
                break;
            case home.GET_LOCATIONS_FAILED:
            case home.GET_MARCHANT_FAILED:
            case home.GET_PRODUCT_BIOMARKERS_FAILED:
                draft.action.isLoading = false;
                draft.action.error = action.payload ? action.payload : "Unknown error";
                break;
            case home.GET_MARCHANT_SUCCESS: {
                const products = reMapName(action.payload.products, state.language);
                draft.merchantData = action.payload;
                draft.products = products;
                draft.filteredProducts = products;
                draft.fuseSearch = new Fuse(products, state.fuseOptions);
                draft.action.isLoading = false;
                draft.action.isSuccess = true;
                break;
            }
            case home.GET_PRODUCT_BIOMARKERS_SUCCESS: {
                draft.productBiomarkers = action.payload;
                draft.action.isLoading = false;
                break;
            }
            case home.GET_LOCATIONS_SUCCESS: {
                const data = reMapLocations(action.payload, state.language);
                const productData = locationsWithCheapestPriceOfProduct(data);
                draft.locations = data;
                draft.filteredLocations = data;
                draft.locationsFuseSearch = new Fuse(data, state.fuseOptions);
                draft.products = productData;
                draft.filteredProducts = productData;
                draft.fuseSearch = new Fuse(productData, state.fuseOptions);
                draft.action.isLoading = false;
                draft.action.isSuccess = true;
                break;
            }
            case home.FILTER_BY_ID: {
                const filteredProducts = filterProductsByGroupId(action.payload, action.payload.products);
                // const resultData = distingushHomeAndOnSite(action.payload.selectedMainToggleId, filteredProducts);
                draft.dataToFilter = action.payload;
                draft.filteredProducts = filteredProducts;
                draft.fuseSearch = new Fuse(filteredProducts, state.fuseOptions);
                break;
            }
            case home.CLEAR_FILTER: {
                // const resultData = distingushHomeAndOnSite(state.selectedMainToggleId, state.products);
                draft.dataToFilter = null;
                draft.filteredLocations = state.locations;
                draft.locationsFuseSearch = new Fuse(state.locations, state.fuseOptions);
                draft.filteredProducts = state.products;
                draft.fuseSearch = new Fuse(state.products, state.fuseOptions);
                break;
            }
            case home.SEARCH_DATA:
                const handleSearch = () => {
                    let result;
                    if (action.keyword !== "") {
                        result = state.fuseSearch.search(action.keyword).map((el) => el.item);
                    } else {
                        if (state.dataToFilter) {
                            const filteredData = filterProductsByGroupId(state.dataToFilter, state.products);
                            // result = distingushHomeAndOnSite(state.selectedMainToggleId, filteredData);
                            result = filteredData;
                        } else {
                            result = state.products;
                        }
                    }
                    return result;
                };
                draft.searchKeyword = action.keyword;
                draft.filteredProducts = handleSearch();
                break;
            case home.SEARCH_LOCATIONS_DATA:
                draft.filteredLocations =
                    action.keyword !== ""
                        ? state.locationsFuseSearch.search(action.keyword).map((el) => el.item)
                        : [...state.locations];
                break;
            case home.SET_SEARCH_KEYS_HOME:
                draft.locationsFuseSearch = new Fuse(state.locations, { keys: action.payload, threshold: 0 });
                break;
            case home.SET_LANGUAGE: {
                localStorage.setItem("lang", action.payload.toLowerCase());
                const products = reMapName(state.products, action.payload);
                draft.products = products;
                draft.filteredProducts = products;
                draft.fuseSearch = new Fuse(products, state.fuseOptions);
                draft.language = action.payload;
                break;
            }
            case home.SET_SELECTED_MAIN_TOGGLE: {
                draft.selectedMainToggleId = action.payload;
                break;
            }
            default:
                break;
        }
    });

export default homePageReducer;
