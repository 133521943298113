import { GlobalIState, modalTypes } from "models";
import { useSelector } from "react-redux";
import { EcocareLogoNew } from "utils/constants/images";
import { hideModalHeader, openModal } from "actions/home/home";
import { useAppDispatch } from "hooks/hooks";
import MapPopup from "./MapPopup";
import Button from "components/Button/Button";
import styles from "./SingleHealthpointShopHeader.module.scss";
import { useTranslations } from "hooks/UseTranslations";

interface Props {}

const SingleHealthpointShopHeader: React.FC<Props> = () => {
    const {
        homePageReducer: {
            merchantData: { merchant_name, city, street, logo }
        }
    } = useSelector((state: GlobalIState) => state);
    const { translations } = useTranslations();

    const dispatch = useAppDispatch();

    const handleViewMerchantInfo = () => {
        dispatch(hideModalHeader());
        dispatch(
            openModal({
                content: <MapPopup />,
                type: modalTypes.sm
            })
        );
    };

    return (
        <div className={styles.container}>
            <div className={styles.info}>
                <div className={styles.imgShadow}>
                    <img src={logo ? logo : EcocareLogoNew} alt="Merchant" />
                </div>
                <div>
                    <p className={styles.title}>{merchant_name}</p>
                    <p className={styles.subtitle}>
                        {street || ""}, {city}
                    </p>
                </div>
            </div>

            <Button
                class={"btn btn-blue"}
                style={{ maxWidth: "140px" }}
                label={translations("label.information")}
                handleClick={(e) => handleViewMerchantInfo()}
            />
        </div>
    );
};

export default SingleHealthpointShopHeader;
