import React from "react";
import Modal from "react-modal";
import styles from "./ModalComponent.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { GlobalIState } from "models";
import { closeModal } from "actions/home/home";
import { CloseButton } from "utils/constants/images";

interface Props {
    modalTitle?: String;
}

const ModalComponent: React.FC<Props> = (props) => {
    const { modalTitle } = props;
    const dispatch = useDispatch();
    const {
        modalReducer: { openModal, modalContent, modalStyle, hideModalHeader, type }
    } = useSelector((state: GlobalIState) => state);
    const hasModalStyle = Object.keys(modalStyle || {}).length > 0;
    const customStyles = {
        content: modalStyle
    };

    const onModalClose = () => {
        dispatch(closeModal());
    };

    return (
        <React.Fragment>
            <Modal
                isOpen={openModal}
                onAfterOpen={() => (document.body.style.overflow = "hidden")}
                onAfterClose={() => (document.body.style.overflow = "unset")}
                onRequestClose={onModalClose}
                className={styles[`${type}Content`]}
                style={hasModalStyle ? customStyles : {}}
                contentLabel="Example Modal"
            >
                {/* <h2 ref={(_subtitle) => (subtitle = _subtitle)}>Hello</h2> */}
                {!hideModalHeader && (
                    <div className={styles.modalHeader}>
                        <div className={styles.modalTitle}>
                            <h2>{modalTitle}</h2>
                        </div>
                        <div className={styles.modalClose}>
                            <button onClick={onModalClose} className={`float-right ${styles.closeButton}`}>
                                <img src={CloseButton} alt="Close Button" />
                            </button>
                        </div>
                    </div>
                )}
                <div className="body">
                    {modalContent}
                    {hideModalHeader && (
                        <button
                            onClick={onModalClose}
                            className={`float-right ${styles.closeButton} ${styles.closeButtonNoHeader}`}
                        >
                            <i className={styles.closeIcon} />
                        </button>
                    )}
                </div>
            </Modal>
        </React.Fragment>
    );
};

export default ModalComponent;
