import { makeStyles } from "@mui/styles";
import { EcocareCareUrl, merchantNumber } from "helpers";
import React, { useEffect, useState } from "react";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { createSearchParams, useNavigate, useSearchParams } from "react-router-dom";
import { RootState, AppDispatch } from "reducers/store";

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export const useStyles: any = (style: any) => {
    const useStyles = makeStyles(() => style);
    return useStyles();
};

export function useCheckEnvAndLang(subRoute?: string): string {
    const { language } = useAppSelector((state) => state.homePageReducer);
    // const [searchParams] = useSearchParams();
    const [url, setUrl] = useState("");

    useEffect(() => {
        // let accountNumber = searchParams.get("account_number");
        // setUrl(EcocareCareUrl(accountNumber, language, subRoute));
        setUrl(EcocareCareUrl());
    }, [language]);

    return url;
}

export const useAddAccountNumberPostfix = () => {
    const nav = useNavigate();
    const [searchParams] = useSearchParams();
    let accountNumber = searchParams.get("account_number");
    const { showDiscount } = useAppSelector((state) => state.layoutReducer);

    const navigate = (pathname: any, params?: any, state?: any) => {
        if (pathname === -1) {
            nav(-1);
            return;
        }

        if (params) {
            let objParams = showDiscount ? { ...params, discount: true } : params;
            if (accountNumber) {
                objParams = { ...objParams, account_number: accountNumber };
            }
            nav({ pathname, search: `?${createSearchParams(objParams)}` }, state);
            return;
        }

        const route = accountNumber
            ? `${pathname}?account_number=${accountNumber}${showDiscount ? "&discount=true" : ""}`
            : pathname;
        nav(route, { state: state });
        return;
    };

    const getRoute = (pathname: string) => {
        const route = accountNumber
            ? `${pathname}?account_number=${accountNumber}${showDiscount ? "&discount=true" : ""}`
            : pathname;
        return route;
    };
    return { navigate, getRoute };
};
